import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import ErrorMessage from 'shared/components/ErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHttpClient } from 'shared/hooks/http-hook';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const StyledInfoForm = styled.form`
  margin: auto;
  padding: 4rem 1.6rem 0 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.2rem;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #969696;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }

  .title {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    margin-bottom: 0;
  }

  .input-elements {
    width: 100%;
    max-width: 36rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .input-element {
    width: 100%;
    background: #010001;
    border: 1px solid #969696;
    border-radius: 8px;

    height: 4.8rem;

    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: table-cell;
    align-items: center;
    color: #ffffff;
    padding: 1.3rem 1.6rem;

    :focus {
      outline: none;
    }
  }

  .send-verification {
    width: 100%;
    max-width: 36rem;
    height: 48px;
    background: #cd0715;
    border-radius: 8px;

    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    border: none;
  }
`;

const MemberResetPassword = () => {
  const { sendRequest } = useHttpClient();
  let { userId, token } = useParams();
  const navigate = useNavigate();
  const formSchema = yup.object().shape({
    password: yup
      .string()
      .required('密碼為必填')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\(\)_\+\-\=\[\]\{}|;:'",.<>\/?\\`~])(?=.{8,})/,
        '密碼為8到32個字元，至少各有1個大小寫英文字，一個數字，和一個特殊符號',
      )
      .max(32, '密碼最長為32個字元'),
    cpassword: yup
      .string()
      .required('請再輸入一次密碼')
      .oneOf([yup.ref('password')], '請輸入一樣的密碼'),
  });

  const onSubmit = async data => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/users/receive_new_password/${userId}/${token}`,
        'POST',
        JSON.stringify({
          password: data.password,
        }),
        {
          'Content-Type': 'application/json',
        },
      );
      navigate('/member/login');
    } catch (err) {
      console.log(err);
    }
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ resolver: yupResolver(formSchema) });
  return (
    <StyledInfoForm onSubmit={handleSubmit(onSubmit)}>
      <h1 className="title">重設密碼</h1>
      <div className="input-elements">
        <input
          className="input-element"
          type="password"
          autoComplete="on"
          placeholder="請輸入新密碼"
          {...register('password')}
        />
        {errors.password && <ErrorMessage message={errors.password.message} />}
      </div>
      <div className="input-elements">
        <input
          className="input-element"
          type="password"
          autoComplete="on"
          placeholder="請再輸入一次新密碼"
          {...register('cpassword')}
        />
        {errors.cpassword && (
          <ErrorMessage message={errors.cpassword.message} />
        )}
      </div>
      <button className="send-verification" onClick={handleSubmit(onSubmit)}>
        確認變更
      </button>
    </StyledInfoForm>
  );
};

export default MemberResetPassword;
