import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import ErrorMessage from 'shared/components/ErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AuthContext } from 'shared/context/auth-context';
import { useHttpClient } from 'shared/hooks/http-hook';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import TermsAndPrivacy from 'components/Layout/TermsAndPrivacy';
import { modalTypeEnum } from 'components/Layout/Footer';

const StyledLoginForm = styled.form`
  margin: auto;
  padding: 4rem 1.6rem 0 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #969696;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }

  .title {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    margin-bottom: 3.2rem;
  }

  .input-elements {
    width: 100%;
    max-width: 36rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.4rem;
    margin: 3.2rem auto 1.2rem auto;
  }

  .input-element {
    width: 100%;
    background: #010001;
    border: 1px solid #969696;
    border-radius: 8px;

    height: 4.8rem;

    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #ffffff;
    padding: 1.3rem 1.6rem;

    :focus {
      outline: none;
    }
  }

  .checkbox-agreement {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 3.2rem;
    padding-bottom: 2.4rem;
    margin-right: auto;

    .box-agreement {
      width: 2rem;
      height: 2rem;
      border-radius: 3px;
      user-select: none;
      :checked {
        accent-color: black;
      }
    }

    .tc-wrapper {
      display: flex;
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      margin: auto;
      text-decoration: none;
    }

    .tc-quote {
      color: #ffffff;
    }

    .text-agreement {
      padding-left: 1.2rem;
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #ffffff;
    }

    .tc-link {
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #ffffff;
      :hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .login {
    width: 100%;
    max-width: 36rem;
    height: 48px;
    background: #cd0715;
    border-radius: 8px;

    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    border: none;
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
  }

  .bottom-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.8rem;
  }

  .bottom-text,
  .btn-go-to-register {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    margin: auto;
  }
  .btn-go-to-register {
    border: none;
    padding: 0;
    text-decoration: underline;
  }
`;

const MemberRegister = () => {
  const formSchema = yup.object().shape({
    email: yup.string().required('Email為必填').email('請輸入正確Email'),
    name: yup.string().required('姓名為必填').max(32, '姓名最長為32字元'),
    mobile: yup
      .string()
      .required('手機號碼為必填')
      .matches(/^09\d{2}-\d{3}-\d{3}$/, '請輸入正確手機號碼'),
    password: yup
      .string()
      .required('密碼為必填')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\(\)_\+\-\=\[\]\{}|;:'",.<>\/?\\`~])(?=.{8,})/,
        '密碼為8到32個字元，至少各有1個大小寫英文字，一個數字，和一個特殊符號',
      )
      .max(32, '密碼最長為32個字元'),
    cpassword: yup
      .string()
      .required('請再輸入一次密碼')
      .oneOf([yup.ref('password')], '請輸入一樣的密碼'),
    agreement: yup.bool().oneOf([true], '請勾選'),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ resolver: yupResolver(formSchema) });

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(modalTypeEnum.PrivacyPolicy);

  const formatPhoneNumber = value => {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you format the area code to early

    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 4)}-${phoneNumber.slice(4)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `${phoneNumber.slice(0, 4)}-${phoneNumber.slice(
      4,
      7,
    )}-${phoneNumber.slice(7, 10)}`;
  };

  const handlePhoneNumberInput = e => {
    // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    // we'll set the input value using our setValue
    setValue('mobile', formattedPhoneNumber, {
      shouldValidate: true,
    });
  };

  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  useEffect(() => {
    if (auth.isLoggedIn) {
      navigate('/member/reservation');
    }
  }, [auth, navigate]);

  const { isLoading, sendRequest } = useHttpClient();
  const onSubmit = async data => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/users/signup',
        'POST',
        JSON.stringify({
          email: data.email,
          name: data.name,
          password: data.password,
          mobile: data.mobile,
          company: '',
        }),
        {
          'Content-Type': 'application/json',
        },
      );
      auth.login(responseData.id, responseData.token);
      navigate('/member/reservation');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}
      <StyledLoginForm onSubmit={handleSubmit(onSubmit)}>
        <h1 className="title">註冊</h1>
        <div className="input-elements">
          <input
            className="input-element"
            type="email"
            autoComplete="on"
            placeholder="Email"
            {...register('email')}
          />
          {errors.email && <ErrorMessage message={errors.email.message} />}
          <input
            className="input-element"
            type="text"
            placeholder="姓名"
            autoComplete="on"
            {...register('name')}
          />
          {errors.name && <ErrorMessage message={errors.name.message} />}
          <input
            className="input-element"
            placeholder="手機號碼"
            autoComplete="on"
            {...register('mobile')}
            onChange={e => handlePhoneNumberInput(e)}
          />
          {errors.mobile && <ErrorMessage message={errors.mobile.message} />}
          <input
            className="input-element"
            type="password"
            autoComplete="on"
            placeholder="密碼"
            {...register('password')}
          />
          {errors.password && (
            <ErrorMessage message={errors.password.message} />
          )}
          <input
            className="input-element"
            type="password"
            autoComplete="on"
            placeholder="確認密碼"
            {...register('cpassword')}
          />
          {errors.cpassword && (
            <ErrorMessage message={errors.cpassword.message} />
          )}
          <div className="checkbox-agreement">
            <input
              className="box-agreement"
              type="checkbox"
              id="agreement"
              name="agreement"
              {...register('agreement')}
            />
            <label className="text-agreement">我已經閱讀並同意</label>
            <Link
              onClick={() => {
                setShowModal(true);
                setModalType(modalTypeEnum.TermsOfService);
              }}
              className="tc-wrapper"
            >
              <span className="tc-quote">「</span>
              <label className="tc-link">服務條款</label>
              <span className="tc-quote">」</span>
            </Link>
            <div>
              {errors.agreement && (
                <ErrorMessage message={errors.agreement.message} />
              )}
            </div>
          </div>
        </div>
        <button className="login" onClick={handleSubmit(onSubmit)}>
          立即註冊
        </button>
        <div className="bottom-wrapper">
          <p className="bottom-text">已經是會員?</p>
          <Link to="/member/login" className="btn-go-to-register">
            前往登入
          </Link>
        </div>
      </StyledLoginForm>
      <TermsAndPrivacy
        show={showModal}
        setShow={setShowModal}
        type={modalType}
      />
    </React.Fragment>
  );
};

export default MemberRegister;
