import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from 'shared/components/ErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AuthContext } from 'shared/context/auth-context';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useContext } from 'react';
import { useHttpClient } from 'shared/hooks/http-hook';
import TermsAndPrivacy from 'components/Layout/TermsAndPrivacy';
import qs from 'qs';
import jwtDecode from 'jwt-decode';
import { Link } from 'react-router-dom';
import { modalTypeEnum } from 'components/Layout/Footer';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import { set } from 'date-fns';

const StyledInfoForm = styled.form`
  margin: auto;
  padding: 4rem 1.6rem 0 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #969696;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }

  .title {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    margin-bottom: 3.2rem;
  }

  .input-elements {
    width: 100%;
    max-width: 36rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.4rem;
    margin: 0 auto 3.2rem auto;
  }

  .input-element {
    width: 100%;
    background: #010001;
    border: 1px solid #969696;
    border-radius: 8px;

    height: 4.8rem;

    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #ffffff;
    padding: 1.3rem 1.6rem;

    :focus {
      outline: none;
    }
  }

  .checkbox-agreement {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 3.2rem;
    padding-bottom: 2.4rem;
    margin-right: auto;

    .box-agreement {
      width: 2rem;
      height: 2rem;
      border-radius: 3px;
      user-select: none;
      :checked {
        accent-color: black;
      }
    }

    .tc-wrapper {
      display: flex;
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      margin: auto;
      text-decoration: none;
    }

    .tc-quote {
      color: #ffffff;
    }

    .text-agreement {
      padding-left: 1.2rem;
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #ffffff;
    }

    .tc-link {
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #ffffff;
      :hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .btn-go-to-register {
      border: none;
      padding: 0;
      text-decoration: underline;
      color: #ffffff;
    }
  }

  .btn-forgot-password {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #969696;
    border: none;
    margin-bottom: 3.2rem;
  }

  .bind {
    width: 100%;
    max-width: 36rem;
    height: 48px;
    background: #cd0715;
    border-radius: 8px;

    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    border: none;
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
  }
`;

const MemberInfo = () => {
  const { isLoading, sendRequest, setIsLoading } = useHttpClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(modalTypeEnum.PrivacyPolicy);
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  useEffect(() => {
    if (auth.isLoggedIn) {
      navigate('/member/reservation');
    }
  }, [auth, navigate]);
  const code = searchParams.get('code');
  const state = searchParams.get('state');

  const formSchema = yup.object().shape({
    email: yup.string().required('Email為必填').email('請輸入正確Email'),
    name: yup.string().required('姓名為必填').max(32, '姓名最長為32字元'),
    mobile: yup
      .string()
      .required('手機號碼為必填')
      .matches(/^09\d{2}-\d{3}-\d{3}$/, '請輸入正確手機號碼'),
    agreement: yup.bool().oneOf([true], '請勾選'),
  });

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({ resolver: yupResolver(formSchema) }); ///  TODO: componentize

  useEffect(() => {
    if ((!code && !state) || localStorage.getItem('state') !== state) {
      navigate('/member/login');
    }
    const fetchToken = async () => {
      setIsLoading(true);
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/users/auth/line',
          'POST',
          qs.stringify({ code }),
          { 'Content-Type': 'application/x-www-form-urlencoded' },
        );
        const { id_token } = responseData?.response;
        const decodedData = jwtDecode(id_token);
        localStorage.removeItem('state');
        setValue('name', decodedData.name);
        setValue('email', decodedData.email);
        try {
          const res = await sendRequest(
            process.env.REACT_APP_BACKEND_URL + '/users/login/line',
            'POST',
            JSON.stringify({
              email: decodedData.email,
            }),
            {
              'Content-Type': 'application/json',
            },
          );
          // user not registered with this line email yet === res.code 202
          if (res.code === 202) {
            return;
          } else {
            auth.login(res.id, res.token);
            setIsLoading(false);
            navigate('/member/reservation');
          }
        } catch (err) {
          console.log('err :>>', err);
        }
      } catch (err) {
        console.log('err :>> ', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchToken();
    setSearchParams({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, code, navigate, sendRequest, setSearchParams, setValue, state]);

  const onSubmit = async data => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/users/bind/line',
        'POST',
        JSON.stringify({
          email: data.email,
          name: data.name,
          mobile: data.mobile,
        }),
        {
          'Content-Type': 'application/json',
        },
      );
      auth.login(responseData.id, responseData.token);
      navigate('/member/reservation');
    } catch (err) {
      console.log(err);
    }
  };

  const formatPhoneNumber = value => {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you format the area code to early

    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 4)}-${phoneNumber.slice(4)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `${phoneNumber.slice(0, 4)}-${phoneNumber.slice(
      4,
      7,
    )}-${phoneNumber.slice(7, 10)}`;
  };

  const handlePhoneNumberInput = e => {
    // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    // we'll set the input value using our setValue
    setValue('mobile', formattedPhoneNumber, {
      shouldValidate: true,
    });
  };
  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}
      <StyledInfoForm onSubmit={handleSubmit(onSubmit)}>
        <h1 className="title">會員資訊填寫</h1>
        <div className="input-elements">
          <input
            className="input-element"
            type="email"
            readOnly={true}
            autoComplete="on"
            placeholder="Email"
            {...register('email')}
          />
          {errors.email && <ErrorMessage message={errors.email.message} />}
          <input
            className="input-element"
            type="text"
            placeholder="姓名"
            {...register('name')}
          />
          {errors.name && <ErrorMessage message={errors.name.message} />}
          <input
            className="input-element"
            placeholder="手機號碼"
            {...register('mobile')}
            onChange={e => handlePhoneNumberInput(e)}
          />
          {errors.mobile && <ErrorMessage message={errors.mobile.message} />}
          <div className="checkbox-agreement">
            <input
              className="box-agreement"
              type="checkbox"
              id="agreement"
              name="agreement"
              {...register('agreement')}
            />
            <label className="text-agreement">我已經閱讀並同意</label>
            <Link
              onClick={() => {
                setShowModal(true);
                setModalType(modalTypeEnum.TermsOfService);
              }}
              className="tc-wrapper"
            >
              <span className="tc-quote">「</span>
              <label className="tc-link">服務條款</label>
              <span className="tc-quote">」</span>
            </Link>
            <div>
              {errors.agreement && (
                <ErrorMessage message={errors.agreement.message} />
              )}
            </div>
          </div>
        </div>
        <button className="bind" onClick={handleSubmit(onSubmit)}>
          綁定會員
        </button>
      </StyledInfoForm>
      <TermsAndPrivacy
        show={showModal}
        setShow={setShowModal}
        type={modalType}
      />
    </React.Fragment>
  );
};

export default MemberInfo;
