export const onClickLineAuth = async () => {
  const randomStr = Math.random().toString(36).substring(2, 15);
  localStorage.setItem('state', randomStr);
  let URL = 'https://access-auto.line.me/oauth2/v2.1/authorize?';
  // 必填
  URL += 'response_type=code'; // 希望LINE回應什麼  但是目前只有code能選
  URL += `&client_id=${process.env.REACT_APP_LINE_CHANNEL_ID}`; // 你的頻道ID
  URL += `&redirect_uri=${process.env.REACT_APP_LINE_REDIRECT_URL}`; // 要接收回傳訊息的網址
  URL += `&state=${randomStr}`; // 用來防止跨站請求的 之後回傳會傳回來給你驗證 通常設亂數
  URL += '&scope=profile%20openid%20email'; // 跟使用者要求的權限 目前就三個能選 openid profile email
  // 選填
  URL += '&ui_locales=zh-Hant';
  URL += '&nonce=helloWorld';
  URL += '&max_age=3600';
  URL += '&ui_locales=en-US';
  URL += '&prompt=consent';
  URL += '&bot_prompt=aggressive';
  URL += '&initial_amr_display=lineqr';
  window.open(URL, '_self'); // 轉跳到該網址
};
