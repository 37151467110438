import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import carZecureIcon from 'assets/icon-zecure.svg';
import carIcon from 'assets/icon-car.svg';
import carIconRed from 'assets/icon-car-red.svg';
import menuIcon from 'assets/imgs/menu-icon.svg';
import plusIcon from 'assets/imgs/plus-icon.svg';
import minusIcon from 'assets/imgs/minus-icon.svg';
import loginIcon from 'assets/imgs/login-icon.svg';
import loginIconRed from 'assets/imgs/login-icon-red.svg';
import arrowDownIcon from 'assets/imgs/arrow-down.svg';
import searchIcon from 'assets/imgs/search.svg';
import searchIconRed from 'assets/imgs/search-red.svg';
import styled from 'styled-components';
import { AuthContext } from 'shared/context/auth-context';

import 'bootstrap/dist/css/bootstrap.min.css';
import OnClickOutsideAlerter from 'shared/utils/OnClickOutsideAlerter';

const StyledNavbar = styled(Navbar)`
  width: 100%;
  height: 5.9rem;
  padding: 0;
  background-color: black;

  .navbar-collapse {
    padding-left: 1.85rem;
    padding-right: 1.85rem;
  }

  .navbar-nav {
    background-color: transparent;
  }

  .bg-transparent {
    background-color: transparent;
  }

  .navbar-brand {
    width: 12.4rem;
    height: 2.4rem;
    padding: 0;
    margin: 0;
  }

  .d-inline-block {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .nav-link,
  .dropdown-item {
    color: white;
    height: 4.8rem;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 2px solid #262626;
    background-color: transparent;
    &.active {
      color: white;
    }
    &:hover {
      border-bottom: 2px solid #850009;
    }
    &:focus {
      border-bottom: 2px solid #cd0715;
    }
  }

  .nav-item {
    background-color: transparent;
  }

  .dropdown-item {
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #969696;
    border-bottom: none;
    padding: 1.6rem;
    &:hover {
      background: #850009;
      border-radius: 8px;
      color: white;
    }
  }

  .navbar-toggler {
    padding: 2.2rem;
    background: none;
    border: none;
    &:focus {
      box-shadow: 0 0 0 0;
    }
  }

  .navbar-toggler-icon {
  }

  .hamburger {
    width: 1.6rem;
    height: 1.6rem;
    background-image: url(${menuIcon});
    background-size: contain;
  }

  .dropdown-toggle.show.nav-link {
    color: white;
    border-bottom: 2px solid #850009;
    background-color: transparent;
    &:after {
      border: none;
      margin-left: auto;
      width: 2.4rem;
      height: 2.4rem;
      background-image: url(${minusIcon});
      background-size: contain;
      background-repeat: no-repeat;
    }

    .right-wrapper {
      display: none;
      background-color: transparent;
    }
  }

  .dropdown-toggle.nav-link {
    color: white;
    &:after {
      border: none;
      width: 2.4rem;
      height: 2.4rem;
      margin-left: auto;
      background-image: url(${plusIcon});
    }
  }

  .dropdown-menu.show {
    background: none;
  }

  .right-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: fit-content;
    height: 100%;
    background: transparent;
    gap: 0.8rem;
    margin-right: 1.2rem;

    .login-register-button {
      width: 7rem;
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      border: none;
      text-decoration: none;
      background-color: transparent;
      :hover {
        color: #cd0715;
      }
    }

    .login-button {
      display: flex;
      width: 1.806rem;
      height: 2.168rem;
      background-image: url(${loginIcon});
      background-size: contain;
      background-repeat: no-repeat;
      border: none;
      background-color: transparent;
      :hover {
        background-image: url(${loginIconRed});
      }
    }

    .car-button {
      display: flex;
      width: 2.4rem;
      height: 2.4rem;
      background-image: url(${carIcon});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      border: none;
      background-color: transparent;
    }

    .car-count-badge {
      display: flex;
      flex-direction: row;
      gap: 0;
      align-items: center;
      background-color: transparent;
      text-decoration: none;

      .decorator {
        width: 0;
        height: 0;
        margin-left: 0.4rem;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-right: 4px solid #1c1c1c;
        background-color: transparent;
      }

      :hover {
        .car-button {
          background-image: url(${carIconRed});
        }
        .car-count {
          background-color: #cd0715;
        }
        .decorator {
          border-right: 4px solid #cd0715;
        }
      }

      .car-count {
        display: flex;
        font-family: 'Albert Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        border-radius: 2px;
        color: #ffffff;
        padding: 0.45rem 0.5rem 0.35rem 0.5rem;
        background-color: #1c1c1c;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    .search-btn {
      /* display: none; */
      width: 2.4rem;
      height: 2.4rem;
      background-image: url(${searchIcon});
      background-size: contain;
      background-repeat: no-repeat;
      border: none;
      background-color: transparent;
      :hover {
        background-image: url(${searchIconRed});
      }
    }
  }

  .search-bar-wrapper {
    width: 36rem;
    height: 4.8rem;
    background: none;
    /* border: none; */
    margin-right: 1.6rem;
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    .wrapper {
      /* width: 36rem; */
      height: 4.8rem;

      background: none;
      /* padding: 1.6rem; */
      > div {
        display: border-box;
        background: none;
        /* margin-top: 1.6rem; */
        /* margin-bottom: 1.6rem; */
        /* padding-top: 0;
        padding-bottom: 0;
        */
        /* padding: 1.6rem; */
        /* height: 3rem; */

        .search-icon {
          width: 2.4rem;
          height: 2.4rem;
          margin-right: 2.4rem;
        }
      }
    }
  }

  @media only screen and (min-width: 400px) {
    .right-wrapper {
      gap: 2.688rem;
    }
  }

  @media only screen and (min-width: 992px) {
    display: flex;
    justify-content: space-around;
    background-color: transparent;

    .navbar-collapse {
      margin: 0;
      flex-grow: 0;
      flex-shrink: 1;
      background-color: transparent;
    }

    .navbar-brand,
    .d-inline-block {
      width: 16rem;
      height: 4.345rem;
      object-fit: contain;
      background: #cd0715;
    }

    .nav-link {
      width: 12rem;
      height: 3.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: none;
    }

    .highlight.nav-link {
      border-bottom: 2px solid #cd0715;
    }

    .dropdown-toggle.show.nav-link {
      &:after {
        margin-left: 0.8rem;
        width: 1.4rem;
        height: 1.4rem;
        background-image: url(${arrowDownIcon});
      }
    }

    .dropdown-toggle.nav-link {
      &:after {
        width: 1.4rem;
        height: 1.4rem;
        margin-left: 0.8rem;
        background-image: url(${arrowDownIcon});
      }
    }
    .dropdown-menu.show {
      width: 100%;
      margin-top: 0.8rem;
      background: #1c1c1c;
      border-radius: 0.8rem;
      align-items: center;
    }
    .dropdown-item {
      &:hover {
        border-radius: 0;
        color: white;
      }
    }

    .right-wrapper {
      justify-self: end;
      order: 1;
    }

    .login-button.remove-hover-highlight {
      :hover {
        background-image: url(${loginIcon});
      }
    }

    .login-register-button.remove-hover-highlight {
      :hover {
        color: white;
      }
    }

    .car-count-badge.remove-hover-highlight {
      :hover {
        .car-button {
          background-image: url(${carIcon});
        }
        .car-count {
          background-color: #1c1c1c;
        }
        .decorator {
          border-right: 4px solid #1c1c1c;
        }
      }
    }

    .search-btn.remove-hover-highlight {
      :hover {
        background-image: url(${searchIcon});
      }
    }
  }

  @media only screen and (min-width: 1100px) {
    display: flex;
    justify-content: center;
    padding-left: 5.5rem;
    padding-right: 3.5rem;
  }

  @media only screen and (min-width: 1200px) {
    justify-content: space-between;
    padding-left: 5.5rem;
    padding-right: 3.5rem;
    .right-wrapper {
      .login-button {
        display: flex;
      }
      .search-btn {
        display: flex;
      }
    }
  }
`;

const NavBar = () => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(false);
  }, []);

  return (
    <StyledNavbar
      // collapseOnSelect
      sticky="top"
      expand="lg"
      expanded={expanded}
      onToggle={() => setExpanded(prev => !prev)}
      onSelect={() => {
        setExpanded(false);
      }}
    >
      <StyledNavbar.Toggle aria-controls="responsive-navbar-nav">
        <div className="hamburger"></div>
      </StyledNavbar.Toggle>
      <StyledNavbar.Brand as={Link} to="/" href="#">
        <img src={carZecureIcon} alt="" className="d-inline-block" />
      </StyledNavbar.Brand>
      <div className="right-wrapper">
        {!auth.isLoggedIn && (
          <Link
            className={`login-register-button ${
              location.pathname === '/' ? 'remove-hover-highlight' : ''
            }`}
            to="/member/login"
          >
            登入
          </Link>
        )}
        {auth.isLoggedIn && (
          <Link
            className={`login-button ${
              location.pathname === '/' ? 'remove-hover-highlight' : ''
            }`}
            to="/member/reservation"
          />
        )}
        <Link
          to="/reservation/form"
          className={`car-count-badge ${
            location.pathname === '/' ? 'remove-hover-highlight' : ''
          }`}
        >
          <div to="/reservation/form" className="car-button"></div>
          <div className="decorator"></div>
          <div className="car-count">
            {!!auth.reservedCars ? auth.reservedCars.length : 0}
          </div>
        </Link>
        <Link
          className={`search-btn  ${
            location.pathname === '/' ? 'remove-hover-highlight' : ''
          }`}
          to="/search"
        ></Link>
      </div>
      <StyledNavbar.Collapse id="responsive-navbar-nav">
        <OnClickOutsideAlerter
          // expanded={expanded}
          setExpanded={setExpanded}
        >
          <Nav className="mr-auto">
            <Nav.Link
              className={location.pathname === '/' ? 'highlight' : ''}
              as={Link}
              to="/"
              href="#"
            >
              首頁
            </Nav.Link>
            <Nav.Link
              className={location.pathname === '/topics' ? 'highlight' : ''}
              as={Link}
              to="topics"
              href="#"
            >
              專題介紹
            </Nav.Link>
            <Nav.Link
              className={location.pathname === '/inventory' ? 'highlight' : ''}
              as={Link}
              to="inventory"
              href="#"
            >
              在庫車款
            </Nav.Link>
            <Nav.Link
              className={
                location.pathname.includes('service') ? 'highlight' : ''
              }
              as={Link}
              to="service/eval"
              href="#"
            >
              線上服務
            </Nav.Link>
            <Nav className="mr-auto">
              <NavDropdown title="關於我們">
                <NavDropdown.Item as={Link} to="aboutUs" href="#">
                  車咖簡介
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="aboutUs/offer" href="#">
                  服務項目
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="aboutUs/latestNews" href="#">
                  最新消息
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="aboutUs/contact" href="#">
                  聯絡我們
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="aboutUs/recruitment" href="#">
                  人才招募
                </NavDropdown.Item>
              </NavDropdown>
              {auth.isLoggedIn && (
                <NavDropdown title="會員中心">
                  <NavDropdown.Item as={Link} to="member/reservation" href="#">
                    預約清單
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="member/address" href="#">
                    地址
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="member/membership" href="#">
                    會員資料
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} onClick={auth.logout} href="#">
                    登出
                  </NavDropdown.Item>
                </NavDropdown>
              )}
            </Nav>
          </Nav>
        </OnClickOutsideAlerter>
      </StyledNavbar.Collapse>
    </StyledNavbar>
  );
};

export default NavBar;
