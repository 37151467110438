import styled from 'styled-components';

const StyledSectionContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 30rem;
  padding: 8rem 0 8rem 0;
  padding: 0;
  margin: auto;
  video {
    width: 100%;
  }
`;

const HomeVideo = () => {
  return (
    <StyledSectionContainer className="container-wrapper">
      <video loop={true} muted={true} autoPlay={true} playsInline={true}>
        <source
          src={`${process.env.REACT_APP_DATA_URL}/uploads/home/home-video.mp4`}
          type="video/mp4"
        />
      </video>
    </StyledSectionContainer>
  );
};

export default HomeVideo;
