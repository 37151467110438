import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import lineIcon from 'assets/imgs/lineIcon.svg';
import { useForm } from 'react-hook-form';
import ErrorMessage from 'shared/components/ErrorMessage';
import { AuthContext } from 'shared/context/auth-context';
import { useHttpClient } from 'shared/hooks/http-hook';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import * as yup from 'yup';
import { onClickLineAuth } from 'shared/utils/lineAuth';

const StyledLoginForm = styled.form`
  margin: auto;
  padding: 4rem 1.6rem 0 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #969696;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }

  .title {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    margin-bottom: 3.2rem;
  }

  .btn-line {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 36rem;
    height: 4.8rem;
    background: #07c203;
    mix-blend-mode: normal;
    border-radius: 0.8rem;
  }

  .line-logo {
    width: 2rem;
    height: 2rem;
    background: none;
  }

  .btn-text {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    // display: flex;
    color: #ffffff;
    background: none;
  }

  .input-elements {
    width: 100%;
    max-width: 36rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.4rem;
    margin: 3.2rem auto 1.2rem auto;
  }

  .input-element {
    width: 100%;
    background: #010001;
    border: 1px solid #969696;
    border-radius: 8px;

    height: 4.8rem;

    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #ffffff;
    padding: 1.3rem 1.6rem;
    :focus {
      outline: none;
    }
  }

  .btn-forgot-password {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #969696;
    border: none;
    margin-bottom: 3.2rem;
  }

  .login {
    width: 100%;
    max-width: 36rem;
    height: 48px;
    background: #cd0715;
    border-radius: 8px;

    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    border: none;
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
  }

  .bottom-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.8rem;
  }

  .bottom-text,
  .btn-go-to-register {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    margin: auto;
  }
  .btn-go-to-register {
    border: none;
    padding: 0;
    text-decoration: underline;
  }

  .registered {
    display: none;
    align-self: flex-start;
    padding-left: 1.6rem;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    color: #ff3846;
    margin-top: -2rem;
    margin-bottom: 0rem;
  }

  .not-registered-text {
    display: flex;
  }

  .not-registered-input-element {
    border: 1px solid #ff3846;
  }
`;

const MemberLogin = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [loginError, setLoginError] = useState('');
  useEffect(() => {
    if (auth.isLoggedIn) {
      navigate('/member/reservation');
    }
  }, [auth, navigate]);

  const { isLoading, sendRequest } = useHttpClient();

  const onSubmit = async data => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/users/login',
        'POST',
        JSON.stringify({
          email: data.email,
          password: data.password,
        }),
        {
          'Content-Type': 'application/json',
        },
      );
      auth.login(responseData.id, responseData.token);
      navigate('/member/reservation');
    } catch (err) {
      console.log(err);
      setLoginError('Email或密碼錯誤。');
    }
  };

  const formSchema = yup.object().shape({
    email: yup.string().required('Email為必填').email('請輸入正確Email'),
    password: yup
      .string()
      .required('密碼為必填')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\(\)_\+\-\=\[\]\{}|;:'",.<>\/?\\`~])(?=.{8,})/,
        '密碼為8到32個字元，至少各有1個大小寫英文字，一個數字，和一個特殊符號',
      )
      .max(32, '密碼最長為32個字元'),
  });

  const {
    register,
    formState: { errors, isValidating },
    handleSubmit,
  } = useForm({ resolver: yupResolver(formSchema) });

  useEffect(() => {
    setLoginError('');
  }, [isValidating]);

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}
      <StyledLoginForm onSubmit={handleSubmit(onSubmit)}>
        <h1 className="title">登入</h1>
        <button onClick={onClickLineAuth} className="btn-line" type="button">
          <img className="line-logo" src={lineIcon} alt="" />
          <span className="btn-text">&nbsp; 使用LINE登入</span>
        </button>
        <div className="input-elements">
          <input
            className={`input-element ${
              auth.isLoggedIn ? '' : 'not-registered-input-element'
            }`}
            type="email"
            autoComplete="on"
            placeholder="Email"
            {...register('email')}
          />
          {errors.email && <ErrorMessage message={errors.email.message} />}
          {loginError && <ErrorMessage message={loginError} />}
          {/* <p
            className={`registered ${
              auth.isLoggedIn ? "" : "not-registered-text"
            }`}
          >
            此信箱尚未註冊，請先加入會員
          </p> */}
          <input
            className="input-element"
            type="password"
            autoComplete="on"
            placeholder="密碼"
            {...register('password')}
          />
          {errors.password && (
            <ErrorMessage message={errors.password.message} />
          )}
        </div>
        <button
          type="button"
          onClick={e => {
            e.preventDefault();
            navigate('/member/forgotPassword');
          }}
          className="btn-forgot-password"
        >
          忘記密碼?
        </button>
        <button
          className="login"
          onClick={handleSubmit(onSubmit)}
          type="submit"
        >
          登入會員
        </button>
        <div className="bottom-wrapper">
          <p className="bottom-text">還不是會員?</p>
          <Link to="/member/register" className="btn-go-to-register">
            前往註冊
          </Link>
        </div>
      </StyledLoginForm>
    </React.Fragment>
  );
};

export default MemberLogin;
