import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  content: '';
  display: flex;
  height: 45.6rem;
  background: ${props => `linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.4) 100%
  ),
  url(${props.img}) center center/cover no-repeat;`}
    .line {
    display: none;
    width: 5.6rem;
    height: 5.6rem;
    background: none;
    align-self: end;
    margin: 3.8rem;
  }

  @media only screen and (min-width: 500px) {
    .line {
      display: block;
    }
  }
`;

const StyledCardBody = styled(Card.Body)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 1.6rem;

  position: relative;
  top: 53%;
  height: 18.4rem;
  margin: auto;
  width: 90%;
  bottom: 1.7rem;
  background: transparent;
`;

const StyledBreadcrumb = styled(Breadcrumb)`
  * {
    background: transparent;
  }

  & {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    background: transparent;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    color: #969696;
    content: '>';
  }

  .breadcrumb-item.active {
    color: #ffffff;
    background: transparent;
  }

  a {
    color: #969696;
    text-decoration: none;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0.8rem 1.6rem;
  gap: 0.8rem;

  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  height: 3.4rem;

  /* #850009 */

  background: #850009;
  border-radius: 4rem;
  border-color: #850009;

  font-family: 'Albert Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;

  &:hover,
  &:active,
  &:focus {
    background: #850009;
    border-color: #850009;
  }
`;

const StyledCardTitle = styled(Card.Title)`
  font-family: 'Albert Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.2rem;
  color: #ffffff;
  background: transparent;
`;

const StyledCardSubtitle = styled(Card.Subtitle)`
  width: 8rem;
  height: 1.8rem;

  /* Regular 14 */

  font-family: 'Albert Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
  /* identical to box height, or 129% */

  /* Text/ff */

  color: #ffffff;
  background: transparent;
`;

const TopicHeader = ({ item }) => {
  return (
    <StyledCard
      img={`${process.env.REACT_APP_DATA_URL}/uploads/topic/${item.topic_banner_image}`}
    >
      <StyledCardBody>
        <StyledBreadcrumb>
          <Breadcrumb.Item href="#">專題介紹</Breadcrumb.Item>
          <Breadcrumb.Item href="#">{item.brand}</Breadcrumb.Item>
          <Breadcrumb.Item active>{item.topic_title}</Breadcrumb.Item>
        </StyledBreadcrumb>
        <StyledButton variant="dark">{item.brand}</StyledButton>
        <StyledCardTitle>
          {item.brand} {item.topic_title}
        </StyledCardTitle>
        <StyledCardSubtitle>
          {item.updated_at.slice(0, 10).replaceAll('-', '/')}
        </StyledCardSubtitle>
      </StyledCardBody>
    </StyledCard>
  );
};

export default TopicHeader;
