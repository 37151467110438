import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  flex-wrap: wrap;

  .img {
    display: flex;
    height: 48rem;
    width: 100%;
    max-width: 85.4rem;
  }

  .body {
    display: flex;
    flex-direction: column;
    padding: 1.6rem 4rem 1.6rem 4rem;
    max-width: 51.2rem;
    background: none;
  }

  .title {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    color: #ffffff;
    padding: 1.6rem 0 1.6rem 0;
    align-self: stretch;
    max-width: 51.2rem;
    background: none;
  }

  .text {
    padding: 1.6rem 0 1.6rem 0;
    height: 10rem;
    max-width: 51.2rem;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
    color: #ffffff;
    align-self: stretch;
    background: none;
  }

  @media only screen and (min-width: 800px) {
    position: relative;
    height: 48rem;
    flex-direction: column-reverse;
    .img {
      position: absolute;
      right: 0;
    }
    .body {
      position: relative;
      top: 1rem;
      margin-right: 43%;
      padding-right: 0;
      max-width: 77.2rem;
      .title,
      .text {
        text-align: right;
        width: auto;
      }
    }
  }
`;

// TODO: Merge w/ Topic Card Right
const TopicCardLeft = ({ item }) => {
  return (
    <Container>
      <div
        className="img"
        style={{
          background: `linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 100%),
                             url(${`${process.env.REACT_APP_DATA_URL}/uploads/topic/${item.left_car_image}`})
                             center center/cover no-repeat`,
        }}
        variant="top"
      />
      <div className="body">
        <div className="title">{item.left_car_title}</div>
        <div className="text">{item.left_car_description}</div>
      </div>
    </Container>
  );
};
export default TopicCardLeft;
