import React, { useState, useContext } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { AuthContext } from 'shared/context/auth-context';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import styled from 'styled-components';
import { Autoplay, Pagination, Controller } from 'swiper';
import ReservationModal from 'shared/components/ReservationModal';

const StyledSwiper = styled(Swiper)`
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    height: 29.5rem;
  }

  .slide-title {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: transparent;
    padding-top: 1rem;
    padding-left: 1.4rem;
    .brand {
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      text-align: center;
      color: #ffffff;
      background-color: transparent;
    }
    .name {
      width: 100%;
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      color: #ffffff;
      background-color: transparent;
    }
  }
  .image-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 90%;

    background-color: #1c1c1c;
    border-radius: 16px;
    z-index: -1;
    .image {
      width: 80%;
      height: 70%;
      margin-top: 10%;
      margin-left: 10%;
      object-fit: contain;
      object-position: bottom;
      background-color: transparent;
    }
  }

  .swiper-pagination {
    bottom: 0rem;
  }

  .swiper-pagination-bullet {
    border-radius: 0;
    width: 3.2rem;
    height: 0.2rem;
    background: white;
  }

  .swiper-pagination-bullet-active {
    /* im: #fff; */
  }

  @media only screen and (min-width: 990px) {
    position: relative;
    z-index: 1;
    .swiper-slide {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 45.4rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      transform: skewX(-20deg) !important;
      .slide-title {
        padding-top: 30rem;
        padding-left: 0;
        transform: skewX(20deg);
      }
      .image-wrapper {
        .image {
          /* height: 100%; */
          /* margin: 0; */
          /* width: 110%; */
          object-position: top;
          transform: skewX(20deg);
        }
      }
    }

    .swiper-slide-active {
      height: 63.4rem;
      flex-basis: 64rem;
      clip-path: polygon(0 0, 115.5% 0, 78.5% 100%, 0% 100%);
      transform: skewX(0deg) !important;

      .slide-title {
        padding-top: 4.6rem;
        padding-left: 5.1rem;
        transform: skewX(0deg);
        .brand {
          font-size: 24px;
          line-height: 32px;
        }
        .name {
          font-size: 56px;
          line-height: 72px;
        }
      }
      .image-wrapper {
        width: 114.5%;
        .image {
          transform: skewX(0);
          object-position: bottom;
        }
      }
    }

    .swiper-slide-prev {
      visibility: hidden;
    }

    .swiper-slide-next {
      height: 63.4rem;
      margin-left: 4rewm;
      .image-wrapper {
        width: 90%;
        height: 64.3%;
        .image {
          /* width: 100%; */
        }
      }
    }

    .swiper-pagination {
      display: flex;
      justify-content: flex-end;
      bottom: 18rem;
      background-color: transparent;
      padding-right: 5.1rem;
    }
  }
`;

const ReserveButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2.4rem;
  gap: 1rem;

  width: 14.8rem;
  height: 4.4rem;
  border: 2px solid #ffffff;
  border-radius: 3rem;
  font-family: 'Albert Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 2rem;
  color: #ffffff;
  text-decoration: none;
  margin: 3.2rem auto auto auto;

  :hover {
    background-color: #cd0715;
    color: #ffffff;
  }

  @media only screen and (min-width: 990px) {
    margin: auto 5.1rem auto 0;
  }
`;

const TextSwiper = styled(Swiper)`
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    /* width: 29.5rem; */
    /* height: 29.5rem; */
    /* background-color: #1c1c1c;
    border-radius: 16px; */
  }

  .swiper-slide-active {
    /* min-width: 30rem; */
    margin-right: 0;
  }

  .descriptions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 4rem;
    max-width: 60rem;
    margin: auto;
    background-color: transparent;

    ul {
      display: flex;
      flex-direction: column;
      height: max-content;
      width: max-content;
      align-items: center;
      justify-content: center;
      padding: 0 1.2rem 0 1.2rem;
      background-color: transparent;
    }

    li {
      background-color: transparent;
    }
  }

  .unit {
    display: inline-block;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2rem;
    align-self: stretch;
    color: #ffffff;
    order: 1;
    flex-grow: 0;
    background-color: transparent;
  }

  .highlight {
    display: inline-block;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 2rem;
    color: #ffffff;
    align-self: stretch;
    height: 2rem;
    width: max-content;
    margin: auto;
  }

  .caption {
    width: 8.4rem;
    height: 2rem;
    font-family: 'Albert Sans';
    font-style: normal;
    font-size: 1.2rem;
    line-height: 2rem;
    color: #ffffff;
    align-self: stretch;
    height: fit-content;
    width: max-content;
    margin: auto;
    background-color: transparent;
  }

  @media only screen and (min-width: 990px) {
    position: relative;
    bottom: 17rem;
    background-color: transparent;
    .swiper-slide,
    .swiper-slide-active {
      background-color: transparent;
    }
    .bottom-wrapper {
      width: 100%;
      height: 90%;
      background-color: transparent;
      display: flex;
      gap: 4rem;
      justify-content: end;
      .descriptions {
        width: fix-content;
        margin: 0;
        gap: 4rem;
        background-color: transparent;
        .highlight {
          font-size: 40px;
          line-height: 32px;
          background-color: transparent;
        }
        z-index: 9;
      }
      z-index: 9;
    }
  }
`;

const CarouselHomeInventory = ({ cars }) => {
  const [controlledSwiper, setControlledSwiper] = useState(null);
  const [controlledTextSwiper, setControlledTextSwiper] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const auth = useContext(AuthContext);
  return (
    <>
      <StyledSwiper
        lazy={true}
        slidesPerView={1.3}
        centeredSlides={true}
        spaceBetween={20}
        loop={true}
        breakpoints={{
          990: {
            slidesPerView: 3,
            centeredSlides: false,
            spaceBetween: 15,
          },
        }}
        autoplay={{
          delay: 50000,
          disableOnInteraction: false,
        }}
        grabCursor={true}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Controller]}
        scrollbar={{ draggable: true }}
        controller={{ control: controlledSwiper }}
        onSwiper={setControlledTextSwiper}
      >
        {cars.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="slide-title">
              <h1 className="brand">{item.brand}</h1>
              <h2 className="name">{item.title}</h2>
            </div>
            <div className="image-wrapper">
              <img
                className="image"
                src={`${process.env.REACT_APP_DATA_URL}/inventory/${item.image}`}
                alt=""
              />
            </div>
          </SwiperSlide>
        ))}
      </StyledSwiper>
      <TextSwiper
        lazy={true}
        slidesPerView={1.001}
        centeredSlides={true}
        loop={true}
        spaceBetween={0}
        grabCursor={true}
        modules={[Controller]}
        onSwiper={setControlledSwiper}
        controller={{ control: controlledTextSwiper }}
      >
        {cars.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="bottom-wrapper">
              <div className="descriptions">
                <ul>
                  <li>
                    <span className="highlight">{item.speed}</span>
                    &nbsp;
                    <span className="unit">&nbsp;秒</span>
                  </li>
                  <li className="caption">0-100 公里/小時</li>
                </ul>
                <ul>
                  <li>
                    <span className="highlight">{item.mileage}</span>
                    &nbsp;
                    <span className="unit">公里</span>
                  </li>

                  <li className="caption">里程數</li>
                </ul>
                <ul>
                  <li>
                    <span className="highlight">{item.drive}</span>
                    &nbsp;
                  </li>
                  <li className="caption">驅動系統</li>
                </ul>
              </div>
              <ReserveButton
                onClick={() => {
                  auth.addReservedCar({
                    id: item.id,
                    price: item.price,
                    img: `${process.env.REACT_APP_DATA_URL}/inventory/${item.image}`,
                    title: item.title,
                  });
                  setShowModal(true);
                }}
              >
                立即預約
              </ReserveButton>
            </div>
          </SwiperSlide>
        ))}
      </TextSwiper>
      <ReservationModal show={showModal} setShow={setShowModal} />
    </>
  );
};

export default CarouselHomeInventory;
