import React, { useMemo } from 'react';
import styled from 'styled-components';
import Badge from 'react-bootstrap/Badge';
import ellipse from 'assets/ellipse.svg';
import HoverVideoPlayer from 'react-hover-video-player';
import useWindowDimensions from 'shared/hooks/useWindowDimensions';

const StyledSectionContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // height: 63rem;
  // padding: 8rem 0 8rem 0;
  padding: 0;
  margin: auto;
`;

const StyledHoverVideoPlayer = styled(HoverVideoPlayer)`
  display: flex;
  margin: auto;
  background: none;
`;

const StyledSection = styled.section`
  position: relative;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  isolation: isolate;
  height: 100%;
  max-width: 100%;
  align-self: stretch;
  background: ${props => `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(${props.img}) center center/auto no-repeat;`};
  background-size: 100%;
`;

const StyledBadge = styled(Badge)`
  gap: 0.8rem;
  margin: auto;
  width: 7.6rem;
  color: #969696;
  background: rgba(1, 0, 1, 0.4);
  border: none;

  font-family: 'Albert Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;
  text-align: center;
`;

const StyledDivEllipse = styled.div`
  display: flex;
  max-width: 42rem;
  max-height: 42rem;
  background: none;
  @media only screen and (min-width: 42rem) {
    width: 42rem;
    height: 42rem;
    align-items: center;
    background: url('${ellipse}');
  }
`;

const StyledDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  gap: 3.6rem;
  margin: auto;
  background: none;

  h1 {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 3.2rem;
    line-height: 3.2rem;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    margin: auto;
    background: none;
  }

  h2 {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2rem;
    color: #ffffff;
    margin: auto;
    background: none;
  }
  .badge {
    background-color: rgba(1, 0, 1, 0.4) !important;
    border-radius: 3.2rem;
  }

  button {
    border-radius: 3.2rem;
    border: 1px solid #807f80;
    background: rgba(1, 0, 1, 0.2);
    width: 6.4rem;
    height: 6.4rem;
  }
`;

const TopicVideo = ({ item }) => {
  const { width } = useWindowDimensions();

  const isMobile = useMemo(() => {
    if (width < 500) {
      return true;
    } else return false;
  }, [width]);

  return (
    <StyledSectionContainer className="container-wrapper">
      <StyledHoverVideoPlayer
        videoSrc={`${process.env.REACT_APP_DATA_URL}/uploads/topic/${item.video}`}
        muted={true}
        videoStyle={{
          margin: 'auto',
          objectFit: 'cover',
        }}
        controls
        controlsList="nodownload"
        pausedOverlay={
          <StyledSection
            img={`${process.env.REACT_APP_DATA_URL}/uploads/topic/${item.video_overlay_image}`}
          >
            <StyledDivEllipse>
              <StyledDiv>
                {!isMobile && (
                  <h1>
                    {item.brand} <br />
                    {item.topic_title}
                  </h1>
                )}
                <button type="button">
                  <svg
                    width="16"
                    height="23"
                    viewBox="0 0 16 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.01553 22.0805C1.35629 22.575 0.415527 22.1046 0.415527 21.2805L0.415526 1.28053C0.415526 0.456488 1.35629 -0.0138946 2.01553 0.480532L15.3489 10.4805C15.8822 10.8805 15.8822 11.6805 15.3489 12.0805L2.01553 22.0805Z"
                      fill="white"
                    />
                  </svg>
                </button>
                <h2>PLAY NOW</h2>
                {!isMobile && <StyledBadge>{item.video_length}</StyledBadge>}
              </StyledDiv>
            </StyledDivEllipse>
          </StyledSection>
        }
        loadingOverlay={
          <div className="loading-overlay">
            <div className="loading-spinner" />
          </div>
        }
      />
    </StyledSectionContainer>
  );
};

export default TopicVideo;
