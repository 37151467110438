import React, { useRef, useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, expanded, setExpanded) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !event.target.classList.contains('navbar-toggler') &&
        !event.target.classList.contains('hamburger') &&
        !event.target.classList.contains('dropdown-toggle')
      ) {
        setExpanded(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [expanded, ref, setExpanded]);
}

/**
 * Component that alerts if you click outside of it
 */

export default function OnClickOutsideAlerter(props) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props.expanded, props.setExpanded);

  return (
    <div className="bg-transparent" ref={wrapperRef}>
      {props.children}
    </div>
  );
}
