import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import emailIcon from 'assets/imgs/email-icon.svg';
import phoneIcon from 'assets/imgs/phone-icon.svg';
import addressIcon from 'assets/imgs/address-icon.svg';
import CloseButton from 'react-bootstrap/CloseButton';
import ErrorMessage from 'shared/components/ErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useHttpClient } from 'shared/hooks/http-hook';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import { RECRUITMENT_BANNER } from 'shared/constants/assets';

const StyledRecruitmentSection = styled.section`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #969696;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }

  .banner {
    position: absolute;
    width: 100%;
    max-width: 144rem;
    margin: auto;
    overflow: hidden;
    height: 48rem;
    object-fit: cover;
    z-index: 1;
  }

  .image-overlay {
    position: relative;
    width: 100%;
    height: 48rem;
    content: '';
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    z-index: 2;
  }

  .contact-wrapper {
    width: 100%;
    max-width: 40rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    background: none;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    padding-bottom: 5.6rem;
    margin: 2.4rem auto 0 auto;
    background: none;

    .contact-title {
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #ffffff;
      background: none;
      padding-bottom: 1.5rem;
      align-self: center;
      background: none;
    }
    .contact-item-wrapper {
      display: flex;
      flex-direction: row;
      gap: 1.8rem;
      background: none;
    }
    .contact-item-icon {
      width: 2.4rem;
      height: 2.4rem;
      background: none;
    }

    .contact-item-text {
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #ffffff;
      background: none;
    }
  }

  .sub-form-wrapper {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    width: 100%;
    max-width: 640px;
    background: rgba(1, 0, 1, 0.4);
    backdrop-filter: blur(4px);
    border-radius: 1.6rem;
    margin: auto;
  }

  .sub-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2.4rem;
    padding: 0;
    background: none;
  }

  .form-element {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    background: none;
  }

  .element-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    padding: 0;
    margin: 0;
    background: none;
  }

  .element-input {
    width: 100%;
    padding: 0 1.6rem;
    background: #010001;
    height: 4.8rem;
    border: 1px solid #969696;
    border-radius: 8px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: white;
    background: none;

    ::placeholder {
      color: #969696;
    }

    :focus {
      outline: none;
    }
  }

  .more-description {
    padding: 1.2rem 1.6rem;
    height: 9.6rem;
    resize: none;
  }

  .upload-file-input {
    display: none;
  }

  .upload-file-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2.4rem;
  }

  .upload-file {
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    border: 0.2rem solid #ffffff;
    border-radius: 3rem;
    background: none;
    padding: 1rem 2.4rem;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    justify-self: end;
    flex: 0 0 auto;
  }

  .upload-file-wrapper-inner {
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
    justify-content: flex-end;
    align-items: center;
    flex: 10 1 auto;
  }

  .uploaded-file {
    height: fit-content;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    display: flex;
    flex: 0 1 auto;
    word-break: break-all;
    text-align: right;
    vertical-align: middle;
  }

  .btn-bottom-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
    justify-content: flex-end;
    padding-top: 3.2rem;
    padding-bottom: 7.4rem;
    background: none;
  }

  .btn-confirm {
    width: 100%;
    height: 48px;
    background: #cd0715;
    border: 2px solid #cd0715;
    border-radius: 8px;
    border-color: none;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  @media only screen and (min-width: 720px) {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 0rem;
    border-bottom: 2px solid #262626;

    .sub-form {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 3.2rem;
    }

    .half-element {
      width: 47%;
    }

    .upload-file-wrapper-inner {
      justify-content: flex-start;
    }

    .btn-bottom-wrapper {
      flex-direction: column;
      align-items: center;
    }

    .btn-confirm {
      max-width: 100%;
      z-index: 2;
    }
  }

  @media only screen and (min-width: 992px) {
    .banner {
      top: 0;
    }
    .image-overlay {
      top: -5.9rem;
    }
  }

  @media only screen and (min-width: 1100px) {
    height: 81.6rem;
    .top-wrapper {
      width: 100%;
      max-width: 144rem;
      margin: auto;
      overflow: hidden;
      position: absolute;
      display: flex;
      flex-direction: row;
      top: 16rem;
      background: none;
      justify-content: center;
      z-index: 3;
      gap: 8rem;

      .contact-wrapper {
        margin: 29rem 0 0 0;
        padding: 0;
        .contact-title {
          font-family: 'Albert Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 56px;
          line-height: 72px;
        }
      }
      .sub-form-wrapper {
        margin: 0;
        padding: 4rem;

        .btn-bottom-wrapper {
          padding-bottom: 0;
        }
      }
    }
  }
`;

const Recruitment = () => {
  const [file, setFile] = useState();
  const [recruitmentBg, setRecruitmentBg] = useState(undefined);
  const handleFileChange = e => {
    console.log(e.target.files[0]);
    if (e.target.files && e.target.files[0].size < 1000000) {
      setFile(e.target.files[0]);
    }
  };

  const handleFileDelete = () => {
    setFile(null);
  };

  const formSchema = yup.object().shape({
    name: yup.string().required('姓名為必填').max(32, '姓名最長為32字元'),
    line: yup.string().max(100, '請輸入正確LINE ID'),
    title: yup.string().required('主旨為必填').max(100, '主旨最長為100字'),
    resume: yup
      .string()
      .required('相關履歷為必填(請簡單說明)')
      .max(255, '相關履歷最長為255字'),
  });

  const {
    register,
    formState: { errors, isDirty, isSubmitting },
    reset,
    handleSubmit,
  } = useForm({ resolver: yupResolver(formSchema) });

  const { isLoading, error, sendRequest } = useHttpClient();
  const [responseMessage, setResponseMessage] = useState('');

  useEffect(() => {
    const fetchRecruitmentBg = async () => {
      try {
        const res = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/bg/content',
          'GET',
        );
        setRecruitmentBg(res.recruitment_bg);
      } catch (err) {}
    };
    fetchRecruitmentBg();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDirty || isSubmitting) {
      setResponseMessage('');
    }
  }, [isDirty, isSubmitting]);

  const onSubmit = async data => {
    try {
      const formData = new FormData();
      formData.append('name', data.name);
      formData.append('line', data.line);
      formData.append('title', data.title);
      formData.append('resume', data.resume);
      formData.append('file', file);
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/upload/recruitment',
        'POST',
        formData,
      );
      reset({ name: '', line: '', title: '', resume: '' });
      setFile(null);
      setResponseMessage(responseData.message);
    } catch (err) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}
      {recruitmentBg && (
        <StyledRecruitmentSection>
          <img
            className="banner"
            src={
              !!recruitmentBg
                ? `${process.env.REACT_APP_DATA_URL}/uploads/bg/${recruitmentBg}`
                : RECRUITMENT_BANNER
            }
            alt=""
          />
          <div className="image-overlay"></div>
          <div className="top-wrapper">
            <div className="contact-wrapper">
              <h1 className="contact-title">人才招募</h1>
              <div className="contact-item-wrapper">
                <img className="contact-item-icon" src={emailIcon} alt="" />
                <a href = "mailto: sales@carzec.com" className="contact-item-text">sales@carzec.com</a>
              </div>
              <div className="contact-item-wrapper">
                <img className="contact-item-icon" src={phoneIcon} alt="" />
                <label for="phone" className="contact-item-text">0800-666-106</label>
              </div>
              <div className="contact-item-wrapper">
                <img className="contact-item-icon" src={addressIcon} alt="" />
                <a href = "https://maps.app.goo.gl/PvM19jsVHNTYXNyE6" className="contact-item-text">
                  10491台北市中山區建國北路三段173號
                </a>
              </div>
            </div>
            <form
              className="sub-form-wrapper"
              onSubmit={handleSubmit(onSubmit)}
            >
              <ul className="sub-form">
                <li className="form-element half-element">
                  <h3 className="element-title">姓名</h3>
                  <input
                    className="element-input"
                    type="text"
                    placeholder="請輸入"
                    {...register('name')}
                  />
                  {errors.name && (
                    <ErrorMessage message={errors.name.message} />
                  )}
                </li>
                <li className="form-element half-element">
                  <h3 className="element-title">LINE ID (選填)</h3>
                  <input
                    className="element-input"
                    type="text"
                    placeholder="請輸入"
                    {...register('line')}
                  />
                  {errors.line && (
                    <ErrorMessage message={errors.line.message} />
                  )}
                </li>
                <li className="form-element">
                  <h3 className="element-title">主旨</h3>
                  <input
                    className="element-input"
                    type="text"
                    placeholder="請輸入"
                    {...register('title')}
                  />
                  {errors.title && (
                    <ErrorMessage message={errors.title.message} />
                  )}
                </li>
                <li className="form-element">
                  <h3 className="element-title">
                    相關履歷（可上傳小於1MB的PDF檔案補充說明）
                  </h3>
                  <textarea
                    className="element-input more-description"
                    type="text"
                    placeholder="請輸入"
                    {...register('resume')}
                  />
                  {errors.resume && (
                    <ErrorMessage message={errors.resume.message} />
                  )}
                </li>
                <li className="upload-file-wrapper">
                  <input
                    className="upload-file-input"
                    type="file"
                    name="pdf-file"
                    id="upload-pdf-file"
                    accept="application/pdf"
                    onClick={e => {
                      e.target.value = null;
                      console.log('Reset file');
                    }}
                    onChange={e => {
                      handleFileChange(e);
                    }}
                  />
                  <label className="upload-file" htmlFor="upload-pdf-file">
                    上傳履歷
                  </label>
                  <div className="upload-file-wrapper-inner">
                    <label className="uploaded-file">
                      {file ? `${file.name}` : ''}
                    </label>
                    <CloseButton
                      style={{
                        display: file ? `block` : 'none',
                      }}
                      variant="white"
                      onClick={() => handleFileDelete()}
                    ></CloseButton>
                  </div>
                  {responseMessage && (
                    <ErrorMessage message={responseMessage} />
                  )}
                </li>
              </ul>
              <div className="btn-bottom-wrapper">
                <button
                  type="button"
                  className="btn-confirm"
                  onClick={handleSubmit(onSubmit)}
                >
                  確定送出
                </button>
              </div>
            </form>
          </div>
        </StyledRecruitmentSection>
      )}
    </React.Fragment>
  );
};

export default Recruitment;
