import CarouselBanner from 'components/Topics/CarouselBanner';
import TopicIntroduction from 'components/Topics/TopicIntroduction';
import { useHttpClient } from 'shared/hooks/http-hook';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import React, { useState, useEffect } from 'react';

const Topics = () => {
  const { isLoading, sendRequest } = useHttpClient();
  const [topicItems, setTopicItems] = useState([]);

  useEffect(() => {
    const fetchTopicItems = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/topic',
          'GET',
        );
        setTopicItems(responseData);
      } catch (err) {}
    };
    fetchTopicItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}
      <CarouselBanner items={topicItems.slice(0, 4)} />
      <TopicIntroduction items={topicItems} />
    </React.Fragment>
  );
};

export default Topics;
