import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  flex-wrap: wrap;
  margin-top: 8rem;
  margin-bottom: 8rem;

  .image {
    height: 48rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    height: 48rem;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .body {
    display: flex;
    flex-direction: column;
    padding: 3.2rem 4rem 3.2rem 4rem;
    margin: auto;
    max-width: 120rem;
    background: none;
  }

  .title {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    color: #ffffff;
    padding: 1.6rem 0 1.6rem 0;
    align-self: stretch;
    max-width: 112rem;
    background: none;
  }

  .text {
    padding: 1.6rem 0 1.6rem 0;
    max-width: 112rem;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
    color: #ffffff;
    align-self: stretch;
    background: none;
  }
`;

const TopicCardCenter = ({ item }) => {
  return (
    <Container>
      <img
        src={`${process.env.REACT_APP_DATA_URL}/uploads/topic/${item.bottom_car_image}`}
        alt="car"
      ></img>
      <div className="body">
        <div className="title">{item.bottom_car_title}</div>
        <div className="text">{item.bottom_car_description} </div>
      </div>
    </Container>
  );
};
export default TopicCardCenter;
