import React, { Suspense, useLayoutEffect } from 'react';
import { createBrowserRouter, Outlet, useLocation } from 'react-router-dom';
import Navbar from 'components/Layout/NavBar';
import Footer from 'components/Layout/Footer';
import 'index.css';
import { AuthContext } from 'shared/context/auth-context';
import { useAuth } from 'shared/hooks/auth-hook';
import LoadingSpinner from 'shared/components/LoadingSpinner';

// import Inventory from 'pages/Inventory';

// const Home = React.lazy(() => import('pages/Home'));
// const Topic = React.lazy(() => import('pages/Topic'));
// const Topics = React.lazy(() => import('pages/Topics'));
// const Inventory = React.lazy(() => import('pages/Inventory'));
// const Reservation = React.lazy(() => import('pages/Reservation'));
// const ReservationForm = React.lazy(() => import('pages/ReservationForm'));
// const ServiceForm = React.lazy(() => import('pages/ServiceForm'));
// const Member = React.lazy(() => import('pages/Member'));
// const MemberLogin = React.lazy(() => import('pages/MemberLogin'));
// const MemberBind = React.lazy(() => import('pages/MemberBind'));
// const MemberForgotPassword = React.lazy(() =>
// 	import('pages/MemberForgotPassword')
// );
// const MemberResetPassword = React.lazy(() =>
// 	import('pages/MemberResetPassword')
// );
// const MemberRegister = React.lazy(() => import('pages/MemberRegister'));
// const AboutUs = React.lazy(() => import('pages/AboutUs'));
// const Offers = React.lazy(() => import('pages/Offers'));
// const ContactUs = React.lazy(() => import('pages/ContactUs'));
// const Recruitment = React.lazy(() => import('pages/Recruitment'));
// const Search = React.lazy(() => import('pages/Search'));

import Home from 'pages/Home';
import Topic from 'pages/Topic';
import Topics from 'pages/Topics';
import Inventory from 'pages/Inventory';
import Reservation from 'pages/Reservation';
import ReservationForm from 'pages/ReservationForm';
import ServiceForm from 'pages/ServiceForm';
import Member from 'pages/Member';
import MemberLogin from 'pages/MemberLogin';
import MemberBind from 'pages/MemberBind';
import MemberForgotPassword from 'pages/MemberForgotPassword';
import MemberResetPassword from 'pages/MemberResetPassword';
import MemberRegister from 'pages/MemberRegister';
import AboutUs from 'pages/AboutUs';
import Offers from 'pages/Offers';
import ContactUs from 'pages/ContactUs';
import Recruitment from 'pages/Recruitment';
import Search from 'pages/Search';

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const Layout = () => {
  const {
    token,
    login,
    logout,
    userId,
    reservedCars,
    addReservedCar,
    removeReservedCar,
    clearReservedCar,
  } = useAuth();
  return (
    <Wrapper>
      <AuthContext.Provider
        value={{
          isLoggedIn: !!token,
          token: token,
          userId: userId,
          reservedCars: reservedCars,
          addReservedCar: addReservedCar,
          removeReservedCar: removeReservedCar,
          clearReservedCar: clearReservedCar,
          login: login,
          logout: logout,
        }}
      >
        <Suspense fallback={<LoadingSpinner asOverlay />}>
          <Navbar />
          <Outlet />
          <Footer />
        </Suspense>
      </AuthContext.Provider>
    </Wrapper>
  );
};

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: 'topics',
        element: <Topics />,
      },
      {
        path: 'topics/:topicId',
        element: <Topic />,
      },
      {
        path: 'inventory',
        element: <Inventory />,
      },
      {
        path: 'inventory/:carId',
        element: <Reservation />,
      },
      {
        path: 'reservation',
        element: <Reservation />,
      },
      {
        path: 'reservation/form',
        element: <ReservationForm />,
      },
      {
        path: 'service/eval',
        element: <ServiceForm activeKey="eval" />,
      },
      {
        path: 'service/sale',
        element: <ServiceForm activeKey="sale" />,
      },
      {
        path: 'aboutUs',
        element: <AboutUs />,
      },
      {
        path: 'aboutUs/offer',
        element: <Offers />,
      },
      {
        path: 'aboutUs/contact',
        element: <ContactUs />,
      },
      {
        path: 'aboutUs/latestNews',
        element: <Topics />,
      },
      {
        path: 'aboutUs/recruitment',
        element: <Recruitment />,
      },
      {
        path: 'member/login',
        element: <MemberLogin />,
      },
      {
        path: 'member/bind',
        element: <MemberBind />,
      },
      {
        path: 'member/register',
        element: <MemberRegister />,
      },
      {
        path: 'member/reservation',
        element: <Member activeKey="reservation" />,
      },
      {
        path: 'member/address',
        element: <Member activeKey="address" />,
      },
      {
        path: 'member/membership',
        element: <Member activeKey="membership" />,
      },
      {
        path: 'member/resetPassword/:userId/:token',
        element: <MemberResetPassword />,
      },
      {
        path: 'member/forgotPassword',
        element: <MemberForgotPassword />,
      },
      {
        path: '/search',
        element: <Search />,
      },
      {
        path: '*',
        element: <Home />,
      },
    ],
  },
]);

export default router;
