import React from 'react';
import HomeBanner from 'components/Home/HomeBanner';
import HomeInventory from 'components/Home/HomeInventory';
import HomeTopic from 'components/Home/HomeTopic';
import HomeVideo from 'components/Home/HomeVideo';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useHttpClient } from 'shared/hooks/http-hook';
import LoadingSpinner from 'shared/components/LoadingSpinner';

const StyledSection = styled.section`
  max-width: 144rem;
  margin-right: auto;
  margin-left: auto;
`;

const Home = () => {
  const [bannerItem, setBannerItem] = useState({});
  const [topicItem, setTopicItem] = useState([]);
  const [inventoryItems, setInventoryItems] = useState([]);
  const { isLoading, sendRequest } = useHttpClient();
  useEffect(() => {
    const fetchTopicItems = async () => {
      try {
        const items = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/home/content',
          'GET',
        );

        // Put the item with id === topicId to the first
        // console.log(items);
        setBannerItem(items.home);
        setInventoryItems(items.inventory);
        setTopicItem(items.topic);
      } catch (err) {}
    };
    fetchTopicItems();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(bannerItem);
  return (
    <StyledSection>
      {isLoading && <LoadingSpinner asOverlay />}
      {!isLoading &&
        !!bannerItem &&
        inventoryItems.length > 0 &&
        !!topicItem && (
          <>
            {/* <HomeBanner item={bannerItem} /> */}
            <HomeVideo />
            <HomeInventory inventoryItems={inventoryItems.slice(0, 4)} />
            <HomeTopic bannerItem={bannerItem} topicItem={topicItem} />
          </>
        )}
    </StyledSection>
  );
};

export default Home;
