import React from 'react';
import styled from 'styled-components';

const StyledSpinner = styled.div`
  .lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
    background: transparent;
  }

  .lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid white;
    border-color: white white white transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  .loading-spinner__overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /* background: rgba(255, 255, 255, 0.9); */
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoadingSpinner = props => {
  return (
    <StyledSpinner>
      <div className={`${props.asOverlay && 'loading-spinner__overlay'}`}>
        <div className="lds-dual-ring"></div>
      </div>
    </StyledSpinner>
  );
};

export default LoadingSpinner;
