import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';

const StyledReservationModal = styled(Modal)`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  .modal-header,
  .modal-body,
  .radio-form,
  .modal-footer,
  .modal-dialog {
    background: transparent;
    border: none;
  }

  .modal-header {
    padding-top: 4rem;
  }

  .modal-title {
    margin: auto;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Text/ff */

    color: #ffffff;
    background: transparent;
  }

  .modal-content {
    max-width: 48rem;
    background: #262626;
    border-radius: 16px;
    border-color: none;
  }

  .btn-confirm {
    width: 240px;
    height: 48px;

    /* Background/ff */
    border: 2px solid #ffffff;
    border-radius: 8px;
    background: transparent;
    margin: 0rem auto 4rem auto;

    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
  }
`;

const ReservationModal = ({ show, setShow }) => {
  const handleClose = () => setShow(false);

  return (
    <StyledReservationModal show={show} onHide={handleClose} centered>
      <Modal.Header closeVariant="white">
        <Modal.Title>已新增預約車輛</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button
          className="btn-confirm"
          onClick={() => {
            handleClose();
          }}
        >
          確認
        </Button>
      </Modal.Footer>
    </StyledReservationModal>
  );
};

export default ReservationModal;
