export const SCI_FI_BG = `${`${process.env.REACT_APP_DATA_URL}/assets/imgs/modern-futuristic-sci-fi-background.png`}`;
export const SERVICE_BG = `${`${process.env.REACT_APP_DATA_URL}/assets/imgs/service-bg.png`}`;
export const RECRUITMENT_BANNER = `${`${process.env.REACT_APP_DATA_URL}/assets/imgs/recruitment-banner.png`}`;
export const CONTACT_BG = `${`${process.env.REACT_APP_DATA_URL}/assets/imgs/contact-banner.png`}`;
export const ABOUT_US_IMG = `${`${process.env.REACT_APP_DATA_URL}/assets/imgs/about-us-img.png`}`;
export const ABOUT_US_IMG_1 = `${`${process.env.REACT_APP_DATA_URL}/assets/imgs/about-us-1-img.png`}`;
export const ABOUT_US_IMG_2 = `${`${process.env.REACT_APP_DATA_URL}/assets/imgs/about-us-2-img.png`}`;
export const ABOUT_US_IMG_3 = `${`${process.env.REACT_APP_DATA_URL}/assets/imgs/about-us-3-img.png`}`;
export const ABOUT_US_IMG_4 = `${`${process.env.REACT_APP_DATA_URL}/assets/imgs/about-us-4-img.png`}`;
export const ABOUT_US_IMG_5 = `${`${process.env.REACT_APP_DATA_URL}/assets/imgs/about-us-5-img.png`}`;
export const ABOUT_US_IMG_6 = `${`${process.env.REACT_APP_DATA_URL}/assets/imgs/about-us-6-img.png`}`;
