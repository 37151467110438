import React from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { modalTypeEnum } from './Footer';

const StyledModal = styled(Modal)`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  .modal-header,
  .modal-body,
  .modal-body,
  .modal-footer,
  .modal-dialog {
    background: transparent;
    border: none;
  }

  .modal-dialog {
    max-width: 47rem;
  }

  .modal-header {
    padding-top: 4rem;
  }

  .modal-title {
    margin: auto;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Text/ff */

    color: #ffffff;
    background: transparent;
  }

  .modal-content {
    max-width: 47rem;
    background: #262626;
    border-radius: 16px;
    border-color: none;
  }

  .btn-confirm {
  }
`;

const ModalWrapper = styled.div`
  padding: 2rem;
  margin: auto;
  background: #1c1c1c;
  border-radius: 8px;
  margin: 2.4rem 0;
`;

const Title = styled.h1`
  font-family: 'Albert Sans';
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */
  /* Text/ff */
  color: #ffffff;
  font-weight: 700;
  background: transparent;
  padding-bottom: 1rem;
`;

const Content = styled.h2`
  font-family: 'Albert Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #ffffff;
  background: transparent;
`;

const StyledButton = styled(Button)`
  width: 240px;
  height: 48px;

  /* Background/ff */

  border: 2px solid #ffffff;
  border-radius: 8px;
  background: transparent;
  margin: 2rem auto;

  font-family: 'Albert Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
`;
const termsOfService = [
  {
    title: '認知與接受條款',
    content: `車咖企業有限公司(以下簡稱「車咖」)係依據本服務條款提供車咖（https://carzec.com/）服務 (以下簡稱「本服務」)。當會員完成車咖之會員註冊手續、或開始使用本服務時，即表示已閱讀、瞭解並同意接受本服務條款之所有內容，並完全接受本服務現有與未來衍生的服務項目及內容。車咖公司有權於任何時間修改或變更本服務條款之內容，修改後的服務條款內容將公佈網站上，車咖將不會個別通知會員，建議會員隨時注意該等修改或變更。會員於任何修改或變更後繼續使用本服務時，視為會員已閱讀、瞭解並同意接受該等修改或變更。若不同意上述的服務條款修訂或更新方式，或不接受本服務條款的其他任一約定，會員應立即停止使用本服務。`,
  },
  {
    title: '第二條',
    content: `若會員為未滿二十歲之未成年人，應於會員的家長（或監護人）閱讀、瞭解並同意本約定書之所有內容及其後修改變更後，方得註冊為會員、使用或繼續使用本服務。當會員使用或繼續使用車咖時，即推定會員的家長（或監護人）已閱讀、瞭解並同意接受本約定書之所有內容及其後修改變更。`,
  },
  {
    title: '第三條',
    content: `
    會員及車咖雙方同意使用本服務之所有內容包括意思表示等，以電子文件作為表示方式。
    `,
  },
  {
    title: '第四條',
    content: `
      為了確保會員之個人資料、隱私及消費者權益之保護，於使用車咖各項服務及交易過程中將使用會員之個人資料，謹依個人資料保護法第8條規定告知以下事項：
      	蒐集之目的：
      蒐集之目的在於進行行銷業務、消費者、客戶管理與服務及其他電子商務服務及與調查、統計與研究分析(法定特定目的項目編號為Ｏ四Ｏ、Ｏ九Ｏ、一四八、一五七)。車咖將藉由加入會員之過程、進行交易之過程、以及登入及使用網站、APP或閱讀軟體之過程等來蒐集個人資料。
      	蒐集之個人資料類別：
      車咖於所屬相關網站及APP內蒐集的個人資料包括：
      (1)	C001辨識個人者： 如會員之姓名、地址、電話、電子郵件等資訊。
      (2)	C002辨識財務者： 如信用卡或金融機構帳戶等資訊。
      (3)	C011個人描述：例如：性別、出生年月日等。
      (4)	其他車咖系統因會員登入及使用網站、APP或閱讀軟體等，所自動蒐集之資料。
      	利用期間、地區、對象及方式：
      (1)	期間：會員當事人要求停止使用或車咖停止提供服務之日為止。
      (2)	地區：會員之個人資料將使用於台灣地區。
      (3)	利用對象及方式：會員之個人資料蒐集除用於車咖之會員管理、客戶管理之檢索查詢等功能外，亦將利用於辨識身份、金流服務、物流服務、行銷廣宣等。例示如下：
      a.	以會員身份使用車咖提供之各項服務時，於頁面中自動顯示會員資訊。
      b.	為遂行交易行為：會員對商品或勞務為預約、下標、購買、參與贈獎等之活動或從事其他交易時，關於商品配送、勞務提供、價金給付、回覆客戶之詢問、車咖對會員之詢問、相關售後服務及其他遂行交易所必要之業務。
      c.	宣傳廣告或行銷等：提供會員各種電子雜誌等資訊、透過電子郵件、郵件、電話等提供與服務有關之資訊。將會員所瀏覽之內容或廣告，依客戶之個人屬性或購買紀錄、車咖網站之瀏覽紀錄等項目，進行個人化作業、會員使用服務之分析、新服務之開發或既有服務之改善等。針對民調、活動、留言版等之意見，或其他服務關連事項，與會員進行聯繫。
      d.	回覆客戶之詢問：針對會員透過電子郵件、郵件、、傳真、電話或其他任何直接間接連絡方式向車咖所提出之詢問進行回覆。
      e.	其他業務附隨之事項：附隨於上述a至d.之利用目的而為車咖提供服務所必要之使用。
      f.	對於各別服務提供者之資訊提供：會員對服務提供者之商品或勞務為預約、下標、購買、參加贈獎活動或申請其他交易時，車咖於該交易所必要之範圍內，得將會員之個人資料檔案提供予服務提供者，並由服務提供者負責管理該個人資料檔案。車咖將以規約課予服務提供者依保障會員隱私權之原則處理個人資料之義務，但無法保證服務提供者會必然遵守。詳細內容，請向各別服務提供者洽詢。
      g.	其他：提供個別服務時，亦可能於上述規定之目的以外，利用個人資料。此時將在該個別服務之網頁載明其要旨。
      	會員就個人資料之權利：
      車咖所蒐集個人資料之當事人，依個人資料保護法得對車咖行使以下權利：
      (1)	查詢或請求閱覽。
      (2)	請求製給複製本。
      (3)	請求補充或更正。
      (4)	請求停止蒐集、處理或利用。
      (5)	請求刪除。
      會員如欲行使上述權利，可與車咖客服連絡進行申請。惟請注意！如拒絕提供加入會員所需必要之資料，將可能導致無法享受完整服務或完全無法使用該項服務。
    `,
  },
  {
    title: '會員的註冊義務',
    content: `
    為了能使用本服務，會員同意以下事項：
    	依本服務註冊表之提示提供會員本人正確、最新的資料，且不得以第三人之名義註冊為會員。每位會員僅能註冊登錄一個帳號，不可重覆註冊登錄。
    	即時維持並更新會員個人資料，確保其正確性，以獲取最佳之服務。
    	若會員提供任何錯誤或不實的資料、或未按指示提供資料、或欠缺必要之資料、或有重覆註冊帳號等情事時，車咖有權不經事先通知，逕行暫停或終止會員的帳號，並拒絕會員使用本服務之全部或一部。
    `,
  },
  {
    title: '車咖隱私權政策',
    content: `關於會員的註冊以及其他特定資料，係依車咖「隱私權政策」條款受到保護與規範。`,
  },
  {
    title: '會員帳號、密碼及安全',
    content: `
    1.	完成本服務的登記程序之後，會員將取得一個特定之密碼及會員帳號，維持密碼及帳號之機密安全，是會員的責任。任何依照規定方法輸入會員帳號及密碼與登入資料一致時，無論是否由本人親自輸入，均將推定為會員本人所使用，利用該密碼及帳號所進行的一切行動，會員本人應負完全責任。
    2.	會員同意以下事項：
      	會員的密碼或帳號遭到盜用或有其他任何安全問題發生時，會員將立即通知車咖。
      	每次連線完畢，均結束會員的帳號使用。
      	會員的帳號、密碼及會員權益均僅供會員個人使用及享有，不得轉借、轉讓他人或與他人合用。
      	帳號及密碼遭盜用、不當使用或其他車咖無法辯識是否為本人親自使用之情況時，對此所致之損害，除證明係因可歸責於車咖之事由所致，車咖將不負任何責任。
      	車咖若知悉會員之帳號密碼確係遭他人冒用時，將立即暫停該帳號之使用(含該帳號所生交易之處理)。
      	會員需定期自行更改密碼。
      	會員應自行在其所使用之電腦設備或行動裝置上安裝防護軟體。
    `,
  },
  {
    title: '兒童及青少年之保護',
    content: `
    為確保兒童及青少年使用網路的安全，並避免隱私權受到侵犯，家長（或監護人）應盡到下列義務： 未滿十二歲之兒童使用本服務時時，應全程在旁陪伴，十二歲以上未滿十八歲之青少年使用本服務前亦應斟酌是否給予同意。
    `,
  },
  {
    title: '使用者的守法義務及承諾',
    content: `
    會員承諾絕不為任何非法目的或以任何非法方式使用本服務，並承諾遵守中華民國相關法規及一切使用網際網路之國際慣例。會員若係中華民國以外之使用者，並同意遵守所屬國家或地域之法令。會員同意並保證不得利用本服務從事侵害他人權益或違法之行為，包括但不限於：
      	公布或傳送任何誹謗、侮辱、具威脅性、攻擊性、不雅、猥褻、不實、違反公共秩序或善良風俗或其他不法之文字、圖片或任何形式的檔案
      	侵害或毀損車咖或他人名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利
      	違反依法律或契約所應負之保密義務
      	冒用他人名義使用本服務
      	傳輸或散佈電腦病毒
      	從事未經車咖事前授權的商業行為
      	刊載、傳輸、發送垃圾郵件、連鎖信、違法或未經車咖許可之多層次傳銷訊息及廣告等；或儲存任何侵害他人智慧財產權或違反法令之資料
      	對本服務其他用戶或第三人產生困擾、不悅或違反一般網路禮節致生反感之行為
      	其他不符本服務所提供的使用目的之行為或車咖有正當理由認為不適當之行為
    `,
  },
  {
    title: '服務內容之變更與電子報及EDM發送',
    content: `
      1.	會員同意車咖所提供本服務之範圍，車咖均得視業務需要及實際情形，增減、變更或終止相關服務的項目或內容，且無需個別通知會員。
      2.	會員同意車咖得依實際執行情形，增加、修改或終止相關活動，並選擇最適方式告知會員。
      3.	會員同意車咖得不定期發送電子報或商品訊息(EDM)至會員所登錄的電子信箱帳號。當會員收到訊息後表示拒絕接受行銷時，車咖將停止繼續發送行銷訊息。    
    `,
  },
  {
    title: '服務之停止、中斷',
    content: `
    車咖將依一般合理之技術及方式，維持系統及服務之正常運作。但於以下各項情況時，車咖有權可以停止、中斷提供本服務：
      	車咖網站電子通信設備進行必要之保養及施工時
      	發生突發性之電子通信設備故障時
      	車咖網站申請之電子通信服務被停止，無法提供服務時
      	由於天災等不可抗力之因素或其他不可歸責於車咖致使車咖網站無法提供服務時
    `,
  },
  {
    title: '交易行為',
    content: `
    1.	會員使用本服務進行交易時，應依據車咖所提供之確認商品數量及價格機制進行。
    2.	會員同意使用本服務進行交易時，於車咖通知確認交易成立前，車咖仍保有不接受或取消交易之權利。會員向車咖發出交易要約後，關於交易成立與否車咖將另行告知。若因交易內容之標的商品或服務，其交易條件(包括但不限於規格、內容說明、圖片、)有誤時，車咖仍得於交易完成後二工作日內拒絕或取消該筆交易。
    3.	會員若於使用本服務進行交易完成後，倘任意取消訂單、或有任何車咖認為不適當而造成車咖作業上之困擾或損害之行為，車咖將可視情況採取拒絕交易或永久取消會員資格辦理。且有關交易資訊，車咖將以最適方式(以電子郵件為主，再輔以電話、郵遞或傳真等)告知。
    4.	會員使用本服務進行交易時，得依照消費者保護法之規定行使權利。因會員之交易行為而對本服務條款產生疑義時，應為有利於消費者之解釋。    
    `,
  },
  {
    title: '責任之限制與排除',
    content: `1.	本服務所提供之各項功能，均依該功能當時之現況提供使用，車咖對於其效能、速度、完整性、可靠性、安全性、正確性等，皆不負擔任何明示或默示之擔保責任。
    2.	車咖並不保證本服務之網頁、伺服器、網域等所傳送的電子郵件或其內容不會含有電腦病毒等有害物；亦不保證郵件、檔案或資料之傳輸儲存均正確無誤不會斷線和出錯等，因各該郵件、檔案或資料傳送或儲存失敗、遺失或錯誤等所致之損害，車咖不負賠償責任。
    `,
  },
  {
    title: '智慧財產權的保護',
    content: `
    1.	車咖所使用之軟體或程式、網站上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計，均由車咖或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。任何人不得逕自使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。若會員欲引用或轉載前述軟體、程式或網站內容，必須依法取得車咖或其他權利人的事前書面同意。尊重智慧財產權是會員應盡的義務，如有違反，會員應對車咖負損害賠償責任（包括但不限於訴訟費用及律師費用等）。
    2.	在尊重他人智慧財產權之原則下，會員同意在使用車咖之服務時，不作侵害他人智慧財產權之行為。
    3.	若會員有涉及侵權之情事，車咖可暫停全部或部份之服務，或逕自以取消會員帳號之方式處理。
    4.	若有發現智慧財產權遭侵害之情事，請將所遭侵權之情形及聯絡方式，並附具真實陳述及擁有合法智慧財產權之聲明，以下列方式聯絡車咖： 以電子郵件(E-mail)寄送至： ○○○○@○○○
    (請注意！此為限定用途之信箱，非關侵權事項通知之信件寄送至此將不獲受理，車咖會員有任何疑問請至客服中心詢問)    
    `,
  },
  {
    title: '會員對車咖之授權',
    content: `
      對於會員上載、傳送、輸入或提供之資料，會員同意車咖網站得於合理之範圍內蒐集、處理、保存、傳遞及使用該等資料，以提供使用者其他資訊或服務、或作成會員統計資料、或進行關於網路行為之調查或研究，或為任何之合法使用。 若會員無合法權利得授權他人使用、修改、重製、公開播送、改作、散布、發行、公開發表某資料，並將前述權利轉授權第三人，請勿擅自將該資料上載、傳送、輸入或提供至車咖。任何資料一經會員上載、傳送、輸入或提供至車咖時，視為會員已允許車咖無條件使用、修改、重製、公開播送、改作、散布、發行、公開發表該等資料，並得將前述權利轉授權他人，會員對此絕無異議。會員並應保證車咖使用、修改、重製、公開播送、改作、散布、發行、公開發表、轉授權該等資料，不致侵害任何第三人之智慧財產權，否則應對車咖負損害賠償責任（包括但不限於訴訟費用及律師費用等）。
    `,
  },
  {
    title: '特別授權事項',
    content: `
    因使用本服務所提供之網路交易或活動，如有須透過宅配或貨運業者始能完成者，則會員同意並授權車咖得視該次網路交易或活動之需求及目的，將由會員所提供且為配送所必要之個人資料(如收件人姓名、配送地址、連絡電話等)，提供予宅配貨運業者及相關配合之廠商，以利完成該次交易。
    `,
  },
  {
    title: '拒絕或終止會員的使用',
    content: `
    會員同意車咖得基於維護交易安全之考量，因任何理由，包含但不限於缺乏使用，或違反本服務條款的明文規定及精神，終止會員的密碼、帳號（或其任何部分）或本服務（或其任何部分）之使用，或將本服務內任何「會員內容」加以移除並刪除。此外，會員同意若本服務（或其任何部分）之使用被終止，車咖對會員或任何第三人均不承擔責任。
    `,
  },
  {
    title: '準據法與管轄法院',
    content: `
      本服務條款之解釋與適用，以及與本服務條款有關或會員與車咖間因交易行為而產生之爭議或糾紛，應依照中華民國法律予以處理，並以台灣台北地方法院為第一審管轄法院，但若法律對於管轄法院另有強制規定者，仍應依其規定。
    `,
  },
];

const privacyPolicy = [
  {
    title: '適用範圍',
    content: `
      車咖企業有限公司( (以下簡稱「車咖」)非常重視會員的隱私權且遵循「個人資料保護法」之規定，因此制訂了隱私權保護政策，您可參考下列隱私權保護政策的內容。
      `,
  },
  {
    title: '個人資料之安全',
    content: `保護會員的個人隱私是車咖重要的經營理念，在未經會員同意之下，我們絕不會將會員的個人資料提供予任何與本購物網站服務無關之第三人。會員應妥善保密自己的網路密碼及個人資料，不要將任何個人資料，尤其是網路密碼提供給任何人。在使用完車咖所提供的各項服務功能後，務必記得登出帳戶，若是與他人共享電腦或使用公共電腦，切記要關閉瀏覽器視窗。`,
  },
  {
    title: '個人資料的蒐集、處理、利用',
    content: `車咖所提供之網站及相關服務所取得的個人資料，都僅供車咖於其內部、依照原來所說明的使用目的和範圍，除非事先說明、或依照相關法律規定，否則車咖不會將資料提供給第三人、或移作其他目的使用。
      	蒐集之目的：
      蒐集之目的在於進行行銷業務、消費者、客戶管理與服務、網路購物及其他電子商務服務及與調查、統計與研究分析(法定特定目的項目編號為Ｏ四Ｏ、Ｏ九Ｏ、一四八、一五七)。車咖將藉由加入會員之過程、進行交易之過程、以及登入及使用網站、APP或閱讀軟體之過程等來蒐集個人資料。
      	蒐集之個人資料類別：
      車咖於所屬相關網站及APP內蒐集的個人資料包括，
      (1)	C001辨識個人者： 如會員之姓名、地址、電話、電子郵件等資訊。
      (2)	C002辨識財務者： 如信用卡或金融機構帳戶資訊。
      (3)	C011個人描述：例如：性別、出生年月日等。
      (4)	其他車咖系統因會員登入及使用網站、APP或閱讀軟體等，所自動蒐集之資料。
      	利用期間、地區、對象及方式：
      (1)	期間：會員當事人要求停止使用或車咖停止提供服務之日為止。
      (2)	地區：會員之個人資料將用於台灣地區。
      (3)	利用對象及方式：會員之個人資料蒐集除用於車咖之會員管理、客戶管理之檢索查詢、各項相關服務之使用等功能外，亦將利用於辨識身份、金流服務、物流服務、行銷廣宣等。例示如下：
      a.	以會員身份使用車咖提供之各項服務時進行身分確認，或於頁面中自動顯示會員資訊等。
      b.	為遂行交易行為：會員對商品或勞務為預約、下標、購買、參與贈獎等之活動或從事其他交易時，關於商品配送、勞務提供、價金給付、回覆客戶之詢問、車咖對會員之詢問、相關售後服務及其他遂行交易所必要之業務。
      c.	宣傳廣告或行銷等：提供會員各種電子雜誌等資訊、透過電子郵件、郵件、電話等提供與服務有關之資訊。將會員所瀏覽之內容或廣告，依客戶之個人屬性或購買紀錄、車咖網站之瀏覽紀錄等項目，進行個人化作業、會員使用服務之分析、新服務之開發或既有服務之改善等。針對民調、活動、留言版等之意見，或其他服務關連事項，與會員進行聯繫。
      d.	回覆客戶之詢問：針對會員透過電子郵件、郵件、、傳真、電話或其他任何直接間接連絡方式向車咖所提出之詢問進行回覆。
      e.	其他業務附隨之事項：附隨於上述a至d.之利用目的而為車咖提供服務所必要之使用。
      f.	對於各別服務提供者之資訊提供：會員對服務提供者之商品或勞務為預約、下標、購買、參加贈獎活動或申請其他交易時，車咖於該交易所必要之範圍內，得將會員之個人資料檔案提供予服務提供者，並由服務提供者負責管理該個人資料檔案。車咖將以規約課予服務提供者依保障會員隱私權之原則處理個人資料之義務，但無法保證服務提供者會必然遵守。詳細內容，請向各別服務提供者洽詢。
      g.	其他：提供個別服務時，亦可能於上述規定之目的以外，利用個人資料。此時將在該個別服務之網頁載明其要旨。
      	會員就個人資料之權利：
      車咖所蒐集個人資料之當事人，依個人資料保護法得對車咖行使以下權利：
      (1)	查詢或請求閱覽。
      (2)	請求製給複製本。
      (3)	請求補充或更正。
      (4)	請求停止蒐集、處理或利用。
      (5)	請求刪除。
      會員如欲行使上述權利，可與車咖客服連絡進行申請。惟請注意！如拒絕提供加入會員所需必要之資料，將可能導致無法享受完整服務或完全無法使用該項服務。
      `,
  },
  {
    title: '資料安全',
    content: `為保障會員的隱私及安全，車咖會員帳號資料會用密碼保護。車咖並盡力以合理之技術及程序，保障所有個人資料之安全。`,
  },
  {
    title: '個人資料查詢或更正的方式',
    content: `會員對於其個人資料，有查詢及閱覽、製給複製本、補充或更正、停止電腦處理及利用、刪除等需求時，可以與客服中心聯絡，車咖將迅速進行處理。`,
  },
  {
    title: 'Cookie',
    content: `為了便利會員使用，車咖網站會使用cookie技術，以便於提供會員所需要的服務；cookie是網站伺服器用來和會員瀏覽器進行溝通的一種技術，它可能在會員的電腦中隨機儲存字串，用以辨識區別使用者，若會員關閉cookie有可能導致無法順利登入網站或無法使用等狀況。`,
  },
  {
    title: 'Google Analyics(分析)廣告功能',
    content: `本網站、服務已安裝 Google Analytics (分析) 廣告功能。本功能僅用於提供WOWHoo分析廣告投放效益及優化使用流程，如您欲停用 Google Analytics (分析) 廣告功能，建議您可參考Google所提供 不透露資訊的方式 以停用該功能。`,
  },
  {
    title: '隱私權保護政策修訂',
    content: `隨著市場環境的改變本公司將會不時修訂網站政策。會員如果對於車咖網站隱私權聲明、或與個人資料有關之相關事項有任何疑問，可以利用電子郵件和車咖客服中心聯絡。`,
  },
  {
    title:
      '車咖企業有限公司個人資料檔案安全維護計畫及業務終止後個人資料處理方法',
    content: `
      第一條(制定宗旨及依據)
      為防止個人資料被竊取、竄改、毀損、滅失或洩漏，並落實個人資料檔案之安全維護與管理，本公司依「網際網路零售業及網際網路零售服務平台業個人資料檔案安全維護計畫及業務終止後個人資料處理作業辦法」第三條規定，訂定博客來數位科技股份有限公司個人資料檔案安全維護計畫及業務終止後個人資料處理方法（以下稱本計畫）。本公司所屬人員應依本計畫辦理個人資料檔案安全維護及業務終止後個人資料處理事項。
      
      第二條(適用範圍)
      本計畫適用於本公司各項業務流程所蒐集、處理及利用之個人資料。
      
      第三條(名詞定義)
      本計畫名詞定義如下：
      一、	媒介物：指保有之個人資料所存在之紙本、磁碟、磁帶、光碟片、微縮片、積體電路晶片、電腦、自動化機器設備或其他媒介之物體者。
      二、	重大個人資料安全事故：係指個人資料遭竊取、竄改、毀損、滅失或洩漏，將危及本公司正常營運或大量當事人權益之情形。
      三、	軌跡資料：個人資料在蒐集、處理及利用過程中，所產生非屬於原蒐集個人資料本體之衍生資訊（包括但不限於軌跡檔案、當事人之帳號、存取時間、設備代號、網路位址）。
      
      第四條(配置個人資料管理人員及資源)
      本公司應配置個人資料保護管理人員及相當資源，以規劃及執行本計畫。
      
      第五條(界定個人資料之範圍)
      本公司應依個人資料保護相關法令，定期查核確認所保有之個人資料現況，界定其納入本計畫之範圍。
      
      第六條(個資清冊之建立)
      本公司應適時並每年定期清查保有之個人資料檔案及其蒐集、處理或利用個人資料之作業流程，據以建立個人資料檔案清冊及個人資料作業流程說明文件。
      
      第七條(風險評估及管理)
      本公司應依前條界定之個人資料範圍及其業務涉及個人資料蒐集、處理、利用之流程，評估可能產生之個人資料風險，並根據風險評估之結果，訂定適當之管理機制及因應措施。
      
      第八條(個人資料蒐集、處理及利用)
      本公司對於個人資料之蒐集、處理及利用，應建立內部管理程序，內容包含下列事項：
      一、	檢視個人資料之蒐集、處理，符合個人資料保護法（以下稱個資法）第十九條第一項所定之法定情形及特定目的，或有其他合法事由；其經當事人同意者，並確保符合個資法第七條之規定。
      二、	蒐集、處理及利用之個人資料包含個資法第六條所定特種個人資料者，檢視其特定目的及是否符合相關法令要件；其經當事人書面同意者，並應確保符合個資法第六條第二項準用第七條第一項、第二項及第四項之規定。
      三、	確認一般個人資料之利用，是否符合個資法第二十條規定蒐集之特定目的必要範圍；其為特定目的外之利用者，檢視是否符合法定情形，經當事人同意者，並確保符合個資法第七條之規定。
      四、	個人資料之蒐集，除個資法有特別規定者外，應履行告知義務，並確認告知之內容及方式是否合法妥適。
      五、	利用個人資料為行銷，於首次行銷時，提供當事人免費表示拒絕接受行銷之管道；而當事人已拒絕接受行銷者，應即停止利用其個人資料行銷，並周知所屬人員或採行防範所屬人員再次行銷之措施。
      六、	檢視個人資料之蒐集、處理、利用與個資法第五條之規定相符。
      七、	對個人資料進行國際傳輸前，應針對該次傳輸進行可能之影響及風險分析，並採取適當安全保護措施。
      八、	檢視所保有個人資料之特定目的是否消失，或期限是否屆滿，於特定目的消失、期限屆滿、有個資法第十九條第二項所定情形，或有違反個資法規定而為個人資料之蒐集、處理或利用時，應依法刪除或停止蒐集、處理、利用個人資料。
      九、	如於特定目的消失或期限屆滿，而未刪除、停止處理或利用個人資料時，須因執行業務所必須或經當事人書面同意。
      十、	檢視個人資料於蒐集、處理或利用過程中是否正確；其有不正確或正確性有爭議者，應依個資法第十一條第一項、第二項及第五項規定辦理。
      十一、	當事人行使個資法第三條所定權利之相關事項： (一)當事人身分之確認。 (二)提供當事人行使權利之方式，並告知所需支付之費用，及應釋明之事項。 (三)對當事人請求之審查方式，並遵守個資法有關處理期限之規定。(四)有個資法所定得拒絕當事人行使權利之事由者，其理由記載及通知當事人之方式。
      十二、	委託他人蒐集、處理或利用個人資料時，應對受託人依個資法施行細則第八條規定為適當之監督，並於委託契約或相關文件中，明確約定其內容。
      十三、	受他人委託處理個人資料之全部或一部時，如認委託機關之指示有違反個資法或其他個人資料保護相關法令者，應立即通知委託機關。
      十四、	若對當事人個人資料進行國際傳輸者，應檢視是否已受主管機關所限制，並且告知其個人資料所欲國際傳輸之區域，同時對資料接收方為下列事項之監督：(一)預定處理或利用個人資料之範圍、類別、特定目的、期間、地區、對象及方式。(二)當事人行使個資法第三條所定權利之相關事項。
      
      第九條(個人資料安全管理措施)
      一、	為維護所保有個人資料之安全，本公司考量業務性質、個人資料存取環境、個人資料傳輸之工具與方法及個人資料之種類、數量等因素，就下列事項執行安全管理措施：
      (一)	系統之資訊安全措施：（1）使用者身分確認及保護機制。 （2）個人資料顯示之隱碼機制。（3）網際網路傳輸之安全加密機制。（4）應用系統於開發、上線、維護等各階段軟體驗證與確認程序。（5）個人資料檔案及資料庫之存取控制與保護監控措施。 （6）防止外部網路入侵對策。 （7）非法或異常使用行為之監控與因應機制。 （8）定期對前二目所定措施進行演練及檢討改善。
      (二)	訂定各類設備或儲存媒體之使用規範，及報廢或轉作他用時，應採取防範資料洩漏之適當措施。
      (三)	蒐集、處理或利用個人資料時，如有加密或遮蔽之必要，應採取適當之加密或遮蔽機制。
      (四)	傳輸個人資料時，應有適當安全之防護機制。
      (五)	作業過程有備份個人資料之需要時，依據所保有個人資料之重要性，採取適當之備份機制，並比照原件保護之。
      (六)	對保有之個人資料存在於媒介物者應採取之設備安全管理措施： (1)實施適宜之存取管制。 (2)訂定妥善保管媒介物之方式。 (3)依媒介物之特性及其環境，建置適當之保護設備或技術。
      (七)	依執行業務之必要，設定相關人員接觸個人資料之權限及控管其接觸情形，並與所屬人員約定保密義務。
      (八)	個人資料儲存媒體於廢棄或轉作其他用途前，應以適當方式銷毀或確實刪除該媒體中所儲存之個人資料。委託他人執行上開行為時，準用個資法第九條第十二款之規定，，應為適當之監督
      二、	資料安全管理
      (一)	電腦存取個人資料之管控：
      (1)	本公司所屬員工應妥善保管個人電腦存取資料之硬體，並設定登入及螢幕保護程式密碼。個人資料使用完畢，應即退出電腦使用檔案，不得留置於電腦上。下班前應關閉電腦電源，並將所保有其他個人資料之媒介物置於專用抽屜內上鎖保管。
      (2)	本公司員工如因其工作職掌相關而須輸出、輸入個人資料時，均須鍵入其個人之使用者代碼及識別密碼，同時在使用範圍及使用權限內為之，其中識別密碼並應保密，不得洩漏或與他人共用。
      (3)	個人資料檔案使用完畢應即退出，不得任其停留於電腦終端機上。
      (4)	定期進行電腦系統防毒、掃毒之必要措施。
      (5)	重要個人資料（如護照號碼、國民身分證統一編號）應另加設管控密碼，非經陳報公司（商業）主管核可，並取得密碼者，不得存取。
      (6)	建置個人資料之個人電腦，不得直接作為公眾查詢之前端工具。
      (二)	紙本資料之保管：
      (1)	本公司保有個人資料存在於紙本者，應儲存於上鎖之保管箱或檔案室內，並對開啟調閱權限進行控管。
      (2)	儲存個人資料紙本之保管箱或檔案室內，應設置防火裝置及防竊措施。儲存個人資料之電腦主機系統應設置防火牆，降低外部入侵風險。主機置放之機房應設置門禁、監視錄影及防火設備。
      (3)	對於記載個人資料之紙本丟棄時，應先以碎紙設備進行處理。
      三、	人員管理
      (一)	應確認蒐集、處理及利用個人資料之相關業務流程之負責人員。
      (二)	本公司依據執行業務之必要，設定所屬人員關於個人資料蒐集、處理或利用，及接觸個人資料儲存媒體之相關權限，定期檢視權限設定內容之必要性，並控管接觸個人資料之情形。
      (三)	本公司所屬人員使用電腦設備蒐集、處理、利用個人資料，應以專屬帳號密碼登入電腦系統，存取個人資料檔案權限應與所職掌業務相符。專屬帳號密碼均應保密，不得洩漏或與他人共用。
      (四)	本公司員工應依公司規定定期變更識別密碼，並於變更識別密碼後始可繼續使用電腦。
      (五)	本公司員工應妥善保管個人資料之儲存媒介物，執行業務時依個人資料保護法規定蒐集、處理及利用個人資料。
      (六)	本公司與員工所簽訂之相關勞務契約或承攬契約均列入保密條款及相關之違約罰則，以確保其遵守對於個人資料內容之保密義務（含契約終止後）。
      (七)	因業務需要而須利用非權限範圍之特定個人資料者，應事前提出申請，經業務主管人員同意後開放權限利用。
      (八)	負責個人資料檔案管理人員於職務異動時，應將保管之檔案資料移交，接辦人員應另行設定密碼。
      四、	設備管理
      (一)	依據作業內容及環境之不同，實施必要之安全環境管制。
      (二)	妥善維護並控管個人資料蒐集、處理或利用過程中所使用之實體設備。
      (三)	針對不同作業環境，建置必要之保護設備或技術。
      (四)	建置個人資料之有關電腦設備，資料保有單位以及系統相關單位應定期保養維護，於保養維護或更新設備時，並應注意資料之備份及相關安全措施。
      (五)	建置個人資料之個人電腦，不得直接作為公眾查詢之前端工具。
      (六)	應指派專人管理儲存個人資料之相關電磁紀錄物或相關媒體資料，非經單位主管同意並作成紀錄不得攜帶外出或拷貝複製。
      (七)	重要個人資料備份應異地存放，並應建置防止個人資料遭竊取、竄改、損毀、滅失或洩漏等事故之機制。
      (八)	電腦、自動化機器或其他存放媒介物需報廢汰換或轉作其他用途時，本公司（商業）負責人或營業處所主管應檢視該設備所儲存之個人資料是否確實刪除。
      (九)	更新或維修電腦設備時，應指定專人在場，確保個人資料之安全及防止個人資料外洩。
      (十)	電腦設備報廢或不使用時，確實刪除電腦硬體設備中所儲存之個人資料檔案。
      五、	技術安全管理
      (一)	採取適當之安全機制，避免用以蒐集、處理或利用個人資料之電腦、相關設備或系統遭受無權限之存取，包括但不限就個人資料之存取權限，設定必要之控管機制，並定期確認控管機制之有效性。
      (二)	定期確認蒐集、處理或利用個人資料之電腦、相關設備或系統具備必要之安全性，包括但不限採取適當之安全機制，因應惡意程式及系統漏洞所造成之威脅。
      (三)	進行軟硬體測試時，應避免使用實際個人資料。如確有使用實際個人資料之必要時，應明確規定其使用之程序及安全管理方式。
      (四)	定期檢查使用於蒐集、處理或利用個人資料之電腦、相關設備或系統之使用狀況及個人資料存取之情形。
      
      第十條(緊急應變、通報及預防機制)
      為因應個人資料之竊取、竄改、毀損、滅失或洩漏等安全事故，應訂定下列應變、通報及預防機制：
      一、	事故發生後應採取之各類措施，包括： (一) 控制當事人損害之方式。 (二) 查明事故後通知當事人之適當方式。 (三)應通知當事人事故事實、所為因應措施及諮詢服務專線等內容。
      二、	事故發生後應受通報之對象及其通報方式。
      三、	事故發生後，其矯正預防措施之研議機制。
      遇有重大個人資料安全事故者，應於七十二小時內通報主管機關。但於其他法令以及規章制度另有規定時，並應依各該規定辦理。
      
      第十一條(教育訓練)
      本公司應定期對員工施以個人資料保護與管理認知宣導及教育訓練，使其明瞭相關法令之要求、人員之責任範圍與各種個人資料保護機制、程序及措施。對第四條所稱管理單位或適當組織之人員，另應依其於安全維護計畫所擔負之任務及角色，每年定期實施必要之教育訓練。
      
      第十二條(個人資料安全稽核機制)
      依本計畫所訂個人資料保護機制、程序及措施，應納入內部稽核及內部自行查核範圍。每年應由第四條所設之個人資料管理單位或適當組織執行內部稽核，提出評估報告，並採取下列改善措施：
      一、	修正個資保護政策及安全維護計畫。
      二、	評估報告中有不符合法令或有違法之虞者，應規劃並採取相關改善及預防措施。
      
      第十三條(留存紀錄)
      本公司執行依本計畫所訂個人資料保護機制、程序及措施，除其他法令另有規定外，應留存下列紀錄或證據：
      一、	個人資料提供或移轉第三人之紀錄，該紀錄應包括提供或移轉之對象、依據、原因、方法、時間及地點等資訊。
      二、	確認個人資料正確性及補充、更正之紀錄。
      三、	當事人行使個資法第三條之權利及處理過程之紀錄。
      四、	個人資料或儲存個人資料媒體之刪除、停止處理、利用或銷毀之原因、方法、時間及地點等紀錄。
      五、	存取個人資料系統之紀錄。
      六、	資料備份及確認其有效性之紀錄。
      七、	人員權限新增、變動及刪除之紀錄。
      八、	因應事故發生所採取行為之紀錄。
      九、	定期檢查處理個人資料之資訊系統之紀錄。
      十、	認知宣導及教育訓練之紀錄。
      十一、	稽核及改善安全維護計畫之紀錄。
      十二、	其他必要紀錄或證據。
      
      第十四條(其他)
      關於保護消費者個人資料之機制，應適時提醒消費者應用，並為適當之公告。
      對本公司所屬平台之使用者，應進行適當之個人資料保護及管理之認知宣導或教育訓練。
      本公司應對個人資料保護訂立相關守則(如隱私權政策等)，要求所屬平台使用者遵守。
      
      第十五條(業務終止後個人資料處理方法)
      本公司因業務之一部或全部終止、特定目的消失、契約或法令規定期限屆滿等，除法令另有規定外，應刪除、停止處理或利用相關之個人資料。如將相關之個人資料移轉第三人，於移轉前，應確認該第三人依法有權蒐集該個人資料。
      前項之移轉，應採取合法且適當之方式為之。
      個人資料在刪除、銷毀時，所保有之紙本個人資料應以碎紙、委外焚化、委外水銷等方式銷毀紙本，個人資料儲存於磁碟、磁帶、光碟片、微縮片、積體電路晶片等媒介物者，應以消磁、穿刺、剪斷、敲擊等破壞措施銷毀。
      個人資料在刪除、停止處理或利用時，應留存下列紀錄：
      一、	刪除、停止處理或利用之方法、時間。
      二、	將刪除、停止處理或利用之個人資料移轉其他對象者，其移轉之原因、對象、方法、時間，及該對象蒐集、處理或利用之合法依據。
      前項及第十三條之軌跡資料、相關證據及紀錄，至少留存五年。但法令另有規定或契約另有約定者，不在此限。      
      `,
  },
];

const TermsAndPrivacy = ({ show, setShow, type }) => {
  const manualType =
    type === modalTypeEnum.TermsOfService ? termsOfService : privacyPolicy;
  const onHideModal = () => setShow(false);
  return (
    <StyledModal show={show} onHide={onHideModal} centered scrollable={true}>
      <Modal.Header>
        <Modal.Title>
          {type === modalTypeEnum.TermsOfService ? '服務條款' : '隱私權政策'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {manualType.map(policy => (
          <ModalWrapper key={policy.title}>
            <Title>{policy.title}</Title>
            <Content>{policy.content}</Content>
          </ModalWrapper>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <StyledButton onClick={onHideModal}>確認</StyledButton>
      </Modal.Footer>
    </StyledModal>
  );
};

export default TermsAndPrivacy;
