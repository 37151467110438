import CarouselWoIndicator from 'components/Home/CarouselWoIndicator';
import rightArrow from 'assets/right-arrow.svg';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledSection = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 8rem 0 0 0;
  .bottom-grid-wrapper {
    display: none;
  }

  @media only screen and (min-width: 900px) {
    flex-direction: column;
    .carousel-topics {
      display: none;
    }
    .bottom-grid-wrapper {
      padding-top: 4.4rem;
      display: flex;
      flex-direction: column;
      .first-row {
        display: flex;
        flex-direction: row;
        width: 100%;
        .img-no1 {
          width: 100%;
          height: 53.3rem;
          background-size: cover;
          border-bottom: 2px solid var(--background-ff, #fff);
        }
      }
      .second-row {
        display: flex;
        flex-direction: row;
        height: 27.4rem;
        .img-no2 {
          width: 33.33%;
          object-fit: cover;
          border-right: 2px solid var(--background-ff, #fff);
        }
        .img-no3 {
          width: 33.34%;
          object-fit: cover;
          object-position: left;
          border-right: 2px solid var(--background-ff, #fff);
        }
        .img-no4 {
          width: 33.33%;
          object-fit: cover;
        }
      }
    }

    .text-wrapper {
      display: flex;
      background: none;
      flex-direction: column;
      gap: 3.2rem;
      padding: 6.4rem;
      .title-wrapper {
        background: none;
        .title {
          background: none;
          font-family: 'Albert Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #ffffff;
        }
        .subtitle {
          background: none;
          font-family: 'Albert Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          line-height: 32px;
          color: #ffffff;
        }
      }

      .button-more {
        background: none;
        width: 14.8rem;
        height: 3.9rem;
        border: 2px solid #ffffff;
        border-radius: 3rem;
        font-family: 'Albert Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
        text-decoration: none;
        padding-top: 0.8rem;
        :hover {
          background-color: #cd0715;
          color: #ffffff;
        }
      }
    }
  }
`;

const StyledH1 = styled.h1`
  display: block;
  margin: auto;
  width: 9.6rem;
  height: 3.2rem;

  font-family: 'Albert Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3.2rem;

  text-align: center;

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  @media only screen and (min-width: 900px) {
    display: inline-flex;
    margin-left: 12rem;
    width: fit-content;
    font-size: 32px;
    line-height: 32px;
  }
`;

const Button = styled(Link)`
  display: block;
  margin: auto;
  font-family: 'Albert Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2rem;
  color: #ffffff;
  border: none;
  text-align: center;
  text-decoration: none;
  :hover {
    color: #ffffff;
  }

  img {
    margin: auto auto 0.4rem 1.7rem;
  }

  @media only screen and (min-width: 900px) {
    position: absolute;
    right: 12rem;
    top: 0;
  }
`;

const HomeTopic = ({ bannerItem, topicItem }) => {
  return (
    <StyledSection>
      <div>
        <StyledH1>專題介紹</StyledH1>
        <div className="carousel-topics">
          <CarouselWoIndicator images={bannerItem.topics_images} />
        </div>
        <Button to="topics">
          View all
          <img src={rightArrow} alt="right-arrow"></img>
        </Button>
      </div>
      <div className="bottom-grid-wrapper">
        <div className="first-row">
          <div
            className="img-no1"
            style={{
              background: ` 
              url(${`${process.env.REACT_APP_DATA_URL}/uploads/home/${bannerItem.topics_images[0]}`}) lightgray 50% / cover no-repeat`,
            }}
          >
            <div className="text-wrapper">
              <div className="title-wrapper">
                <h2 className="title">{topicItem.brand}</h2>
                <h3 className="subtitle">{topicItem.car_title}</h3>
              </div>
              <Link to="topics" className="button-more">
                瞭解更多
              </Link>
            </div>
          </div>
        </div>
        <div className="second-row">
          <div
            className="img-no2"
            style={{
              background: `url(${`${process.env.REACT_APP_DATA_URL}/uploads/home/${bannerItem.topics_images[1]}`}) lightgray 50% / cover no-repeat`,
            }}
          />
          <div
            className="img-no3"
            style={{
              background: `url(${`${process.env.REACT_APP_DATA_URL}/uploads/home/${bannerItem.topics_images[2]}`}) lightgray 50% / cover no-repeat`,
            }}
          />
          <div
            className="img-no4"
            style={{
              background: `url(${`${process.env.REACT_APP_DATA_URL}/uploads/home/${bannerItem.topics_images[3]}`}) lightgray 50% / cover no-repeat`,
            }}
          />
        </div>
      </div>
    </StyledSection>
  );
};

export default HomeTopic;
