import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import arrowImg from 'assets/imgs/arrowDown.svg';
import {
  DataContextMemberAddress,
  AddressComponentRenderState,
} from 'pages/Member';
import { useForm, Controller } from 'react-hook-form';
import ErrorMessage from 'shared/components/ErrorMessage';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useTwZipCode,
  cities,
  districts,
  zipCodes,
} from 'shared/utils/ZipCode';
import { useHttpClient } from 'shared/hooks/http-hook';
import { AuthContext } from 'shared/context/auth-context';
import LoadingSpinner from 'shared/components/LoadingSpinner';

const CurrentAddressSection = styled.section`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #969696;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }

  .sub-form {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2.4rem;
  }

  .top-title-wrapper {
    display: flex;
    justify-content: center;
  }

  .form-element {
    width: 100%;
  }

  .country-city-element {
    width: 48%;
  }

  .address {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .element-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    padding: 0.4rem 0;
  }
  .element-input,
  .element-select {
    width: 100%;
    padding: 0 1.6rem;
    background: #010001;
    height: 4.8rem;
    border: 1px solid #969696;
    border-radius: 8px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: white;

    ::placeholder {
      color: #969696;
    }

    :focus {
      outline: none;
    }
  }

  .element-select {
    width: 100%;
    padding: 0 1.6rem;
    background: #010001;
    height: 4.8rem;
    border: 1px solid #969696;
    border-radius: 8px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: white;

    appearance: none;
    background-image: url(${arrowImg});
    background-repeat: no-repeat;
    background-position:
      right 1.6em top 50%,
      0 0;
    background-size:
      1.4em auto,
      100%;
  }

  .address {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
  }

  .zip-code {
    max-width: 9.8rem;
  }

  .btn-wrapper {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    justify-content: flex-end;
    padding-top: 5.6rem;
  }

  .btn-back,
  .btn-confirm {
    width: 15.6rem;
    height: 4.8rem;
    border: 2px solid #ffffff;
    border-radius: 8px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  .btn-confirm {
    background: #cd0715;
  }

  @media only screen and (min-width: 720px) {
    .top-title-wrapper {
      justify-content: flex-start;
    }

    .sub-form {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 2.4rem;
    }

    .form-element {
      max-width: 48%;
    }

    .address-form {
      max-width: 100%;
    }

    .zip-code {
      max-width: 12rem;
    }

    .btn-wrapper {
      padding-top: 4rem;
    }
  }
`;

const MAX_ADDRESS_COUNT = 10;

const CurrentAddress = ({
  showMobileEmail = true,
  buttonName = '儲存',
  addressToEdit,
  addressToEditIndex = -1, // -1: Add new address
}) => {
  const {
    setShowMemberAddress,
    addressItems,
    setAddressItems,
    locationItems,
    setLocationItems,
  } = useContext(DataContextMemberAddress);
  const backToMemberAddress = state => {
    setShowMemberAddress(state);
  };

  const auth = useContext(AuthContext);
  const { isLoading, sendRequest } = useHttpClient();

  const formatPhoneNumber = value => {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you format the area code to early

    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 4)}-${phoneNumber.slice(4)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `${phoneNumber.slice(0, 4)}-${phoneNumber.slice(
      4,
      7,
    )}-${phoneNumber.slice(7, 10)}`;
  };

  const {
    city: contactCity,
    district: contactDistrict,
    handleCityChange: handleContactCityChange,
    handleDistrictChange: handleContactDistrictChange,
  } = useTwZipCode();

  const handlePhoneNumberInput = e => {
    // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    // we'll set the input value using our setValue
    setValue('mobile', formattedPhoneNumber, {
      shouldValidate: true,
    });
  };

  const formSchema = yup.object().shape({
    name: yup.string().required('姓名為必填').max(32, '姓名最長為32字元'),
    company: yup.string().max(32, '公司名稱最長為32字元'),
    email: yup.string().required('Email為必填').email('請輸入正確Email'),
    mobile: yup
      .string()
      .required('手機號碼為必填')
      .matches(/^09\d{2}-\d{3}-\d{3}$/, '請輸入正確手機號碼'),
    city: yup.string().matches(/^((?!請選擇).)*$/, '縣市為必填'),
    district: yup.string().matches(/^((?!請選擇).)*$/, '鄉鎮(市)區為必填'),
    street: yup
      .string()
      .required('門牌號碼/街道名稱為必填')
      .max(100, '地址最長為100個字元'),
  });

  const {
    register,
    control,
    trigger,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({ resolver: yupResolver(formSchema) });

  useEffect(() => {
    if (addressToEdit) {
      for (let name in addressToEdit) {
        setValue(name, addressToEdit[name]);
      }
      handleContactCityChange(addressToEdit.city);
      handleContactDistrictChange(addressToEdit.district);
    }
    // TODO: refactor
    // Add dummy mobile/email for reservation location is selected
    // Otherwise will need to unregister/register again for these values
    if (!showMobileEmail) {
      setValue('email', 'dummy@dummy.com');
      setValue('mobile', '0900-000-000');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressToEdit]);

  const onSubmit = async data => {
    let res;
    try {
      if (buttonName === '新增') {
        res = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/addresses/new',
          'POST',
          JSON.stringify({
            name: data.name,
            company: data.company,
            email: data.email,
            mobile: data.mobile,
            zipcode: data.zipCode,
            city: data.city,
            district: data.district,
            street: data.street,
            type: showMobileEmail ? 0 : 1,
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          },
        );
      } else if (buttonName === '儲存') {
        res = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/addresses/update',
          'POST',
          JSON.stringify({
            name: data.name,
            company: data.company,
            email: data.email,
            mobile: data.mobile,
            zipcode: data.zipCode,
            city: data.city,
            district: data.district,
            street: data.street,
            id: data.id,
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          },
        );
      }
      if (showMobileEmail && !!res) {
        if (addressToEditIndex === -1) {
          if (addressItems.length < MAX_ADDRESS_COUNT) {
            addressItems.push({});
          }
          addressToEditIndex = addressItems.length - 1;
        }
        addressItems[addressToEditIndex] = data;
        setAddressItems(addressItems);
      } else {
        if (addressToEditIndex === -1) {
          if (locationItems.length < MAX_ADDRESS_COUNT) {
            locationItems.push({});
          }
          addressToEditIndex = locationItems.length - 1;
        }
        locationItems[addressToEditIndex] = data;
        locationItems[addressToEditIndex].email = '';
        locationItems[addressToEditIndex].mobile = '';
        setLocationItems(locationItems);
      }
    } catch (err) {}

    backToMemberAddress(AddressComponentRenderState.AllAddress);
  };

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}
      <CurrentAddressSection>
        <div className="top-title-wrapper">
          <h1 className="top-title-text">
            {showMobileEmail ? '聯絡地址' : '預約地點'}
          </h1>
        </div>
        <form className="sub-form" onSubmit={handleSubmit(onSubmit)}>
          <li className="form-element">
            <h3 className="element-title">姓名</h3>
            <input
              className="element-input"
              type="text"
              placeholder="請輸入"
              {...register('name')}
            />
            {errors.name && <ErrorMessage message={errors.name.message} />}
          </li>
          <li className="form-element">
            <h3 className="element-title">公司名稱 (選填)</h3>
            <input
              className="element-input"
              type="text"
              placeholder="請輸入"
              {...register('company')}
            />
            {errors.company && (
              <ErrorMessage message={errors.company.message} />
            )}
          </li>
          {showMobileEmail && (
            <React.Fragment>
              <li className="form-element">
                <h3 className="element-title">Email</h3>
                <input
                  className="element-input"
                  type="email"
                  autoComplete="on"
                  placeholder="請輸入"
                  {...register('email')}
                  aria-invalid={errors.email ? 'true' : 'false'}
                />
                {errors.email && (
                  <ErrorMessage message={errors.email.message} />
                )}
              </li>
              <li className="form-element">
                <h3 className="element-title">手機號碼</h3>
                <input
                  className="element-input"
                  placeholder="請輸入"
                  {...register('mobile')}
                  onChange={e => handlePhoneNumberInput(e)}
                />
                {errors.mobile && (
                  <ErrorMessage message={errors.mobile.message} />
                )}
              </li>
            </React.Fragment>
          )}
          <li className="form-element country-city-element">
            <h3 className="element-title">縣市</h3>
            <Controller
              defaultValue="請選擇"
              control={control}
              name="city"
              render={({ field }) => (
                <React.Fragment>
                  <select
                    className="element-select"
                    {...field}
                    onChange={e => {
                      handleContactCityChange(e.target.value);
                      setValue('district', districts[e.target.value][0]);
                      setValue(
                        'zipCode',
                        zipCodes[e.target.value][districts[e.target.value][0]],
                      );
                      trigger('district');
                      field.onChange(e);
                    }}
                    value={contactCity}
                  >
                    <option
                      className="element-disabled"
                      value="請選擇"
                      disabled
                    >
                      請選擇
                    </option>
                    {cities.map((city, i) => {
                      return (
                        <option className="element-option" key={i}>
                          {city}
                        </option>
                      );
                    })}
                  </select>
                  {errors.city && (
                    <ErrorMessage message={errors.city.message} />
                  )}
                </React.Fragment>
              )}
            />
          </li>

          <li className="form-element country-city-element">
            <h3 className="element-title">鄉鎮(市)區</h3>
            <Controller
              defaultValue="請選擇"
              control={control}
              name="district"
              render={({ field }) => (
                <React.Fragment>
                  <select
                    className="element-select"
                    {...field}
                    onChange={e => {
                      handleContactDistrictChange(e.target.value);
                      setValue(
                        'zipCode',
                        zipCodes[contactCity][e.target.value],
                      );
                      field.onChange(e);
                    }}
                    value={contactDistrict}
                  >
                    <option
                      className="element-disabled"
                      value="請選擇"
                      disabled
                    >
                      請選擇
                    </option>
                    {contactCity in districts &&
                      districts[contactCity].map((district, i) => {
                        return (
                          <option className="element-option" key={i}>
                            {district}
                          </option>
                        );
                      })}
                  </select>
                  {errors.district && (
                    <ErrorMessage message={errors.district.message} />
                  )}
                </React.Fragment>
              )}
            />
          </li>
          <li className="form-element address-form">
            <h3 className="element-title">地址</h3>
            <div className="address">
              <input
                className="element-input zip-code"
                type="tel"
                placeholder="郵遞區號"
                {...register('zipCode')}
                readOnly
              ></input>
              <input
                className="element-input street"
                placeholder="門牌號碼/街道名稱"
                type="text"
                {...register('street')}
              />
              {errors.street && (
                <ErrorMessage message={errors.street.message} />
              )}
            </div>
          </li>
        </form>
        <div className="btn-wrapper">
          <button
            onClick={() =>
              backToMemberAddress(AddressComponentRenderState.AllAddress)
            }
            className="btn-back"
          >
            返回
          </button>
          <button onClick={handleSubmit(onSubmit)} className="btn-confirm">
            {buttonName}
          </button>
        </div>
      </CurrentAddressSection>
    </React.Fragment>
  );
};

export default CurrentAddress;
