import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from 'react-router-dom';
import { useHttpClient } from 'shared/hooks/http-hook';
import LoadingSpinner from 'shared/components/LoadingSpinner';

const StyledSection = styled.section`
  .header {
    margin: auto;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
  }

  .nav-tabs {
    display: flex;
    border: none;
    max-width: 40rem;
    justify-content: center;
  }

  .nav-link,
  .nav-link.active {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;

    /* Text/ff */
    color: #ffffff;
    background: none;
    border: none;
    margin: auto;
    padding: 1.4rem 2rem;
  }

  .nav-link.active {
    font-weight: 700;
    border-bottom: 0.1rem solid red;
  }

  ul,
  li {
    margin: auto;
    padding: 0;
  }

  .section {
    display: flex;
    margin: 0 1.6rem;
    flex-direction: column;
    align-items: center;
  }

  .bottom-line {
    border-bottom: 1px solid #969696;
  }

  .img-container {
    margin: 3.2rem 0;
    display: flex;
  }

  .img {
    width: 80%;
    margin: auto;
    object-fit: contain;
  }

  .text {
    display: flex;
    flex-direction: column;
    max-width: 34.3rem;
  }

  .title {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
    margin: auto;
    padding-bottom: 1.6rem;
  }

  .subtitle {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    margin: auto;
    padding-bottom: 3.2rem;
  }

  .descriptions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1.6rem;
  }

  .description {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .spec {
    display: flex;
    flex-direction: row;
  }

  .speed {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 20px;
    display: inline-flex;
    color: #ffffff;
  }

  .speed-unit {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    display: inline-flex;
    color: #ffffff;
  }

  .mileage {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 20px;
    /* identical to box height, or 83% */

    color: #ffffff;
  }

  .mileage-unit {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    color: #ffffff;
  }

  .drive {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */

    color: #ffffff;
  }

  .detail {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */

    color: #ffffff;
  }

  .button {
    margin: 3.2rem auto 5.6rem auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;

    width: 148px;
    height: 39px;

    /* Background/ff */

    border: 2px solid #ffffff;
    border-radius: 30px;

    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    text-decoration: none;

    :hover {
      background-color: #cd0715;
    }
  }

  @media only screen and (min-width: 1200px) {
    .header {
      font-weight: 700;
      font-size: 32px;
      line-height: 32px;
      padding-top: 4rem;
      padding-bottom: 1.6rem;
    }
    .nav-link,
    .nav-link.active {
      font-size: 20px;
      line-height: 24px;
    }

    .section {
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .row-reverse {
      flex-direction: row-reverse;
    }

    .img-container {
      width: 60rem;
      height: 60rem;
      margin: 0 2rem;
    }

    .img {
      width: 100%;
    }

    .title,
    .subtitle,
    .text {
      margin: 0;
      text-align: left;
    }

    .subtitle {
      font-size: 56px;
      line-height: 72px;
    }

    .text {
      max-width: 62.4rem;
      justify-content: flex-start;
      margin: 0 2rem;
    }

    .descriptions {
      max-width: 54rem;
    }

    .bottom-line {
      border-bottom: none;
    }

    .speed {
      font-size: 40px;
      line-height: 32px;
    }

    .speed-unit {
      font-size: 20px;
      line-height: 24px;
    }

    .mileage {
      font-size: 40px;
      line-height: 32px;
    }

    .mileage-unit {
      font-size: 20px;
      line-height: 24px;
    }

    .drive {
      font-size: 32px;
      line-height: 32px;
    }

    .detail {
      font-size: 16px;
    }

    .button {
      margin-left: 0;
    }
  }
`;

const Page = ({ cars }) => {
  return (
    <React.Fragment>
      {cars &&
        cars.map((item, index) => (
          <section
            className={`section ${
              index < cars.length - 1 ? 'bottom-line' : ''
            } ${index % 2 ? 'row-reverse' : ''}`}
            key={index}
          >
            <div className="img-container">
              <img
                className="img"
                src={`${process.env.REACT_APP_DATA_URL}/inventory/${item.image}`}
                alt="car"
              />
            </div>
            <div className="text">
              <h1 className="title">{item.brand}</h1>
              <h2 className="subtitle">{item.title}</h2>
              <div className="descriptions">
                <ul className="description">
                  <li className="spec">
                    <span className="speed">{item.speed}</span>
                    <span className="speed-unit">&nbsp;秒</span>
                  </li>
                  <li className="detail">0-100 公里/小時</li>
                </ul>
                <ul className="description">
                  <li className="spec">
                    <span className="mileage">{item.mileage}</span>
                    <span className="mileage-unit">&nbsp;公里</span>
                  </li>
                  <li className="detail">里程數</li>
                </ul>
                <ul className="description">
                  <li className="spec">
                    <span className="drive">{item.drive}</span>
                  </li>
                  <li className="detail">驅動系統</li>
                </ul>
              </div>
              <Link to={`/inventory/${item.id}`} className="button">
                更多詳情
              </Link>
            </div>
          </section>
        ))}
    </React.Fragment>
  );
};

const Inventory = () => {
  const { isLoading, sendRequest } = useHttpClient();
  const [inventoryItems, setInventoryItems] = useState([]);

  useEffect(() => {
    // console.log("Fetch inventory items");
    const fetchInventoryItems = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/inventory',
          'GET',
        );
        setInventoryItems(responseData);
      } catch (err) {}
    };
    fetchInventoryItems();
  }, [sendRequest]);

  // Get the unique brand names from the inventory items
  const brands = [
    ...new Map(inventoryItems.map(item => [item.brand, item])).keys(),
  ];

  // Create items arrays for each brand
  const items = brands.map(brand => ({
    key: brand,
    title: brand,
    // Filter the inventory items by brand name
    cars: inventoryItems.filter(item => item.brand === brand),
  }));

  const total = {
    key: 'total',
    title: '全部',
    cars: inventoryItems,
  };

  // Add "total" to the beginning of the array
  items.unshift(total);

  return (
    <StyledSection>
      {isLoading && <LoadingSpinner asOverlay />}
      <h1 className="header">在庫車款</h1>
      <Tabs defaultActiveKey={items[0].key}>
        {items.map((item, index) => (
          <Tab key={item.key} eventKey={item.key} title={item.title}>
            <Page key={index} cars={item.cars} />
          </Tab>
        ))}
      </Tabs>
    </StyledSection>
  );
};

export default Inventory;
