import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledSection = styled.ul`
  display: flex;
  padding: 0px;
  flex-wrap: wrap;
  margin: auto;
  width: 100%;
  justify-content: center;
`;

const Container = styled.li`
  position: relative;
  height: 406px;
  width: 100%;
  max-width: 72rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    padding: 23.755rem 2.545rem 3.2rem 2.545rem;
    gap: 16px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 37.5%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }

  .brand {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    background: transparent;
    color: #ffffff;
  }

  .car {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    color: #ffffff;
    background: transparent;
  }

  .button {
    border: 2px solid rgba(255, 255, 255, 1);
    background: none;

    box-sizing: border-box;
    min-width: 14.8rem;
    min-height: 3.9rem;
    border: 2px solid #ffffff;
    border-radius: 30px;
    text-decoration: none;

    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: white;
    padding-top: 0.8rem;

    :hover {
      background-color: #cd0715;
    }
  }

  ul,
  li {
    background: transparent;
  }

  @media only screen and (min-width: 49.5rem) {
    .wrapper {
      padding-top: 31.1rem;
    }
  }

  @media only screen and (min-width: 1440rem) {
    .wrapper {
      padding-top: 31.1rem;
    }
  }
`;

const TopicMore = ({ items }) => {
  return (
    <>
      <StyledSection>
        {items.map((item, index) => (
          <Container
            key={index}
            style={{
              backgroundImage: `url(${`${process.env.REACT_APP_DATA_URL}/uploads/topic/${item.carousel_image}`})`,
            }}
          >
            <ul className="wrapper">
              <li>
                <h1 className="brand">{item.brand}</h1>
                <h2 className="car">{item.car_title}</h2>
              </li>
              <Link to={`/topics/${item.id}`} className="button">
                瞭解更多
              </Link>
            </ul>
          </Container>
        ))}
      </StyledSection>
    </>
  );
};

export default TopicMore;
