import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledSection = styled.section`
  height: 100%;
  min-width: 30rem;
  .title-wrapper {
    display: flex;
    height: 45rem;
    flex-direction: column;
    padding: 5rem 3rem;
    background: none;

    &::after {
      content: '';
      position: absolute;
      width: 28.8%;
      height: 32rem;
      top: 0%;
      right: 0%;
      background: linear-gradient(
        200.96deg,
        #cd0715 24.12%,
        rgba(205, 7, 21, 0) 86.15%
      );
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      min-width: 37rem;
      height: 24.8rem;
      top: 5.9rem;
      right: 0%;
      background: url(${props => props.carImage}) transparent no-repeat bottom -5rem
        right -7rem / contain;
      z-index: 1;
    }

    .title {
      width: 55%;
      padding-bottom: 0.8rem;
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
    }

    .slogan {
      width: fit-content;
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      line-height: 60px;
      background: linear-gradient(
        90deg,
        #ffffff 50%,
        rgba(255, 255, 255, 0) 107.42%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    .slogan-sentence {
      padding-top: 16.8rem;
      margin-top: auto;
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      display: flex;
      color: #ffffff;
      justify-self: flex-end;
      align-self: center;
    }

    .large-screen {
      display: none;
    }
  }

  .subtitle-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 23.6rem;
    .descriptions {
      display: flex;
      flex-direction: row;
      margin: 1.6rem auto 3.2rem auto;
      .description {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.8rem;
        .spec {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          gap: 0.8rem;
          .speed,
          .mileage {
            font-family: 'Albert Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 20px;
            color: #ffffff;
          }
          .speed-unit,
          .mileage-unit {
            font-family: 'Albert Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #ffffff;
          }

          .drive {
            font-family: 'Albert Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: #ffffff;
          }
        }
        .detail {
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: #ffffff;
        }
      }
    }
    .btn-reservation {
      width: 14.8rem;
      height: 4.4rem;
      padding: 1rem 2.4rem;
      border: 2px solid #ffffff;
      border-radius: 30px;

      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      text-decoration: none;
      cursor: pointer;
      :hover {
        background-color: #cd0715;
        color: #ffffff;
      }
    }
  }

  @media only screen and (min-width: 600px) {
    .title-wrapper {
      height: 55rem;

      &::after {
        height: 42rem;
      }

      &::before {
        content: '';
        height: 34.8rem;
        top: 5.9rem;
      }
      .slogan {
        height: 19.2rem;
      }
      .slogan-sentence {
        padding-top: 26.8rem;
      }
    }
  }

  @media only screen and (min-width: 700px) {
    .title-wrapper {
      height: 65rem;

      .background-shadow {
        height: 52rem;
      }

      &::before {
        content: '';
        height: 44.8rem;
        top: 5.9rem;
      }

      .slogan {
        font-weight: 700;
        font-size: 80px;
        line-height: 96px;
      }

      .slogan-sentence {
        padding-top: 36.8rem;
      }
    }
  }

  @media only screen and (min-width: 900px) {
    .title-wrapper {
      width: 100%;
      height: 73rem;
      padding-top: 8rem;
      padding-left: 8rem;
      padding-right: 0;

      &::after {
        height: 52rem;
      }

      &::before {
        content: '';
        height: 71.8rem;
        top: -4rem;
        left: 0rem;
        overflow: hidden;
        background: url(${props => props.carImage}) transparent no-repeat bottom -5rem
          right -30rem / contain;
      }
      .title {
        font-family: 'Albert Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
      }

      .slogan {
        font-family: 'Albert Sans';
        font-style: normal;
        width: 80rem;
        font-weight: 700;
        font-size: 160px;
        line-height: 192px;
        background: linear-gradient(
          90deg,
          #ffffff 50%,
          rgba(255, 255, 255, 0) 107.42%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        overflow: hidden;
      }

      .slogan-sentence {
        padding-top: 0;
        margin-top: 1.6rem;
        align-self: flex-start;
        text-align: left;
        font-family: 'Albert Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        z-index: 2;
        background-color: transparent;
      }

      .small-screen {
        display: none;
      }

      .large-screen {
        display: flex;
      }
    }

    .subtitle-wrapper {
      width: fit-content;
      display: flex;
      flex-direction: row;
      gap: 4.85rem;
      align-items: center;
      position: absolute;
      top: 50rem;
      padding-left: 6.4rem;
      z-index: 2;
      background-color: transparent;

      .descriptions {
        margin: 0;
        .description {
          .spec {
            align-items: flex-end;
            .speed,
            .mileage {
              font-size: 40px;
              line-height: 32px;
            }
            .speed-unit,
            .mileage-unit {
              font-size: 20px;
              line-height: 24px;
            }

            .drive {
              font-size: 32px;
              line-height: 32px;
            }
          }
          .detail {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 1440px) {
    .title-wrapper {
      &::after {
        margin: auto;
        right: 0;
        left: 100rem;
        width: 42.5rem;
        display: table;
      }

      &::before {
        content: '';
        margin: auto;
        width: 142.5rem;
        top: -4rem;
        left: 0rem;
        overflow: hidden;
        background: url(${props => props.carImage}) transparent no-repeat bottom -5rem
          right -30rem / contain;
      }
      .slogan-sentence {
      }
    }
  }
`;

const HomeBanner = ({ item }) => {
  // TODO: optimization
  const sloganSentences = [
    item.slogan_sentence_1 + '，',
    item.slogan_sentence_2,
    item.slogan_sentence_3 + '，',
    item.slogan_sentence_4,
  ];

  // TODO: optimization
  const sloganSentencesLargeScreen = [
    item.slogan_sentence_1 + '，' + item.slogan_sentence_2,
    item.slogan_sentence_3 + '，' + item.slogan_sentence_4,
  ];

  return (
    <StyledSection
      carImage={`${process.env.REACT_APP_DATA_URL}/uploads/home/${item.banner_image}`}
    >
      <div className="title-wrapper">
        <h1 className="title">{item.car_title}</h1>
        <h1 className="slogan">{item.slogan}</h1>
        <p className="slogan-sentence small-screen">
          {sloganSentences.map((sloganSentence, index) => (
            <React.Fragment key={index}>
              {sloganSentence}
              <br />
            </React.Fragment>
          ))}
        </p>
        <p className="slogan-sentence large-screen">
          {sloganSentencesLargeScreen.map((sloganSentence, index) => (
            <React.Fragment key={index}>
              {sloganSentence}
              <br />
            </React.Fragment>
          ))}
        </p>
      </div>
      <div className="subtitle-wrapper">
        <div className="descriptions">
          <ul className="description">
            <li className="spec">
              <span className="speed">{item.banner_speed}</span>
              <span className="speed-unit">秒</span>
            </li>
            <li className="detail">0-100 公里/小時</li>
          </ul>
          <ul className="description">
            <li className="spec">
              <span className="mileage">{item.banner_mileage}</span>
              <span className="mileage-unit">公里</span>
            </li>
            <li className="detail">里程數</li>
          </ul>
          <ul className="description">
            <li className="spec">
              <span className="drive">{item.banner_drive}</span>
            </li>
            <li className="detail">驅動系統</li>
          </ul>
        </div>
        <Link to="reservation" className="btn-reservation">
          立即預約
        </Link>
      </div>
    </StyledSection>
  );
};

export default HomeBanner;
