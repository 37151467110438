import React from 'react';
import TopicHeader from 'components/Topic/TopicHeader';
import TopicVideo from 'components/Topic/TopicVideo';
import TopicCardLeft from 'components/Topic/TopicCardLeft';
import TopicCardRight from 'components/Topic/TopicCardRight';
import TopicCardCenter from 'components/Topic/TopicCardCenter';
import CarouselTopic from 'components/Topic/CarouselTopic';
import TopicMore from 'components/Topic/TopicMore';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useHttpClient } from 'shared/hooks/http-hook';
import LoadingSpinner from 'shared/components/LoadingSpinner';

const StyledSection = styled.section`
  .container-wrapper {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
`;

const Topic = () => {
  const topicId = useParams().topicId;
  const [index, setIndex] = useState(0);
  const [topicItems, setTopicItems] = useState([]);
  const { isLoading, sendRequest } = useHttpClient();
  useEffect(() => {
    const fetchTopicItems = async () => {
      try {
        const items = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/topic',
          'GET',
        );

        // Put the item with id === topicId to the first
        const index = items.findIndex(
          item => parseInt(item.id) === parseInt(topicId),
        );
        setIndex(index === -1 ? 0 : index);
        setTopicItems(items);
      } catch (err) {}
    };
    fetchTopicItems();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const index = topicItems.findIndex(
      item => parseInt(item.id) === parseInt(topicId),
    );
    setIndex(index === -1 ? 0 : index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicId]);

  const itemsCount = topicItems.length;

  return (
    <StyledSection>
      {isLoading && <LoadingSpinner asOverlay />}
      {!isLoading && topicItems.length > 0 && (
        <React.Fragment>
          <TopicHeader item={topicItems[index]} />
          <TopicVideo item={topicItems[index]} />
          <TopicCardRight item={topicItems[index]} />
          <TopicCardLeft item={topicItems[index]} />
          <CarouselTopic item={topicItems[index]} />
          <TopicCardCenter item={topicItems[index]} />
          <TopicMore
            items={[
              topicItems[(index + itemsCount - 1) % itemsCount],
              topicItems[(index + 1) % itemsCount],
            ]}
          />
        </React.Fragment>
      )}
    </StyledSection>
  );
};

export default Topic;
