import React from 'react';
import styled from 'styled-components';
import AboutUsBanner from 'components/AboutUs/AboutUsBanner';
import AboutUsTwoPicItem from 'components/AboutUs/AboutUsTwoPicItem';
import AboutUsOnePicItem from 'components/AboutUs/AboutUsOnePicItem';
import { useState, useEffect } from 'react';
import { useHttpClient } from 'shared/hooks/http-hook';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import {
  ABOUT_US_IMG,
  ABOUT_US_IMG_1,
  ABOUT_US_IMG_2,
  ABOUT_US_IMG_3,
  ABOUT_US_IMG_4,
  ABOUT_US_IMG_5,
  ABOUT_US_IMG_6,
} from 'shared/constants/assets';

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AboutUs = () => {
  const [aboutUs, setAboutUs] = useState({});
  const { isLoading, sendRequest } = useHttpClient();
  useEffect(() => {
    const fetchAboutUs = async () => {
      try {
        const res = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/aboutUs/content',
          'GET',
        );
        setAboutUs(res.aboutUs);
      } catch (err) {}
    };
    fetchAboutUs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledSection>
      {isLoading && <LoadingSpinner asOverlay />}
      {!isLoading && Object.keys(aboutUs).length !== 0 && (
        <React.Fragment>
          <AboutUsBanner
            bannerPhoto={
              aboutUs?.banner_img
                ? `${process.env.REACT_APP_DATA_URL}/uploads/aboutUs/${aboutUs?.banner_img}`
                : ABOUT_US_IMG
            }
            title={aboutUs?.banner_title ?? 'About us'}
            subTitle={`車咖的起源`}
            paragraph={`想當年大家第一次接觸手排車的記憶，應該都是從考駕照時那句"男人就是要開手排啊！"開始的吧？每次放離合器都怕那突然到震一下熄火，一檔換二檔都笨拙的震一下，上坡時，心裡都有點挫，怕會不會熄火滑下去。在交通尖峰時刻，車水馬龍的十字路口熄火，發動又再熄火，真的是度秒如年。開完後不知不覺發現左腳怎麼那麼痠，這些回憶仿佛都還歷歷在目，鼻子仿佛都還聞得到離合器片燒磨的焦味。但也是這每個開手排車人必經的過程，讓我們瞭解到原來隨心所欲的決定何時進檔，何時降檔的感覺是如此美妙，完美地跟指搭上補油的聲浪，感覺真的非常爽！`}
            
            paragraph2={`手排車同時也教會我有關操控的一切，必須在對的時間做對的事，不然手排車會給你最直接的"反饋"！沒有商量的空間！這也讓我思考該如何不斷精進我的駕駛技術，好配得上這些猛獸，畢竟赤兔馬也得呂布才配。於是我滿腔熱血地參加了賽車學校的四階段訓練課程，也去匈牙利進行甩尾訓練。還記得第一次在下賽道就遇下傾盆大雨，震驚了一下換上雨胎，確認完跑道路綫就上了，不意外的有驚無險飛出彎道好幾次。同時也讓我深刻理解到跑賽道跟跑公路的差異，公路是設計來讓你舒服開車的，賽道天生是用來挑戰你的。只有這樣對極限的追求才能激發出每台車跟駕駛的契合，把車推到瀕臨臨界點，在最后一刻才踩下刹車過彎並出彎的痛快，對"快狠準"的極致追求，達成心中的perfect lap!`}
    
            paragraph3={`踏入甩尾的世界時，對於駕駛的認知又再次徹底被顛覆，並瞭解爲何飄移是如此的迷人，腳微妙的挑動控制油門，車子隨著你的目光隨心所欲的向前滑行，燒胎後的烟霧彌漫仿佛真的進入另一個世界。前置後驅不僅代表著駕駛的美學，更是我心中完美機械工程的象徵。在賽道上我有幸曾駕駛了經典的Carrera GT，並體會到什麼叫做——技術不到家的話，不是你在玩車，是車在玩你！回過頭看，真的很幸運有經歷到那個處處是經典，極致造車工藝世代的終章。渾厚的自然進氣引擎聲浪，手排換擋仍快過電腦的駕駛藝術，F1賽道技術移植的高性能大缸數大排氣量引擎，懷念兒時墻上一張張經典海報的同時，也感嘆這是一個時代的結束，千禧年代之後這些已成絕響。`}
    
            paragraph4={`"車咖"品牌在這樣的背景下誕生。一個為熱血，為同樣瘋狂愛車的您而生的平臺。單純希望能再有那麽一個地方讓我們一起重拾愛車的感動，純粹的駕駛快感，一同分享這份熱血。車咖團隊將對汽車的熱愛轉化成專業，竭誠為您提供服務。我們對於手排車、特殊車款，以及經典跑車都抱持高度的熱情，並深感這類車款在未來將愈加稀有，並了解到找車時可能面臨的困難。車咖秉持著對車況透明並據實告知的理念，以及完善的售後服務來確保您的每次駕駛都是一場美好的體驗。希望同樣熱血的您，都能夠無後顧之憂的享受駕駛的樂趣。`}
    
            paragraph5={`車咖創辦人 胡瀚仁（Henry Hu)`}

          />
          <AboutUsTwoPicItem
            alignRight={false}
            carPhoto1={
              aboutUs?.section_1_left_img
                ? `${process.env.REACT_APP_DATA_URL}/uploads/aboutUs/${aboutUs?.section_1_left_img}`
                : ABOUT_US_IMG_1
            }
            carPhoto2={
              aboutUs?.section_1_right_img
                ? `${process.env.REACT_APP_DATA_URL}/uploads/aboutUs/${aboutUs?.section_1_right_img}`
                : ABOUT_US_IMG_2
            }
            subTitle={aboutUs?.section_1_subtitle ?? 'About'}
            description={
              aboutUs?.section_1_description ??
              '911 Carrera 的優雅的輪廓以其標誌性「保時捷飛躍線（Porsche Flyline）」使得 911 Carrera 外型顯而易見，自 1963 年以來就塑造了保時捷品牌的 DNA，皆是具有驚人性能的跑車。'
            }
          />
          <AboutUsOnePicItem
            alignRight={true}
            carPhoto={
              aboutUs?.section_2_img
                ? `${process.env.REACT_APP_DATA_URL}/uploads/aboutUs/${aboutUs?.section_2_img}`
                : ABOUT_US_IMG_3
            }
            subTitle={aboutUs?.section_2_subtitle ?? 'Our team'}
            description={
              aboutUs?.section_2_description ??
              '911 Carrera 的優雅的輪廓以其標誌性「保時捷飛躍線（Porsche Flyline）」使得 911 Carrera 外型顯而易見，自 1963 年以來就塑造了保時捷品牌的 DNA，皆是具有驚人性能的跑車。'
            }
          />
          <AboutUsOnePicItem
            alignRight={false}
            carPhoto={
              aboutUs?.section_3_img
                ? `${process.env.REACT_APP_DATA_URL}/uploads/aboutUs/${aboutUs?.section_3_img}`
                : ABOUT_US_IMG_4
            }
            subTitle={aboutUs?.section_3_subtitle ?? 'Our team'}
            description={
              aboutUs?.section_3_description ??
              '911 Carrera 的優雅的輪廓以其標誌性「保時捷飛躍線（Porsche Flyline）」使得 911 Carrera 外型顯而易見，自 1963 年以來就塑造了保時捷品牌的 DNA，皆是具有驚人性能的跑車。'
            }
          />
          <AboutUsTwoPicItem
            alignRight={true}
            carPhoto1={
              aboutUs?.section_4_left_img
                ? `${process.env.REACT_APP_DATA_URL}/uploads/aboutUs/${aboutUs?.section_4_left_img}`
                : ABOUT_US_IMG_5
            }
            carPhoto2={
              aboutUs?.section_4_right_img
                ? `${process.env.REACT_APP_DATA_URL}/uploads/aboutUs/${aboutUs?.section_4_right_img}`
                : ABOUT_US_IMG_6
            }
            subTitle={aboutUs?.section_4_subtitle ?? 'Work'}
            description={
              aboutUs?.section_4_description ??
              '911 Carrera 的優雅的輪廓以其標誌性「保時捷飛躍線（Porsche Flyline）」使得 911 Carrera 外型顯而易見，自 1963 年以來就塑造了保時捷品牌的 DNA，皆是具有驚人性能的跑車。'
            }
          />
        </React.Fragment>
      )}
    </StyledSection>
  );
};

export default AboutUs;
