import React from 'react';
import styled from 'styled-components';
import AboutUsBanner from 'components/AboutUs/AboutUsBanner';
import AboutUsTwoPicItem from 'components/AboutUs/AboutUsTwoPicItem';
import CarouselDual from 'components/Offers/CarouselDual';
import { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from 'shared/hooks/http-hook';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import {
  ABOUT_US_IMG,
  ABOUT_US_IMG_1,
  ABOUT_US_IMG_2,
  ABOUT_US_IMG_5,
  ABOUT_US_IMG_6,
} from 'shared/constants/assets';

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Offers = () => {
  const [offers, setOffers] = useState({});
  const [carouselImgs, setCarouselImgs] = useState([]);
  const [carouselTexts, setCarouselTexts] = useState([]);
  const { isLoading, sendRequest } = useHttpClient();

  useEffect(() => {
    const fetchAboutUs = async () => {
      try {
        const res = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/offers/content',
          'GET',
        );
        setOffers(res.offers);
      } catch (err) {}
    };
    fetchAboutUs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapCharacteristicsData = useCallback(() => {
    offers.characteristics_images.forEach(fileName => {
      setCarouselImgs(prev => [
        ...prev,
        `${process.env.REACT_APP_DATA_URL}/uploads/offers/${fileName}`,
      ]);
    });

    offers.characteristics_titles.forEach((title, index) => {
      setCarouselTexts(prev => [
        ...prev,
        {
          title: title,
          text: offers.characteristics_descriptions[index],
        },
      ]);
    });
  }, [
    offers?.characteristics_descriptions,
    offers?.characteristics_images,
    offers?.characteristics_titles,
  ]);

  useEffect(() => {
    if (
      offers?.characteristics_images?.length &&
      offers?.characteristics_titles?.length &&
      offers?.characteristics_descriptions?.length
    ) {
      mapCharacteristicsData();
    }
  }, [offers, mapCharacteristicsData]);

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}
      {!isLoading && Object.keys(offers).length !== 0 && (
        <React.Fragment>
          <StyledSection>
            <AboutUsBanner
              bannerPhoto={
                offers?.banner_img
                  ? `${process.env.REACT_APP_DATA_URL}/uploads/offers/${offers?.banner_img}`
                  : ABOUT_US_IMG
              }
              title={offers?.banner_title ?? '服務項目'}
              subTitle={
                offers?.banner_subtitle ??
                `De Tomaso Partners Up With Capricorn Group To Build The Upcoming P72
            Supercar At The Nürburgring`
              }
              paragraph={
                offers?.banner_paragraph ??
                `The history of De Tomaso is steeped in international collaborations.
            Take the perennially popular Pantera for example, which was the
            result of an American designer drawing sports cars in Turin (Tom
            Tjaarda at Ghia) to be powered by V8s from Ohio (the Ford Cleveland
            engines), all under the direction of Alejandro de Tomaso, who came
            to Europe from Argentina to build sports and racing cars in the late
            1950s. Today, the company’s tradition of global partnerships is
            being carried forward with a brand new strategic partnership, this
            time between the revived De Tomaso marque and longtime German
            motorsport chassis and component developer, capricorn Group
            (responsible in part for the Porsche 919 LMP1 triple Le Mans-winner,
            for starters).`
              }
            />
            <AboutUsTwoPicItem
              alignRight={false}
              carPhoto1={
                offers?.section_1_left_img
                  ? `${process.env.REACT_APP_DATA_URL}/uploads/offers/${offers?.section_1_left_img}`
                  : ABOUT_US_IMG_1
              }
              carPhoto2={
                offers?.section_1_left_img
                  ? `${process.env.REACT_APP_DATA_URL}/uploads/offers/${offers?.section_1_right_img}`
                  : ABOUT_US_IMG_2
              }
              subTitle={offers?.section_1_subtitle ?? '預約賞車'}
              description={
                offers?.section_1_description ??
                '911 Carrera 的優雅的輪廓以其標誌性「保時捷飛躍線（Porsche Flyline）」使得 911 Carrera 外型顯而易見，自 1963 年以來就塑造了保時捷品牌的 DNA，皆是具有驚人性能的跑車。'
              }
            />
            <AboutUsTwoPicItem
              alignRight={true}
              carPhoto1={
                offers?.section_2_left_img
                  ? `${process.env.REACT_APP_DATA_URL}/uploads/offers/${offers?.section_2_left_img}`
                  : ABOUT_US_IMG_5
              }
              carPhoto2={
                offers?.section_2_right_img
                  ? `${process.env.REACT_APP_DATA_URL}/uploads/offers/${offers?.section_2_right_img}`
                  : ABOUT_US_IMG_6
              }
              subTitle={offers?.section_2_subtitle ?? '委託銷售'}
              description={
                offers?.section_2_description ??
                '911 Carrera 的優雅的輪廓以其標誌性「保時捷飛躍線（Porsche Flyline）」使得 911 Carrera 外型顯而易見，自 1963 年以來就塑造了保時捷品牌的 DNA，皆是具有驚人性能的跑車。'
              }
            />
          </StyledSection>
          <CarouselDual
            header="專屬服務"
            cars={carouselImgs}
            characters={carouselTexts}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Offers;
