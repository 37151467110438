import styled from 'styled-components';

const StyledSection = styled.section`
  position: relative;
  width: 100%;
  .section-main {
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .image-large {
    position: relative;
    width: 100%;
    height: 40rem;
    object-fit: cover;
    overflow: hidden;
  }

  .image-overlay {
    position: absolute;
    width: 100%;
    height: 40rem;
    background: linear-gradient(270deg, #000000 0%, rgba(0, 0, 0, 0) 50%);
  }

  .texts-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: none;

    .sub-title-decorator {
      position: absolute;
      width: 5rem;
      height: 4rem;
      top: 2.8rem;
      background: linear-gradient(
        100.46deg,
        #cd0715 0%,
        rgba(205, 7, 21, 0) 100%
      );
    }

    .sub-title-second {
      width: 100%;
      padding: 3.167rem 3.2rem 0.8rem 3.2rem;
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 32px;
      text-transform: uppercase;
      color: #ffffff;
      background: transparent;
    }
    .sub-text-second {
      width: 100%;
      padding: 0 3.2rem;
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: justify;
      color: #ffffff;
      background: transparent;
    }
  }

  @media only screen and (min-width: 1100px) {
    .section-main {
      flex-direction: row;
      justify-content: flex-start;
      margin: auto;
      gap: 0rem;
    }

    .image-large {
      position: relative;
      width: 56%;
      height: 48rem;
      object-fit: cover;
    }

    .image-overlay {
      position: absolute;
      width: 56%;
      height: 48rem;
    }

    .texts-wrapper {
      position: absolute;
      max-width: 51.2rem;
      left: 50%;
      .sub-title-decorator {
        display: none;
      }

      .sub-title-second {
        max-width: 51.2rem;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 3.2rem;
        padding-top: 17.8rem;
      }

      .sub-text-second {
        max-width: 51.2rem;
        padding: 0;
      }
    }

    .align-right {
      position: relative;
      flex-direction: flex-end;
      width: 100%;
      height: 48rem;

      .section-main {
        position: relative;
      }

      .image-large {
        position: absolute;
        left: 43%;
      }

      .image-overlay {
        position: absolute;
        left: 43%;
        background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      }
      .texts-wrapper {
        position: static;
        margin-left: auto;
        margin-right: 50%;
        text-align: right;
        .sub-title-second {
          text-align: right;
          margin-left: auto;
        }
        .sub-text-second {
          text-align: right;
          margin-left: auto;
        }
        z-index: 1;
      }
    }
  }
`;

const AboutUsOnePicItem = props => {
  return (
    <StyledSection>
      <section
        className={`section-main ${props.alignRight ? 'align-right' : ''}`}
      >
        <img className="image-large" src={props.carPhoto} alt="" />
        <div className="image-overlay"></div>
        <div className="texts-wrapper">
          <div className="sub-title-decorator"></div>
          <h2 className="sub-title-second">{props.subTitle}</h2>
          <p className="sub-text-second">{props.description}</p>
        </div>
      </section>
    </StyledSection>
  );
};

export default AboutUsOnePicItem;
