import React, { useState, useEffect } from 'react';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import ReactPaginate from 'react-paginate';
import { useHttpClient } from 'shared/hooks/http-hook';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import collapseBefore from "assets/imgs/collapseBefore.svg";
// import Accordion from "react-bootstrap/Accordion";

// const StyledAccordion = styled(Accordion)`
//   .accordion-item {
//     border: none;
//   }
//   .accordion-body {
//     background: #1c1c1c;
//     border-radius: 0px;
//     > ul {
//       display: flex;
//       flex-wrap: wrap;
//       background: #010001;
//       border-radius: 16px;
//       padding: 1.2rem 1.6rem;

//       > li {
//         width: 100%;
//         display: flex;
//         gap: 3.2rem;
//         background: none;
//         padding: 0.8rem;
//         label {
//           min-width: 8rem;
//           font-family: "Albert Sans";
//           font-style: normal;
//           font-weight: 400;
//           font-size: 14px;
//           line-height: 18px;
//           display: flex;
//           align-items: center;
//           color: #969696;
//           background: none;
//         }

//         h5 {
//           font-family: "Albert Sans";
//           font-style: normal;
//           font-weight: 400;
//           font-size: 14px;
//           line-height: 18px;
//           color: #ffffff;
//           background: none;
//           overflow-wrap: anywhere;
//           margin-left: auto;
//           text-align: right;
//           margin-bottom: 0;
//         }
//       }
//     }

//     > div {
//       background: none;
//       > div {
//         display: flex;
//         gap: 3.2rem;
//         padding: 0 2rem;
//         background: none;

//         img {
//           width: 6.4rem;
//           height: 6.4rem;
//           object-fit: contain;
//           background: none;
//         }

//         .name-price-wrapper {
//           display: flex;
//           flex-direction: column;
//           background: none;
//           justify-items: space-between;
//           align-items: flex-start;
//           width: 100%;

//           h2 {
//             font-family: "Albert Sans";
//             font-style: normal;
//             font-weight: 500;
//             font-size: 16px;
//             line-height: 24px;
//             display: flex;
//             align-items: center;
//             color: #ffffff;
//             background: none;
//           }

//           h3 {
//             font-family: "Albert Sans";
//             font-style: normal;
//             font-weight: 500;
//             font-size: 14px;
//             line-height: 16px;
//             text-align: left;
//             display: flex;
//             align-items: center;
//             color: #ffffff;
//             background: none;
//           }
//         }
//       }
//     }
//   }

//   .accordion-button {
//     width: 100%;
//     background: none;
//     border: none;
//     padding: 0 1.25rem;

//     &.collapsed {
//       margin: 0;
//       border: none;
//       ::after {
//         position: absolute;
//         right: 3rem;
//         background-image: url(${collapseBefore});
//         background-size: 2rem 2rem;
//         background-position-x: 50%;
//       }
//     }

//     &:not(.collapsed)::after {
//       position: absolute;
//       right: 3rem;
//       background-image: url(${collapseBefore});
//       background-size: 2rem 2rem;
//       background-position-x: 50%;
//     }

//     :focus {
//       box-shadow: none;
//       border-color: black;
//       background: #1c1c1c;
//       border-radius: 0px;
//     }

//     > div {
//       padding: 1.2rem 0;
//       margin: 0 1.2rem;
//       border-bottom: 0.2rem solid #262626;
//       width: 100%;
//       height: 6.8rem;
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       padding-right: 0rem;
//       gap: 3rem;
//       background: none;

//       h6 {
//         width: 33%;
//         font-family: "Albert Sans";
//         font-style: normal;
//         font-weight: 500;
//         font-size: 14px;
//         line-height: 16px;
//         color: #ffffff;
//         margin: auto;
//         background: none;
//         word-wrap: break-word;
//       }

//       .date-time {
//         display: flex;
//         flex-direction: column;
//         width: 33%;
//         margin: auto;
//         padding: 0;
//         background: none;
//         > h6 {
//           width: 100%;
//         }
//       }
//     }
//   }

//   .accordion-button:not(.collapsed) {
//     box-shadow: none;
//   }

//   .accordion-button {
//     &.collapsed:focus {
//       border-radius: 0px 0px 0px 0px;
//     }
//     :hover {
//       border-radius: 0px 0px 0px 0px;
//       background: #1c1c1c;
//     }
//   }
//   @media only screen and (min-width: 900px) {
//     .accordion-body {
//       border-radius: 0px 0px 16px 16px;
//       > ul {
//         margin: 1rem 1rem;
//         > li {
//           width: 50%;
//           label {
//             font-size: 16px;
//             line-height: 20px;
//           }
//           h5 {
//             font-size: 16px;
//             line-height: 20px;
//             margin-left: 0;
//           }
//         }
//       }
//       > div {
//         > div {
//           img {
//             width: 8rem;
//             height: 8rem;
//           }
//           .name-price-wrapper {
//             flex-direction: row;
//             align-items: center;
//             h2 {
//               font-size: 16px;
//               line-height: 24px;
//             }
//             h3 {
//               font-size: 16px;
//               line-height: 24px;
//               text-align: right;
//               margin-left: auto;
//             }
//           }
//         }
//       }
//     }
//     .accordion-button {
//       :focus {
//         border-radius: 16px 16px 0px 0px;
//       }
//       > div {
//         h6 {
//           font-size: 16px;
//           line-height: 24px;
//         }
//         .date-time {
//           flex-direction: row;
//           gap: 1rem;
//         }
//       }
//     }
//   }
// `;

const StyledAutoCompleteDiv = styled.div`
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  max-width: 72rem;
  * {
    background-color: transparent;
  }
  .ellipsis {
    color: #ffffff;
    span {
      color: #ffffff;
    }
  }

  .wrapper {
    /* display: none; */
    .line {
      display: none;
    }
    div:nth-child(2) {
      display: none;
    }
  }

  @media only screen and (min-width: 723.2px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

const StyledCars = styled.div``;
const StyledCarItemLink = styled(Link)`
  display: flex;
  flex-direction: row;
  gap: 2.4rem;
  margin: 1.6rem;
  border-bottom: 2px solid #262626;
  text-decoration: none;
  align-items: center;
  max-width: 72rem;
  /* justify-content: space-around; */
  :hover {
    cursor: pointer;
    border-bottom: 2px solid #cd0715;
  }
  .car-image {
    width: 8rem;
    height: 100%;
    object-fit: cover;
    object-position: center;
    margin: auto 0;
  }
  .car-details {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    .car-class {
      width: 6.4rem;
      height: 2.4rem;
      background: #1c1c1c;
      border-radius: 0.4rem;
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #ffffff;
    }
    .car-brand {
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.8rem;
      text-align: left;
      color: #ffffff;
    }
    .car-title {
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2rem;
      text-align: left;
      color: #ffffff;
    }
  }
  .link {
    display: none;
    :hover {
      background-color: #cd0715;
    }
  }
  @media only screen and (min-width: 480px) {
    .link {
      display: block;
      margin-left: auto;
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.8rem;
      text-align: left;
      color: #ffffff;
      text-decoration: none;
      border: 2px solid #ffffff;
      border-radius: 15px;
      padding: 0.475rem 2.1rem;
    }
  }

  @media only screen and (min-width: 600px) {
    gap: 4rem;
    padding-top: 4.1rem;
    padding-bottom: 4.1rem;
    .car-image {
      width: 12rem;
    }
    .car-details {
      gap: 0.8rem;
      .car-class {
        width: 7.2rem;
        height: 2.6rem;
        background: #1c1c1c;
        border-radius: 0.4rem;
        font-size: 1.4rem;
        line-height: 1.8rem;
      }
      .car-brand {
        font-size: 2rem;
        line-height: 2rem;
      }
      .car-title {
        font-size: 2rem;
        line-height: 2.4rem;
      }
    }
    .link {
      font-size: 1.6rem;
      line-height: 2rem;
      border-radius: 3rem;
      padding: 0.95rem 4.2rem;
    }
  }

  @media only screen and (min-width: 723.2px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

const CarItems = ({ currentItems, cars }) => {
  return (
    <StyledCars>
      {currentItems.map((item, index) => (
        <StyledCarItemLink
          key={index}
          to={
            item.class === '在庫車款'
              ? `/inventory/${item.itemId}`
              : `/topics/${item.itemId}`
          }
        >
          <img className="car-image" src={item.image} alt=""></img>
          <div className="car-details">
            <h6 className="car-class">{item.class}</h6>
            <h6 className="car-brand">{item.brand}</h6>
            <h6 className="car-title">{item.title}</h6>
          </div>
          <Link
            to={
              item.class === '在庫車款'
                ? `/inventory/${item.itemId}`
                : `/topics/${item.itemId}`
            }
            className="link"
          >
            更多詳情
          </Link>
        </StyledCarItemLink>
      ))}
    </StyledCars>
  );
};

const StyledReactPaginate = styled(ReactPaginate)`
  && {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
    gap: 8px;
    padding-top: 3.2rem;
  }

  .page-link {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    background-color: transparent;
    border-radius: 14px;
    border: transparent;
    color: #969696;
    user-select: none;
  }

  .page-active {
    background: #1c1c1c;
    border-radius: 14px;
    border: transparent;
    box-shadow: none;
    color: white;
  }

  .page-previous,
  .page-next {
    font-weight: 200;
    font-size: 18px;
    color: white;
    text-decoration: none;
    user-select: none;
  }
`;

const Search = () => {
  const itemsPerPage = 20;
  // console.log(item);

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;

  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  // const currentItems = item.slice(itemOffset, endOffset);
  // const pageCount = Math.ceil(item.length / itemsPerPage);

  // // Invoke when user click to request another page.
  // const handlePageClick = (event) => {
  //   const newOffset = (event.selected * itemsPerPage) % item.length;
  //   setItemOffset(newOffset);
  // };

  const [searchedAllData, setSearchedAllData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [cars, setCars] = useState([]);

  const { isLoading, sendRequest } = useHttpClient();

  useEffect(() => {
    const fetchSearch = async () => {
      try {
        const responseInventoryData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/inventory',
          'GET',
        );
        console.log(responseInventoryData);
        const inventoryItemLength = responseInventoryData.length;

        // id: 7, brand: 'Ferrari', title: '2022 Ferrari Testing'
        // image: "c316caf4-5658-4be4-a79f-365498ec4cd3.png"
        // setInventoryItems(responseData);

        const inventoryItems = responseInventoryData.map((item, index) => {
          return {
            id: index,
            class: '在庫車款',
            image: `${process.env.REACT_APP_DATA_URL}/inventory/${item.image}`,
            brand: item.brand,
            title: item.title,
            itemId: item.id,
          };
        });

        const responseTopicData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/topic',
          'GET',
        );

        const topicItems = responseTopicData.map((item, index) => {
          return {
            id: index + inventoryItemLength,
            class: '專題介紹',
            image: `${process.env.REACT_APP_DATA_URL}/uploads/topic/${item.car_image}`,
            brand: item.brand,
            title: item.car_title,
            itemId: item.id,
          };
        });

        setAllData(inventoryItems.concat(topicItems));
        setSearchedAllData(inventoryItems.concat(topicItems));
        setCars(inventoryItems.concat(topicItems));
      } catch (err) {}
    };
    fetchSearch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Note: due to `Every returned JSX element must be only one JSX element`,
  //       Tab cannot be included in this function
  const currentItems = searchedAllData.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(searchedAllData.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = event => {
    const newOffset = (event.selected * itemsPerPage) % searchedAllData.length;
    setItemOffset(newOffset);
  };

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string === '') {
      setSearchedAllData(allData);
    } else if (results.length > 0) {
      setSearchedAllData(results);
    }
  };

  const handleOnHover = result => {
    // the item hovered
    // console.log(result);
  };

  const handleOnSelect = item => {
    // the item selected
    // console.log("onSelect", item);
    // console.log(item);
  };

  const handleOnFocus = () => {
    // console.log("Focused");
  };

  const formatResult = item => {
    return (
      <span style={{ display: 'block', textAlign: 'left' }}>
        {item.inventory_date} &nbsp; {item.inventory_time} &nbsp; {item.name}
      </span>
    );
  };
  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}
      {!isLoading && !!allData && !!cars && (
        <React.Fragment>
          <StyledAutoCompleteDiv>
            <ReactSearchAutocomplete
              items={allData}
              onSearch={handleOnSearch}
              onHover={handleOnHover}
              onSelect={handleOnSelect}
              onFocus={handleOnFocus}
              maxResults={allData.length}
              formatResult={formatResult}
              showNoResultsText="無搜尋結果"
              placeholder="輸入關鍵字"
              resultStringKeyName="title"
              fuseOptions={{
                keys: ['brand', 'title', 'class'],
              }}
              styling={{
                backgroundColor: '#000000',
                borderRadius: '2.4rem',
                border: '2px solid #969696',
                fontFamily: 'Albert Sans',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '1.6rem',
                color: '#ffffff',
                iconColor: '#ffffff',
                lineHeight: '1.8rem',
                hoverBackgroundColor: '#1c1c1c',
                zIndex: '998',
                lineColor: '#969696',
                placeholderColor: '#969696',
              }}
            />
          </StyledAutoCompleteDiv>
          <CarItems currentItems={currentItems} cars={cars} />
          <StyledReactPaginate
            className="pagination"
            pageLinkClassName="page-link"
            activeLinkClassName="page-active"
            previousLinkClassName="page-previous"
            nextLinkClassName="page-previous"
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Search;
