import React from 'react';
import { Link } from 'react-router-dom';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import styled from 'styled-components';

const StyledSwiper = styled(Swiper)`
  * {
    background: #1c1c1c;
  }

  display: block;
  .swiper-slide {
    display: flex;
  }
  ul,
  li {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  img {
    height: 32rem;
    // max-width: 88.6rem;
    width: 100%;
    margin: 0;
    padding: 0;
    object-fit: cover;
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4rem 3.2rem 7.2rem 3.2rem;
    min-height: 42rem;
    // max-width: 42rem;
  }

  h3 {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
  }

  h1 {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    color: #ffffff;
    padding-bottom: 2.4rem;
  }

  p {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    padding-bottom: 2.4rem;
  }

  .button-more {
    box-sizing: border-box;
    padding: 1rem 2.4rem;
    max-width: 14.8rem;
    min-height: 3.9rem;
    border: 2px solid #ffffff;
    border-radius: 3rem;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
    :hover {
      background-color: #cd0715;
    }
  }

  .swiper-pagination {
    display: flex;
    justify-content: center;
    gap: 0.8rem;
  }

  .swiper-pagination-bullets {
    bottom: 3.2rem;
  }

  .swiper-pagination-bullet {
    border-radius: 0;
    width: 2.8rem;
    height: 0.1rem;
    background: white;
  }

  @media only screen and (min-width: 1000px) {
    height: 64rem;
    ul {
      display: flex;
      flex-direction: row;
      // margin: 0;
      padding: 0;
    }
    li {
      width: 50%;
      padding: 0;
    }

    .img-container {
      // height: 64rem;
      width: 120%;
      clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
    }

    img {
      height: 100%;
    }

    .text {
      margin: auto auto auto 0;
      max-width: 42rem;
      height: 23.5rem;
      padding-left: 0;
    }

    .swiper-pagination-bullets {
      left: 70%;
      width: 50%;
      justify-content: left;
      bottom: 4.8rem;
    }
  }
`;

const CarouselBanner = ({ items }) => {
  return (
    <StyledSwiper
      lazy={true}
      modules={[Pagination, Autoplay]}
      slidesPerView={1}
      pagination={{ clickable: true }}
      autoplay={{
        delay: 5000,
      }}
      spaceBetween={10}
      grabCursor={true}
      scrollbar={{ draggable: false }}
    >
      {items.map((d, index) => (
        <SwiperSlide key={index}>
          <ul>
            <li className="img-container">
              <img
                src={`${process.env.REACT_APP_DATA_URL}/uploads/topic/${d.carousel_image}`}
                alt="car"
              ></img>
            </li>
            <li className="text">
              <h3>{d.brand}</h3>
              <h1>{d.car_title}</h1>
              <p>{d.right_car_description}</p>
              <Link className="button-more" to={`/topics/${d.id}`}>
                瞭解更多
              </Link>
            </li>
          </ul>
        </SwiperSlide>
      ))}
    </StyledSwiper>
  );
};

export default CarouselBanner;
