import carZecureLogo from 'assets/logo.svg';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import TermsAndPrivacy from 'components/Layout/TermsAndPrivacy';

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 117rem;

  .left-column-wrapper,
  .right-column-wrapper {
    display: none;
  }

  .link {
    width: 5.6rem;
    height: 1.6rem;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #ffffff;
    opacity: 0.75;
    flex: none;
    flex-grow: 0;
    text-align: left;
    text-decoration: none;

    :hover {
      cursor: pointer;
    }
  }

  @media only screen and (min-width: 992px) {
    height: 31.2rem;
    justify-content: end;

    .hide-in-large-screen {
      display: none;
    }
    .middle-row-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-right: 2rem;

      .left-column-wrapper,
      .right-column-wrapper {
        display: block;
      }
    }
  }
`;

const StyledLogo = styled.div`
  margin: 2rem;
`;

const StyledLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-begin;
  padding: 0;
  gap: 1.6rem;
  flex-wrap: wrap;

  section {
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: auto;
    gap: 1.6rem;
    max-width: 16.35rem;
  }

  .title {
    width: 6.4rem;
    height: 2rem;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2rem;
    color: #969696;
    opacity: 0.75;
  }

  .link {
    width: 5.6rem;
    height: 1.6rem;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #ffffff;
    opacity: 0.75;
    flex: none;
    flex-grow: 0;
    text-align: left;
    text-decoration: none;

    :hover {
      cursor: pointer;
    }
  }

  @media only screen and (min-width: 992px) {
    gap: 8rem;
    left: 40rem;
    section {
      margin-left: 2rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      margin: 0;
      gap: 1.6rem;
      max-width: 6.4rem;
    }
  }
`;

const StyledServiceDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 3rem 0 4rem 0;
  padding: 0 2rem 0 2rem;
  justify-content: flex-start;
  align-items: flex-start;

  .phone {
    width: 5.6rem;
    height: 1.8rem;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin: 0.2rem 0 0.2rem 0;
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  .phone_no {
    width: 23.9rem;
    margin: 0.2rem 0 0.2rem 0;
    height: 1.8rem;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 1.8rem;
    color: white;
  }

  .email {
    width: 23.9rem;
    height: 1.8rem;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: white; 
    text-decoration: none;
  }

  .address {
    width: 23.9rem;
    height: 1.8rem;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #ffffff;
    text-decoration: none;
  }
`;

const Bottom = styled.div`
  ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 5.9rem;
    justify-content: flex-start;
    align-items: center;
    border-top: 0.1rem solid #a2a6b8;
  }

  li:hover {
    cursor: pointer;
  }

  .terms {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    text-align: left;
    margin-right: 2.4rem;
    color: #ffffff;
  }

  .privacy {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    text-align: right;
    color: #ffffff;
  }

  .copyright {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    text-align: right;
    color: #ffffff;
    margin-left: auto;
    margin-right: 2rem;
  }

  @media only screen and (min-width: 992px) {
    ul {
      border-top: 0.1rem solid #262626;
    }
    .terms {
      margin-left: auto;
      order: 1;
    }
    .privacy {
      order: 1;
    }
    .copyright {
      margin-left: 0;
      order: 0;
    }
  }
`;

const Logo = () => {
  return (
    <StyledLogo>
      <img src={carZecureLogo} alt="logo" />
    </StyledLogo>
  );
};

const Links = () => {
  return (
    <StyledLinks>
      <section>
        <div className="title">關於我們</div>

        <Link to="aboutUs" className="link">
          車咖簡介
        </Link>
        <Link to="aboutUs/offer" className="link">
          服務項目
        </Link>
        <Link to="aboutUs/contact" className="link">
          聯絡我們
        </Link>
        <Link to="aboutUs/recruitment" className="link">
          人才招募
        </Link>
      </section>
      <section>
        <div className="title">購車服務</div>
        <Link to="topics" className="link">
          專題介紹
        </Link>
        <Link to="inventory" className="link">
          在庫車款
        </Link>
        <Link to="service/eval" className="link">
          線上估價
        </Link>
        <Link to="service/sale" className="link">
          委託銷售
        </Link>
      </section>
    </StyledLinks>
  );
};

const Service = () => {
  return (
    <StyledServiceDiv>
        <h6 className="phone">服務專線</h6>
        <label for="phone" className="phone_no">0800-666-106</label>
        <a href = "mailto: sales@carzec.com" className="email">sales@carzec.com</a>
        <a href = "https://maps.app.goo.gl/PvM19jsVHNTYXNyE6" className="address">
                  10491台北市中山區建國北路三段173號
        </a>
    </StyledServiceDiv>
  );
};

export const modalTypeEnum = {
  PrivacyPolicy: 'privacyPolicy',
  TermsOfService: 'termsOfService',
};

const Footer = props => {
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(modalTypeEnum.PrivacyPolicy);

  return (
    <StyledSection>
      <div className="hide-in-large-screen">
        <Logo />
      </div>
      <div className="middle-row-wrapper">
        <div className="left-column-wrapper">
          <Logo />
          <Service />
        </div>
        <Links />
      </div>
      <div className="hide-in-large-screen">
        <Service />
      </div>
      <TermsAndPrivacy
        show={showModal}
        setShow={setShowModal}
        type={modalType}
      />
      <Bottom>
        <ul>
          <li
            className="terms"
            onClick={() => {
              setShowModal(true);
              setModalType(modalTypeEnum.TermsOfService);
            }}
          >
            服務條款
          </li>
          <li
            className="privacy"
            onClick={() => {
              setShowModal(true);
              setModalType(modalTypeEnum.PrivacyPolicy);
            }}
          >
            隱私權政策
          </li>
          <li className="copyright">©CarZecure 2023</li>
        </ul>
      </Bottom>
    </StyledSection>
  );
};

export default Footer;
