import React, { useState, createContext, useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useHttpClient } from 'shared/hooks/http-hook';
import { AuthContext } from 'shared/context/auth-context';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import styled from 'styled-components';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import carIcon from 'assets/imgs/car.svg';
import { ReactComponent as CalenderIcon } from 'assets/imgs/calender.svg';
import { ReactComponent as MemberIcon } from 'assets/imgs/member.svg';
import { ReactComponent as AddressIcon } from 'assets/imgs/address.svg';
import { ReactComponent as LogoutIcon } from 'assets/imgs/logout.svg';
import CarReservation from 'components/Member/CarReservation';
import MemberAddress from 'components/Member/MemberAddress';
import EmptyCarReservation from 'components/Member/EmptyCarReservation';
import CurrentAddress from 'components/Member/CurrentAddress';
import MemberProfile from 'components/Member/MemberProfile';
import MemberPassword from 'components/Member/MemberPassword';

const StyledMemberSection = styled.section`
  display: flex;
  flex-direction: row;
  max-width: 112rem;
  margin: 8.3rem auto auto auto;

  * {
    background: black;
    border: none;
    box-shadow: none;
  }

  .tab-section {
    position: sticky;
    top: 8rem;
    display: none;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    width: 18.6rem;
    height: 38rem;
    margin-left: 1.6rem;
    margin-right: auto;
  }

  .tab-content {
    margin: 0 auto;
  }

  .top-address-panel {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  .nav-tabs {
    display: flex;
    flex-direction: column;
    border: none;
    gap: 0.8rem;
    margin-top: 4.6rem;
  }

  .nav-label {
    cursor: pointer;
  }

  .header-wrapper {
    width: 18.6rem;
    height: 9.2rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    margin-bottom: 3.2rem;
    > .img-wrapper {
      width: 6.4rem;
      height: 6.4rem;
      background: #1c1c1c;
      border-radius: 4rem;
      display: flex;
      justify-content: center;

      > img {
        width: 3.2rem;
        height: 2.4rem;
        object-fit: contain;
        margin: auto;
        background: none;
      }
    }

    h4 {
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      margin-top: 0.8rem;
    }
  }

  .nav-link {
    padding: 0px 24px;
    gap: 12px;
    width: 186px;
    height: 48px;
    border-radius: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 13.8rem;
      height: 2.4rem;
      gap: 1.2rem;
      background: none;

      > :is(img, label, span, svg, text) {
        background: none;
      }

      > label {
        font-family: 'Albert Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #969696;
      }

      .reservation-count {
        font-family: 'Albert Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #969696;
      }
    }

    &.active {
      background: #850009;
      border: none;

      .calender,
      .address {
        > path {
          stroke: white;
        }
      }

      > div :is(label, text) {
        color: white;
      }

      .member {
        > path {
          fill: white;
        }

        > circle {
          stroke: white;
        }
      }
    }
  }

  .logout-wrapper {
    width: 100%;
    margin-top: 2rem;
    padding-top: 3.2rem;
    border-top: 0.2rem solid #262626;
    > button {
      display: flex;
      width: 13.8rem;
      height: 2.4rem;
      justify-content: flex-start;
      align-items: center;
      gap: 1.5rem;
      padding-left: 0.6rem;
      margin: auto;
      background: none;
      border: none;
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #969696;
    }
  }

  .tab-content {
    width: 85.4rem;

    h1 {
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #ffffff;
      padding-bottom: 1.2rem;
    }

    .reservation-list-top-title {
      padding-left: 1.6rem;
    }
  }

  @media only screen and (min-width: 900px) {
    &&& {
      margin-top: 12rem;
    }

    .tab-section {
      display: flex;
    }

    .tab-content {
      .reservation-wrapper {
        border-radius: 16px;
        header {
          font-size: 16px;
          line-height: 20px;
        }
      }
      .reservation-list-top-title {
        padding-left: 0;
      }
    }
  }
`;

export const AddressComponentRenderState = {
  AllAddress: 0,
  EditContactAddress: 1,
  NewContactAddress: 2,
  EditContactLocation: 3,
  NewContactLocation: 4,
};

export const ProfileComponentRenderState = {
  Profile: 0,
  ModifyPassword: 1,
};

export const DataContextMemberAddress = createContext(
  AddressComponentRenderState.AllAddress,
);

export const DataContextMemberProfile = createContext(
  ProfileComponentRenderState.Profile,
);

const Member = props => {
  const auth = useContext(AuthContext);
  const { isLoading, sendRequest } = useHttpClient();
  const navigate = useNavigate();

  const [addressItems, setAddressItems] = useState([]);
  const [locationItems, setLocationItems] = useState([]);
  const [reservationItems, setReservationItems] = useState([]);
  const [cars, setCars] = useState({});
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const fetchAddresses = async () => {
      if (!auth.isLoggedIn) {
        navigate('/member/login');
      } else {
        try {
          const reservationData = await sendRequest(
            process.env.REACT_APP_BACKEND_URL + '/reservation/history',
            'GET',
            null,
            {
              Authorization: 'Bearer ' + auth.token,
            },
          );
          setReservationItems(reservationData);

          const responseData = await sendRequest(
            process.env.REACT_APP_BACKEND_URL + '/addresses',
            'GET',
            null,
            {
              Authorization: 'Bearer ' + auth.token,
            },
          );
          const initAddressItems = responseData
            .filter(function (address) {
              return address.type === 0;
            })
            .map(
              ({
                id,
                name,
                company,
                mobile,
                email,
                zipcode,
                city,
                district,
                street,
              }) => ({
                id,
                name,
                company,
                mobile,
                email,
                zipCode: zipcode,
                city,
                district,
                street,
              }),
            );
          setAddressItems(initAddressItems);
          var initLocationItems = responseData
            .filter(function (address) {
              return address.type === 1;
            })
            .map(
              ({
                id,
                name,
                company,
                mobile,
                email,
                zipcode,
                city,
                district,
                street,
              }) => ({
                id,
                name,
                company,
                mobile,
                email,
                zipCode: zipcode,
                city,
                district,
                street,
              }),
            );
          setLocationItems(initLocationItems);

          // TODO: pass data to the user profile tab, only refetch on change
          // Do not fetch this again in user profile tab
          const userProfile = await sendRequest(
            process.env.REACT_APP_BACKEND_URL + '/users/profile',
            'GET',
            null,
            {
              Authorization: 'Bearer ' + auth.token,
            },
          );
          setUserData(userProfile);
          // setUserName(userProfile.name);
          // setAuthMethod(userProfile.auth_method);

          // Get the inventory data

          const inventoryData = await sendRequest(
            process.env.REACT_APP_BACKEND_URL + '/inventory/all',
            'GET',
          );

          // Construct items from the inventoryItems
          const inventoryCars = new Map(
            inventoryData.map(item => {
              return [
                item.id,
                {
                  img: `${process.env.REACT_APP_DATA_URL}/inventory/${item.image}`,
                  title: item.title,
                  price: item.price,
                },
              ];
            }),
          );
          setCars(inventoryCars);
        } catch (err) {}
      }
    };
    fetchAddresses();
  }, [auth, navigate, sendRequest, setLocationItems, setAddressItems]);

  const [showMemberAddress, setShowMemberAddress] = useState(
    AddressComponentRenderState.AllAddress,
  );

  const [showMemberProfile, setShowMemberProfile] = useState(
    ProfileComponentRenderState.Profile,
  );

  const [currentAddressData, setCurrentAddressData] = useState({});

  const getCurrentAddressIndex = items => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].street === currentAddressData.street) {
        return i;
      }
    }
  };

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}
      {!isLoading && (
        <StyledMemberSection>
          <Tab.Container
            defaultActiveKey="reservation"
            activeKey={props.activeKey}
          >
            <section className="tab-section">
              <div className="header-wrapper">
                <div className="img-wrapper">
                  <img src={carIcon} alt="" />
                </div>
                <h4>{userData.name}</h4>
              </div>
              <Nav>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/member/reservation"
                    eventKey="reservation"
                  >
                    <div>
                      <CalenderIcon className="calender" />
                      <label className="nav-label">預約清單</label>
                      <label className="reservation-count">
                        {reservationItems.length}
                      </label>
                    </div>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Nav>
                <Nav.Item>
                  <Nav.Link as={Link} to="/member/address" eventKey="address">
                    <div>
                      <AddressIcon className="address" />
                      <label className="nav-label">地址</label>
                    </div>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Nav>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/member/membership"
                    eventKey="membership"
                  >
                    <div>
                      <MemberIcon className="member" />
                      <label className="nav-label">會員資料</label>
                    </div>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <div className="logout-wrapper">
                <button type="button" onClick={auth.logout}>
                  <LogoutIcon />
                  登出
                </button>
              </div>
            </section>
            <Tab.Content>
              <Tab.Pane eventKey="reservation">
                <h1 className="reservation-list-top-title">預約清單</h1>
                {!reservationItems.length && <EmptyCarReservation />}
                {!!reservationItems.length && (
                  <CarReservation item={reservationItems} cars={cars} />
                )}
              </Tab.Pane>
              <Tab.Pane
                id="top-address-panel"
                className="top-address-panel"
                eventKey="address"
              >
                <DataContextMemberAddress.Provider
                  value={{
                    setShowMemberAddress,
                    setCurrentAddressData,
                    addressItems,
                    setAddressItems,
                    locationItems,
                    setLocationItems,
                  }}
                >
                  {showMemberAddress ===
                    AddressComponentRenderState.AllAddress && (
                    <MemberAddress
                      addressItems={addressItems}
                      locationItems={locationItems}
                    />
                  )}
                  {showMemberAddress ===
                    AddressComponentRenderState.EditContactAddress && (
                    <CurrentAddress
                      buttonName="儲存"
                      addressToEdit={currentAddressData}
                      addressToEditIndex={getCurrentAddressIndex(addressItems)}
                    />
                  )}
                  {showMemberAddress ===
                    AddressComponentRenderState.NewContactAddress && (
                    <CurrentAddress buttonName="新增" />
                  )}
                  {showMemberAddress ===
                    AddressComponentRenderState.EditContactLocation && (
                    <CurrentAddress
                      showMobileEmail={false}
                      buttonName="儲存"
                      addressToEdit={currentAddressData}
                      addressToEditIndex={getCurrentAddressIndex(locationItems)}
                    />
                  )}
                  {showMemberAddress ===
                    AddressComponentRenderState.NewContactLocation && (
                    <CurrentAddress showMobileEmail={false} buttonName="新增" />
                  )}
                </DataContextMemberAddress.Provider>
              </Tab.Pane>
              <Tab.Pane eventKey="membership">
                <DataContextMemberProfile.Provider value={setShowMemberProfile}>
                  {showMemberProfile ===
                    ProfileComponentRenderState.Profile && (
                    <MemberProfile userData={userData} />
                  )}
                  {showMemberProfile ===
                    ProfileComponentRenderState.ModifyPassword && (
                    <MemberPassword />
                  )}
                </DataContextMemberProfile.Provider>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </StyledMemberSection>
      )}
    </React.Fragment>
  );
};

export default Member;
