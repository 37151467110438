import styled from 'styled-components';

const StyledSection = styled.section`
  margin-top: 8rem;
  margin-bottom: 8rem;
  width: 100%;
  .section-main {
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .images-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-begin;
    flex-direction: row;
    justify-content: center;
    gap: 3.2rem;
    overflow: hidden;

    .image-small {
      width: 24rem;
      height: 33.333rem;
      filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.4));
      border-radius: 16px;
      object-fit: cover;
      :nth-child(2) {
        margin-top: 6.66rem;
      }
    }
  }

  .texts-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: none;

    .sub-title-decorator {
      position: absolute;
      width: 5rem;
      height: 4rem;
      top: 2.8rem;
      background: linear-gradient(
        100.46deg,
        #cd0715 0%,
        rgba(205, 7, 21, 0) 100%
      );
    }

    .sub-title-second {
      width: 100%;
      padding: 3.167rem 3.2rem 0.8rem 3.2rem;
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 32px;
      text-transform: uppercase;
      color: #ffffff;
      background: transparent;
    }
    .sub-text-second {
      width: 100%;
      padding: 0 3.2rem;
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: justify;
      color: #ffffff;
      background: transparent;
    }
  }

  @media only screen and (min-width: 1100px) {
    .section-main {
      flex-direction: row;
      justify-content: center;
      margin: auto;
      gap: 8rem;
    }
    .images-wrapper {
      gap: 4.8rem;
      width: 52.8rem;
      .image-small {
        height: 40rem;
        :nth-child(1) {
          margin-top: 8rem;
        }
        :nth-child(2) {
          margin-top: 0;
        }
      }
    }

    .texts-wrapper {
      max-width: 51.2rem;
      .sub-title-decorator {
        position: absolute;
        width: 13rem;
        height: 8rem;
        top: 1rem;
        left: -8rem;
      }

      .sub-title-second {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 3.2rem;
      }
      .sub-text-second {
        padding: 0;
      }
    }

    .align-right {
      flex-direction: row-reverse;
      .images-wrapper {
        .image-small {
          :nth-child(1) {
            margin-top: 0;
          }
          :nth-child(2) {
            margin-top: 8rem;
          }
        }
      }
      .texts-wrapper {
        text-align: right;
        .sub-title-decorator {
          position: absolute;
          top: 1rem;
          left: 46.2rem;
          background: linear-gradient(
            100.46deg,
            #cd0715 0%,
            rgba(205, 7, 21, 0) 100%
          );
          transform: matrix(-1, 0, 0, 1, 0, 0);
        }

        .sub-title-second {
          text-align: right;
        }
        .sub-text-second {
          padding: 0;
          text-align: right;
        }
      }
    }
  }
`;

const AboutUsTwoPicItem = props => {
  return (
    <StyledSection>
      <section
        className={`section-main ${props.alignRight ? 'align-right' : ''}`}
      >
        <div className="images-wrapper">
          <img className="image-small" src={props.carPhoto1} alt="" />
          <img className="image-small" src={props.carPhoto2} alt="" />
        </div>
        <div className="texts-wrapper">
          <div className="sub-title-decorator"></div>
          <h2 className="sub-title-second">{props.subTitle}</h2>
          <p className="sub-text-second">{props.description}</p>
        </div>
      </section>
    </StyledSection>
  );
};

export default AboutUsTwoPicItem;
