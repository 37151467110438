import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import arrowImg from 'assets/imgs/arrowDown.svg';
import CloseButton from 'react-bootstrap/CloseButton';
import tick from 'assets/imgs/tick.svg';
import radioSelect from 'assets/imgs/radio-select.svg';
import radioSelected from 'assets/imgs/radio-select-clicked.svg';
import Tab from 'react-bootstrap/Tab';
import ImageUploading from 'react-images-uploading';
import { Nav } from 'react-bootstrap';
import ErrorMessage from 'shared/components/ErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHttpClient } from 'shared/hooks/http-hook';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import { SERVICE_BG } from 'shared/constants/assets';

const StyledFormDiv = styled.div`
  *:not(.btn-close) {
    margin: 0;
    padding: 0;
    background: transparent;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #969696;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }

  display: flex;
  flex-direction: column;
  min-width: 100%;
  justify-content: between;
  padding: 0;

  .reservation-form {
    max-width: 66.4rem;
    margin: auto;
  }

  .title-wrapper {
    margin-bottom: 3rem;
    /* margin-left: -1.6rem; */
    /* width: eval(100vw+10rem); */
    background: linear-gradient(
        89.88deg,
        rgba(0, 0, 0, 0.98) 13.92%,
        rgba(0, 0, 0, 0.630365) 51.26%,
        rgba(0, 0, 0, 0) 101.36%
      ),
      url(${props =>
        props?.bg
          ? `${process.env.REACT_APP_DATA_URL}/uploads/bg/${props.bg}`
          : SERVICE_BG});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .title {
    display: flex;
    padding-left: 2rem;
    padding-top: 6.8rem;
    padding-bottom: 6.8rem;
    justify-content: space-between;
    align-items: center;
  }

  .title-text {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
  }

  li {
    padding: 1.6rem 0;
  }

  .nav-tabs {
    background: none;
    border: none;
  }

  .nav-tabs {
    display: flex;
    gap: 1.6rem;
  }

  .nav {
    display: flex;
    width: 100%;
    gap: 2.4rem;
    flex-wrap: wrap;
    padding: 1.8rem 1.6rem 4rem 1.6rem;
  }

  .nav-item {
    max-width: 15.6rem;
    min-width: 11rem;
  }

  .nav-link {
    height: 4rem;
    width: 15.6rem;
    padding-top: 0.8rem;
    border: 0.2rem solid #ffffff;
    border-radius: 3rem;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    text-align: center;
    display: inline-block;
    cursor: pointer;
  }

  .nav-link.active {
    background: #cd0715;
    color: #ffffff;
    :before {
      content: url(${tick});
      padding-right: 0.9rem;
    }
  }

  .sub-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 1.6rem;
    gap: 2.4rem;
  }

  .sub-form-radio {
    padding: 0 1.6rem;
    margin: 2.4rem 0;
  }

  .supplementary-note {
    padding: 0 1.6rem;
  }

  .form-element {
    width: 100%;
  }

  .element-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    padding: 0.4rem 0;
  }

  .element-input,
  .element-select {
    width: 100%;
    padding: 0 1.6rem;
    background: #010001;
    height: 4.8rem;
    border: 1px solid #969696;
    border-radius: 8px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: white;

    ::placeholder {
      color: #969696;
    }

    :focus {
      outline: none;
    }
  }

  .element-select {
    width: 100%;
    padding: 0 1.6rem;
    background: #010001;
    height: 4.8rem;
    border: 1px solid #969696;
    border-radius: 8px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: white;

    appearance: none;
    background-image: url(${arrowImg});
    background-repeat: no-repeat;
    background-position:
      right 1.6em top 50%,
      0 0;
    background-size:
      1.4em auto,
      100%;
  }

  .element-option {
    width: 100%;
    padding: 0 1.6rem;
    background: #010001;
    height: 4.8rem;
    border: 1px solid #969696;
    border-radius: 8px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: white;
  }

  .radio-form {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.4rem 0;
  }

  .radio-select {
    margin-left: 0.1rem;
    appearance: none;
    background: url(${radioSelect});
    background-size: contain;
    background-repeat: no-repeat;
    width: 2rem;
    height: 2rem;
    :checked {
      background: url(${radioSelected});
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .radio-title {
    height: 20px;

    /* Regular 16 */

    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    display: flex;
    align-items: center;

    /* Text/ff */

    color: #ffffff;
    margin: auto 1.2rem;
    display: inline-block;
  }

  .element-select {
    color: white;
  }

  .btn-select {
    width: 120px;
    height: 40px;
    background: #1c1c1c;
    border: 0px solid #ffffff;
    border-radius: 30px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
    border-color: none;
  }

  .note {
    margin: 2.8rem 1.6rem;
    padding: 1.2rem 1.6rem;

    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    color: #969696;
    background: #1c1c1c;
    border-radius: 8px;
  }

  .more-description {
    padding: 1.2rem 1.6rem;
    height: 9.6rem;
    resize: none;
  }

  .upload__image-wrapper {
    width: 100%;
    height: 100%;
    padding-top: 2.4rem;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  .upload-btn-wrapper {
    display: flex;
    gap: 2.4rem;
    padding: 0 1.6rem;
  }

  .btn-upload {
    width: 11.2rem;
    height: 4rem;
    border: 2px solid #ffffff;
    border-radius: 30px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;

    :active {
      background: #cd0715;
    }
  }

  .image-items {
    display: flex;
    flex-wrap: wrap;
    gap: 1.24rem;
  }

  .image-item {
    position: relative;
    width: 5.54rem;
    height: 5.54rem;
    object-fit: fill;
  }

  img {
    width: 5.54rem;
    height: 5.54rem;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    top: 0rem;
    width: 5.54rem;
    height: 5.54rem;
    background: rgba(0, 0, 0, 0.3);
  }

  .btn-close {
    position: absolute;
    right: 0.3rem;
    top: 0.3rem;
    width: 1rem;
    height: 1rem;
  }

  .image-count-recorder {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  .btn-bottom-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
    justify-content: flex-end;
    padding: 3.2rem 1.6rem 7.4rem 1.6rem;
  }

  .btn-confirm {
    width: 100%;
    height: 48px;
    background: #cd0715;
    border: 2px solid #cd0715;
    border-radius: 8px;
    border-color: none;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  @media only screen and (min-width: 720px) {
    background: url(${props =>
      props?.bg
        ? `${process.env.REACT_APP_DATA_URL}/uploads/bg/${props.bg}`
        : SERVICE_BG});
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 0rem;

    .title-wrapper {
      margin-bottom: auto;
      width: 100%;
      background: none;
      padding-left: 1rem;
    }

    .title {
      padding: 4rem 0 1.6rem 0;
    }

    .reservation-form-wrapper {
      max-width: 90.4rem;
      background: rgba(1, 0, 1, 0.7);
      backdrop-filter: blur(4px);
    }

    .sub-form {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0;
    }

    .sub-form-radio {
      padding: 0;
    }
    .form-element {
      width: 32rem;
    }

    .title-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .nav {
      padding: 4rem 0;
      display: flex;
      width: 100%;
      gap: 2.4rem;
    }

    .note {
      margin-left: 0;
      margin-right: 0;
    }

    .supplementary-note {
      padding: 0;
    }

    .upload-btn-wrapper {
      padding: 0;
    }

    .btn-bottom-wrapper {
      flex-direction: row;
      align-items: flex-start;
      padding-left: 0;
      padding-right: 0;
    }

    .upload__image-wrapper,
    .btn-bottom-wrapper {
      max-width: 66.4rem;
      margin: auto;
    }

    .btn-confirm {
      max-width: 100%;
      z-index: 2;
    }
  }
`;

const ServiceForm = props => {
  const [serviceBg, setServiceBg] = useState(undefined);
  const date = new Date();
  const futureDate = date.getDate() + 3;
  date.setDate(futureDate);

  const formSchema = yup.object().shape({
    name: yup.string().required('姓名為必填').max(32, '姓名最長為32字元'),
    line: yup.string().max(100, '請輸入正確LINE ID'),
    brand: yup
      .string()
      .required('車輛廠牌為必填')
      .max(40, '請輸入正確車輛廠牌'),
    model: yup
      .string()
      .required('車輛型號為必填')
      .max(40, '請輸入正確車輛型號'),
    year: yup
      .number()
      .typeError('請輸入正確出廠年份')
      .required('出廠年份為必填')
      .min(1500, '請輸入正確出廠年份')
      .max(2100, '請輸入正確出廠年份'),
    cc: yup
      .number()
      .typeError('請輸入正確CC數')
      .required('出廠年份為必填')
      .min(50, '請輸入正確CC數')
      .max(10000, '請輸入正確CC數'),
    appearance: yup
      .string()
      .required('外觀顏色為必填')
      .max(10, '請輸入正確外觀顏色'),
    interior: yup
      .string()
      .required('內裝顏色為必填')
      .max(10, '請輸入正確內裝顏色'),
    mileage: yup.string().required('里程數為必填').max(10, '請輸入正確里程數'),
    notes: yup.string().max(255, '補充說明最長為255個字'),
  });

  const {
    reset,
    register,
    formState: { errors, isDirty, isSubmitting },
    handleSubmit,
  } = useForm({ resolver: yupResolver(formSchema) });
  const [serviceType, setServiceType] = useState();
  const location = useLocation().pathname.split('/').at(-1);
  const [fuel, setFuel] = useState('gas');
  const [newCar, setNewCar] = useState(true);
  const [images, setImages] = useState([]);
  const maxNumber = 10;

  const { isLoading, error, sendRequest } = useHttpClient();
  const [responseMessage, setResponseMessage] = useState('');

  useEffect(() => {
    const fetchServiceBg = async () => {
      try {
        const res = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/bg/content',
          'GET',
        );
        setServiceBg(
          props.activeKey === 'sale'
            ? res.service_sale_bg
            : res.service_eval_bg,
        );
      } catch (err) {}
    };
    fetchServiceBg();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeKey]);

  useEffect(() => {
    if (isDirty || isSubmitting) {
      setResponseMessage('');
    }
  }, [isDirty, isSubmitting]);

  useEffect(() => {
    setServiceType(location);
    // console.log(location);
  }, [location]);

  const onSubmit = async data => {
    try {
      const formData = new FormData();
      formData.append('serviceType', serviceType);
      formData.append('name', data.name);
      formData.append('line', data.line);
      formData.append('brand', data.brand);
      formData.append('model', data.model);
      formData.append('year', data.year);
      formData.append('cc', data.cc);
      formData.append('fuel', data.fuel);
      formData.append('appearance', data.appearance);
      formData.append('interior', data.interior);
      formData.append('mileage', data.mileage);
      formData.append('newCar', data.newCar);
      formData.append('notes', data.notes);
      for (const image of images) {
        formData.append('images', image.file);
      }
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/upload/service',
        'POST',
        formData,
      );
      reset({
        name: '',
        line: '',
        brand: '',
        model: '',
        year: '',
        cc: '',
        fuel: 'gas',
        appearance: '',
        interior: '',
        mileage: '',
        newCar: true,
        notes: '',
      });
      setImages([]);
      setFuel('gas');
      setNewCar(true);
      setResponseMessage(responseData.message);
    } catch (err) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}
      {serviceBg && (
        <StyledFormDiv bg={serviceBg}>
          <div className="reservation-form-wrapper">
            <form
              className="reservation-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="title-wrapper">
                <div className="title">
                  <h1 className="title-text">線上服務</h1>
                </div>
              </div>
              <div className="title-top">
                <Tab.Container
                  defaultActiveKey="eval"
                  activeKey={props.activeKey}
                >
                  <Nav>
                    <Nav.Link eventKey="eval" as={Link} to="/service/eval">
                      線上估價
                    </Nav.Link>
                    <Nav.Link eventKey="sale" as={Link} to="/service/sale">
                      委託銷售
                    </Nav.Link>
                  </Nav>
                </Tab.Container>
              </div>
              <ul className="sub-form">
                <li className="form-element">
                  <h3 className="element-title">姓名</h3>
                  <input
                    className="element-input"
                    type="text"
                    placeholder="請輸入"
                    {...register('name')}
                  />
                  {errors.name && (
                    <ErrorMessage message={errors.name.message} />
                  )}
                </li>
                <li className="form-element">
                  <h3 className="element-title">LINE ID (選填)</h3>
                  <input
                    className="element-input"
                    type="text"
                    placeholder="請輸入"
                    {...register('line')}
                  />
                  {errors.line && (
                    <ErrorMessage message={errors.line.message} />
                  )}
                </li>
                <li className="form-element">
                  <h3 className="element-title">車輛廠牌</h3>
                  <input
                    className="element-input"
                    type="text"
                    placeholder="請輸入"
                    {...register('brand')}
                  />
                  {errors.brand && (
                    <ErrorMessage message={errors.brand.message} />
                  )}
                </li>
                <li className="form-element">
                  <h3 className="element-title">車輛型號</h3>
                  <input
                    className="element-input"
                    type="text"
                    placeholder="請輸入"
                    {...register('model')}
                  />
                  {errors.model && (
                    <ErrorMessage message={errors.model.message} />
                  )}
                </li>
                <li className="form-element">
                  <h3 className="element-title">出廠年份</h3>
                  <input
                    className="element-input"
                    type="number"
                    placeholder="請輸入"
                    {...register('year')}
                  />
                  {errors.year && (
                    <ErrorMessage message={errors.year.message} />
                  )}
                </li>
                <li className="form-element">
                  <h3 className="element-title">CC數</h3>
                  <input
                    className="element-input"
                    type="number"
                    placeholder="請輸入"
                    {...register('cc')}
                  />
                  {errors.cc && <ErrorMessage message={errors.cc.message} />}
                </li>
              </ul>
              <div className="sub-form-radio">
                <h3 className="element-title">引擎燃料</h3>
                <div className="radio-form">
                  <input
                    className="radio-select"
                    type="radio"
                    value="gas"
                    id="gas"
                    {...register('fuel')}
                    checked={fuel === 'gas'}
                    onChange={e => setFuel(e.currentTarget.value)}
                  />
                  <label className="radio-title">汽油 &ensp;</label>
                  <input
                    className="radio-select"
                    type="radio"
                    value="diesel"
                    id="diesel"
                    {...register('fuel')}
                    checked={fuel === 'diesel'}
                    onChange={e => setFuel(e.currentTarget.value)}
                  />
                  <label className="radio-title">柴油</label>
                  <input
                    className="radio-select"
                    type="radio"
                    value="hybrid"
                    id="hybrid"
                    {...register('fuel')}
                    checked={fuel === 'hybrid'}
                    onChange={e => setFuel(e.currentTarget.value)}
                  />
                  <label className="radio-title">油電</label>
                  <input
                    className="radio-select"
                    type="radio"
                    value="electric"
                    id="electric"
                    {...register('fuel')}
                    checked={fuel === 'electric'}
                    onChange={e => setFuel(e.currentTarget.value)}
                  />
                  <label className="radio-title">純電</label>
                </div>
              </div>
              <ul className="sub-form">
                <li className="form-element">
                  <h3 className="element-title">外觀顏色</h3>
                  <input
                    className="element-input"
                    type="text"
                    placeholder="請輸入"
                    {...register('appearance')}
                  />
                  {errors.appearance && (
                    <ErrorMessage message={errors.appearance.message} />
                  )}
                </li>
                <li className="form-element">
                  <h3 className="element-title">內裝顏色</h3>
                  <input
                    className="element-input"
                    type="text"
                    placeholder="請輸入"
                    {...register('interior')}
                  />
                  {errors.interior && (
                    <ErrorMessage message={errors.interior.message} />
                  )}
                </li>
              </ul>
              <ul className="sub-form-radio">
                <li>
                  <h3 className="element-title">里程數</h3>
                  <input
                    className="element-input"
                    type="number"
                    placeholder="請輸入"
                    {...register('mileage')}
                  />
                  {errors.mileage && (
                    <ErrorMessage message={errors.mileage.message} />
                  )}
                </li>
              </ul>
              <div className="sub-form-radio">
                <h3 className="element-title">是否為一手車至今?</h3>
                <div className="radio-form">
                  <input
                    className="radio-select"
                    type="radio"
                    value={true}
                    id="yes"
                    {...register('newCar')}
                    checked={newCar === true}
                    onChange={e => setNewCar(true)}
                  />
                  <label className="radio-title">是 &ensp;</label>
                  <input
                    className="radio-select"
                    type="radio"
                    value={false}
                    id="no"
                    {...register('newCar')}
                    checked={newCar === false}
                    onChange={e => setNewCar(false)}
                  />
                  <label className="radio-title">否</label>
                </div>
              </div>
              <p className="note">
                為加強本公司提供估價之準確及競爭力，建議可文字敘述或提供照片檔，說明爱車之價值，如配備/改裝/保養维修耗材更换等付出之心血費用或該產品特殊稀有之程度，及您對車輛愛護使用保管之程度)
              </p>
              <ul className="supplementary-note">
                <li>
                  <h3 className="element-title">愛車補充說明（最多255字）</h3>
                  <textarea
                    className="element-input more-description"
                    type="text"
                    placeholder="請輸入"
                    {...register('notes')}
                  />
                  {errors.notes && (
                    <ErrorMessage message={errors.notes.message} />
                  )}
                </li>
              </ul>
            </form>
            <ImageUploading
              multiple
              value={images}
              maxFileSize={3000000}
              onChange={(imageList, addUpdateIndex) => {
                // console.log(imageList, addUpdateIndex);
                setImages(imageList);
              }}
              maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({ imageList, onImageUpload, onImageRemove }) => (
                <div className="upload__image-wrapper">
                  <div className="image-items">
                    {imageList.map((image, index) => (
                      <div key={index} className="image-item">
                        <img src={image['data_url']} alt="" />
                        <div className="overlay"></div>
                        <CloseButton
                          variant="white"
                          onClick={() => onImageRemove(index)}
                        ></CloseButton>
                      </div>
                    ))}
                  </div>
                  <div className="upload-btn-wrapper">
                    <button className="btn-upload" onClick={onImageUpload}>
                      上傳圖片
                    </button>
                    <p className="image-count-recorder">
                      {imageList.length}/{maxNumber}{' '}
                      (若需上傳圖片，每張需小於3MB)
                    </p>
                  </div>
                  {responseMessage && (
                    <ErrorMessage message={responseMessage} />
                  )}
                </div>
              )}
            </ImageUploading>
            <div className="btn-bottom-wrapper">
              <button
                type="button"
                className="btn-confirm"
                onClick={handleSubmit(onSubmit)}
              >
                確認送出
              </button>
            </div>
          </div>
        </StyledFormDiv>
      )}
    </React.Fragment>
  );
};

export default ServiceForm;
