import React, { useContext } from 'react';
import {
  DataContextMemberAddress,
  AddressComponentRenderState,
} from 'pages/Member';
import styled from 'styled-components';
import { AuthContext } from 'shared/context/auth-context';
import { useHttpClient } from 'shared/hooks/http-hook';

const StyledAddressCardDiv = styled.div`
  && {
    width: 100%;
    border-radius: 16px;
    background: #1c1c1c;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .text-wrapper {
    padding: 0 2.4rem 2rem 2.4rem;
    background: none;
  }

  .contact-title-wrapper {
    display: flex;
    flex-direction: row;
    gap: 2.4rem;
    padding-top: 2rem;
    background: none;

    .contact-title {
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #ffffff;
      padding: 0;
      background: none;
    }
  }

  .contact-info {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #ffffff;
    padding: 0;
    background: none;
  }

  .btn-wrapper {
    width: 100%;
    border-top: 0.2rem solid black;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 1.2rem;
    background: none;
    gap: 3rem;
    padding: 1.2rem 2.4rem;
  }

  .btn-remove {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    text-decoration-line: underline;
    color: #969696;
    background: none;
  }

  .btn-edit {
    width: 68px;
    height: 40px;
    background: #010001;
    border-radius: 30px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
  }

  @media only screen and (min-width: 900px) {
    && {
      width: 48%;
    }
  }
`;

const StyledAddressCardPanelDiv = styled.div`
  && {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2.4rem;
    padding-bottom: 4.4rem;
  }
`;

const AddressCard = ({ item, type }) => {
  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();

  const {
    setShowMemberAddress,
    setCurrentAddressData,
    addressItems,
    setAddressItems,
    locationItems,
    setLocationItems,
  } = useContext(DataContextMemberAddress);
  const editMemberAddress = type => {
    setShowMemberAddress(type);
    setCurrentAddressData(item);
  };

  const removeItem = async () => {
    const outputArray = [];
    if (item.mobile.length) {
      for (let i = 0; i < addressItems.length; i++) {
        if (addressItems[i].street !== item.street) {
          outputArray.push(addressItems[i]);
        } else {
          try {
            await sendRequest(
              process.env.REACT_APP_BACKEND_URL + '/addresses/delete',
              'POST',
              JSON.stringify({
                id: addressItems[i].id,
              }),
              {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token,
              },
            );
          } catch (err) {}
        }
      }
      setAddressItems(outputArray);
    } else {
      for (let i = 0; i < locationItems.length; i++) {
        if (locationItems[i].street !== item.street) {
          outputArray.push(locationItems[i]);
        } else {
          try {
            await sendRequest(
              process.env.REACT_APP_BACKEND_URL + '/addresses/delete',
              'POST',
              JSON.stringify({
                id: addressItems[i].id,
              }),
              {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token,
              },
            );
          } catch (err) {}
        }
      }
      setLocationItems(outputArray);
    }
  };

  return (
    <StyledAddressCardDiv>
      <div className="text-wrapper">
        <div className="contact-title-wrapper">
          <h1 className="contact-title">{item.name}</h1>
          {item.company.length && (
            <h1 className="contact-title">{item.company}</h1>
          )}
        </div>
        {type === 1 && !!item.mobile.length && (
          <h2 className="contact-info">{item.mobile}</h2>
        )}
        {type === 1 && !!item.email.length && (
          <h2 className="contact-info">{item.email}</h2>
        )}
        {item.street.length && (
          <h2 className="contact-info">
            {item.zipCode}
            {item.city}
            {item.district}
            {item.street}
          </h2>
        )}
      </div>
      <div className="btn-wrapper">
        <button className="btn-remove" onClick={removeItem}>
          移除
        </button>
        <button onClick={() => editMemberAddress(type)} className="btn-edit">
          編輯
        </button>
      </div>
    </StyledAddressCardDiv>
  );
};

const AddressCardPanel = ({
  items,
  type = AddressComponentRenderState.EditContactAddress,
}) => {
  return (
    <StyledAddressCardPanelDiv>
      {items.map((item, index) => (
        <AddressCard key={index} item={item} type={type} />
      ))}
    </StyledAddressCardPanelDiv>
  );
};

export default AddressCardPanel;
