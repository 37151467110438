import styled from 'styled-components';
import addressEmptyIcon from 'assets/imgs/address-empty.svg';

const EmptyAddressWrapper = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 14.6rem;
    gap: 1.5rem;
    margin-bottom: 3rem;
    background: #1c1c1c;
    border-radius: 16px;
  }

  .empty-address-icon {
    width: 4.2rem;
    height: 4.2rem;
    background: none;
  }

  .empty-address-label {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    background: none;
  }
`;

const EmptyAddress = ({ label }) => {
  return (
    <EmptyAddressWrapper>
      <img className="empty-address-icon" src={addressEmptyIcon} alt="" />
      <h3 className="empty-address-label">{label}</h3>
    </EmptyAddressWrapper>
  );
};

export default EmptyAddress;
