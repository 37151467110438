import React, { useState } from 'react';
import styled from 'styled-components';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ReactPaginate from 'react-paginate';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

const Intro = styled.div`
  h1 {
    padding-top: 3rem;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    text-align: center;

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
  }

  .title {
    padding-top: 6.2rem;
  }

  .nav-tabs {
    display: flex;
    border: none;
    justify-content: center;
  }

  .nav-link,
  .nav-link.active {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;

    /* Text/ff */
    color: #ffffff;
    background: none;
    border: none;
    margin: auto;
    padding: 1.2rem 2.4rem;
  }

  .nav-link.active {
    font-weight: 700;
    border-bottom: 0.1rem solid red;
  }

  .tab-pane.active.show {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 140rem;
    margin: auto;
  }

  a.card {
    display: flex;
    margin: 3rem 1.6rem 3rem 1.6rem;
    width: 34.3rem;
    height: 37.4rem;
    background: #1c1c1c;
    border-radius: 16px 16px 0px 0px;
    text-decoration: none;

    :hover {
      .card-body {
        border-bottom: 2px solid #cd0715;
      }
      .card-img-overlay {
        width: 100%;
        height: 98%;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 37.5%,
          rgba(0, 0, 0, 0.4) 100%
        );
        filter: drop-shadow(0px 8px 36px rgba(255, 255, 255, 0.16));
      }
      .card-img-top {
        transform: scale(1.6);
        transition: all 0.4s ease-in-out;
      }
    }
  }

  .img-container {
    overflow: hidden;
    margin: 0;
    padding: 0;
    border-radius: 16px 16px 0px 0px;
  }

  .card-img-top {
    width: 100%;
    height: 21.2rem;
    object-fit: cover;
    border-radius: 16px 16px 0px 0px;
  }

  .card-img-overlay {
    background: transparent;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-begin;
    padding: 20px;
    gap: 12px;
    background: #1c1c1c;
  }

  .card-title {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */
    color: #ffffff;
    background: none;
  }

  .card-subtitle {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    color: #ffffff;
    background: none;
  }

  .card-text {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* or 129% */

    text-align: justify;

    /* Text/Click */

    color: #969696;
    background: none;
  }

  .pagination {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
    gap: 8px;
  }

  .page-link {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    background-color: transparent;
    border-radius: 14px;
    border: transparent;
    color: #969696;
    user-select: none;
  }

  .page-active {
    background: #1c1c1c;
    border-radius: 14px;
    border: transparent;
    color: white;
  }

  .page-previous,
  .page-next {
    font-weight: 200;
    font-size: 18px;
    color: white;
    text-decoration: none;
    user-select: none;
  }

  @media only screen and (min-width: 1000px) {
    .title {
      padding-top: 4.4rem;
    }
  }

  @media only screen and (min-width: 1200px) {
    a.card {
      flex: 0 32%;
      margin: 1.6rem;
      max-width: 37.867rem;
    }
  }
`;

const Items = ({ currentItems }) => {
  return (
    <>
      {currentItems &&
        currentItems.map((item, index) => (
          <Card as={Link} to={`/topics/${item.id}`} key={index}>
            <div className="img-container">
              <Card.Img
                variant="top"
                src={`${process.env.REACT_APP_DATA_URL}/uploads/topic/${item.car_image}`}
              />
            </div>
            <Card.ImgOverlay />
            <Card.Body>
              <Card.Title>{item.brand}</Card.Title>
              <Card.Subtitle>{item.car_title}</Card.Subtitle>
              <Card.Text>
                {item.right_car_description.length > 68
                  ? item.right_car_description.slice(0, 68) + '...'
                  : item.right_car_description}
              </Card.Text>
            </Card.Body>
          </Card>
        ))}
    </>
  );
};

const Page = ({ item }) => {
  const itemsPerPage = 9;
  // console.log(item);

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = item.cars.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(item.cars.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = event => {
    const newOffset = (event.selected * itemsPerPage) % item.cars.length;
    setItemOffset(newOffset);
  };

  // Note: due to `Every returned JSX element must be only one JSX element`,
  //       Tab cannot be included in this function
  return (
    <>
      <Items currentItems={currentItems} />
      <ReactPaginate
        className="pagination"
        pageLinkClassName="page-link"
        activeLinkClassName="page-active"
        previousLinkClassName="page-previous"
        nextLinkClassName="page-previous"
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
      />
    </>
  );
};

const TopicIntroduction = ({ items }) => {
  // console.log(items);
  // TODO: Use filter and meaningful keys
  // Get the unique brand names from the inventory items
  const brands = [...new Map(items.map(item => [item.brand, item])).keys()];

  // Create items arrays for each brand
  const topicItems = brands.map(brand => ({
    key: brand,
    title: brand,
    // Filter the inventory items by brand name
    cars: items.filter(item => item.brand === brand),
  }));

  const total = {
    key: 'total',
    title: '全部',
    cars: items,
  };

  // Add "total" to the beginning of the array
  topicItems.unshift(total);

  // console.log(topicItems);
  return (
    <Intro>
      <h1 className="title">專題介紹</h1>
      <Tabs defaultActiveKey={topicItems[0].key}>
        {topicItems.map((item, index) => (
          <Tab key={item.key} eventKey={item.key} title={item.title}>
            <Page key={index} item={item} />
          </Tab>
        ))}
      </Tabs>
    </Intro>
  );
};

export default TopicIntroduction;
