import styled from 'styled-components';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ErrorMessage from 'shared/components/ErrorMessage';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHttpClient } from 'shared/hooks/http-hook';
import { AuthContext } from 'shared/context/auth-context';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import { useNavigate } from 'react-router-dom';

import {
  DataContextMemberProfile,
  ProfileComponentRenderState,
} from 'pages/Member';

const StyledMemberProfileSection = styled.section`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #969696;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }

  && {
    padding: 0 1.6rem;
  }

  .title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    padding-left: 0;
  }

  .btn-line {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    width: 100%;
    height: 4.8rem;
    background: #07c203;
    mix-blend-mode: normal;
    border-radius: 8px;
    margin-bottom: 2.4rem;
    margin-top: 1.9rem;
  }

  .btn-icon {
    width: 2rem;
    height: 1.906rem;
    background: none;
  }

  .btn-text {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    margin: auto 0;
    background: none;
  }

  .sub-form {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2.4rem;
  }
  .form-element {
    width: 100%;
  }

  .element-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    padding: 0.4rem 0;
  }
  .element-input {
    width: 100%;
    padding: 0 1.6rem;
    background: #010001;
    height: 4.8rem;
    border: 1px solid #969696;
    border-radius: 8px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: white;

    ::placeholder {
      color: #969696;
    }

    :focus {
      outline: none;
    }
  }

  .btn-wrapper {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    justify-content: flex-end;
    padding-top: 5.6rem;
  }

  .btn-change-pwd,
  .btn-confirm {
    width: 15.6rem;
    height: 4.8rem;
    border: 2px solid #ffffff;
    border-radius: 8px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  .btn-confirm {
    background: #cd0715;
  }

  @media only screen and (min-width: 720px) {
    .title-wrapper {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    .btn-line {
      max-width: 15.6rem;
      margin-top: 0;
    }

    .sub-form {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 2.4rem;
    }

    .form-element {
      max-width: 48%;
    }

    .btn-wrapper {
      padding-top: 4rem;
    }
  }
`;

const MemberProfile = ({ userData }) => {
  const setShowMemberProfile = useContext(DataContextMemberProfile);
  const modifyMemberPassword = state => {
    setShowMemberProfile(state);
  };

  const formSchema = yup.object().shape({
    name: yup.string().required('姓名為必填').max(32, '姓名最長為32字元'),
    company: yup.string().max(32, '公司名稱最長為32字元'),
    mobile: yup
      .string()
      .required('手機號碼為必填')
      .matches(/^09\d{2}-\d{3}-\d{3}$/, '請輸入正確手機號碼'),
    email: yup.string().required('Email為必填').email('請輸入正確Email'),
  });

  const {
    register,
    formState: { errors, isValidating },
    handleSubmit,
    setValue,
  } = useForm({ resolver: yupResolver(formSchema) });

  const formatPhoneNumber = value => {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you format the area code to early

    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 4)}-${phoneNumber.slice(4)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `${phoneNumber.slice(0, 4)}-${phoneNumber.slice(
      4,
      7,
    )}-${phoneNumber.slice(7, 10)}`;
  };
  const handlePhoneNumberInput = e => {
    // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    // we'll set the input value using our setValue
    setValue('mobile', formattedPhoneNumber, {
      shouldValidate: true,
    });
  };

  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const navigate = useNavigate();
  const [responseMessage, setResponseMessage] = useState('');
  useEffect(() => {
    const fetchProfile = async () => {
      if (!auth.isLoggedIn) {
        navigate('/member/login');
      } else {
        try {
          setValue('name', userData.name, {
            shouldValidate: true,
          });
          setValue('company', userData.company, {
            shouldValidate: true,
          });
          setValue('email', userData.email, {
            shouldValidate: true,
          });
          setValue('mobile', userData.mobile, {
            shouldValidate: true,
          });
        } catch (err) {}
      }
    };
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, sendRequest]);

  useEffect(() => {
    if (isValidating) {
      setResponseMessage('');
    }
  }, [isValidating]);

  const onSubmit = async data => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/users/profile',
        'POST',
        JSON.stringify({
          name: data.name,
          company: data.company,
          email: data.email,
          mobile: data.mobile,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        },
      );
      setResponseMessage(responseData.message);
      // console.log(responseData.message);
    } catch (err) {}
  };
  return (
    <>
      {isLoading && <LoadingSpinner asOverlay />}
      <StyledMemberProfileSection>
        <div className="title-wrapper">
          <h2 className="title">會員資料</h2>
        </div>
        <form className="sub-form" onSubmit={handleSubmit(onSubmit)}>
          <li className="form-element">
            <h3 className="element-title">姓名</h3>
            <input
              className="element-input"
              type="text"
              placeholder="請輸入"
              {...register('name')}
            />
            {errors.name && <ErrorMessage message={errors.name.message} />}
          </li>
          <li className="form-element">
            <h3 className="element-title">公司名稱 (選填)</h3>
            <input
              className="element-input"
              type="text"
              placeholder="請輸入"
              {...register('company')}
            />
            {errors.company && (
              <ErrorMessage message={errors.company.message} />
            )}
          </li>
          <li className="form-element">
            <h3 className="element-title">Email</h3>
            <input
              className="element-input"
              type="email"
              autoComplete="on"
              readOnly={true}
              placeholder="請輸入"
              {...register('email')}
            />
            {errors.email && <ErrorMessage message={errors.email.message} />}
          </li>
          <li className="form-element">
            <h3 className="element-title">手機號碼</h3>
            <input
              className="element-input"
              placeholder="請輸入"
              {...register('mobile')}
              readOnly={true}
              // TODO: remove the related code for the attribute which does not allow to be changed
              // onChange={(e) => handlePhoneNumberInput(e)}
            />
            {errors.mobile && <ErrorMessage message={errors.mobile.message} />}
            {responseMessage && <ErrorMessage message={responseMessage} />}
          </li>
        </form>
        <div className="btn-wrapper">
          {userData.auth_method === 'password' && (
            <button
              onClick={() =>
                modifyMemberPassword(ProfileComponentRenderState.ModifyPassword)
              }
              className="btn-change-pwd"
            >
              修改密碼
            </button>
          )}
          <button className="btn-confirm" onClick={handleSubmit(onSubmit)}>
            儲存變更
          </button>
        </div>
      </StyledMemberProfileSection>
    </>
  );
};

export default MemberProfile;
