// import React from "react";
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import router from './App.js';
import ReactGA from 'react-ga4';

ReactGA.initialize(`${process.env.REACT_APP_GA_MEASUREMENT}`);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <RouterProvider router={router} />,
  // </React.StrictMode>
);
