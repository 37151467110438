import React from 'react';
import styled from 'styled-components';

const StyledSpan = styled.div`
  width: 100%;
  height: fit-content;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ff3846;
  padding-left: 1.6rem;
  padding-top: 0.4rem;
  background-color: transparent;
`;

const ErrorMessage = props => {
  return <StyledSpan className="error-message">{props.message}</StyledSpan>;
};

export default ErrorMessage;
