import styled from 'styled-components';

const StyledSection = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .banner {
    height: 48rem;
    object-fit: cover;
    width: 100%;
  }

  .title {
    position: relative;
    width: 100%;
    top: -3.6rem;
    margin: 0;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 72px;
    text-transform: uppercase;
    text-align: left;
    color: #ffffff;
    background: none;
    text-shadow: 4px 4px 32px rgba(0, 0, 0, 0.4);
  }

  .section-first {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
    width: 100%;
    max-width: 112rem;
  }

  .sub-title-first {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    color: #ffffff;
    margin-bottom: 2.4rem;
  }

  .sub-paragraph-first {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
  }

  @media only screen and (min-width: 1106.4px) {
    .title {
      max-width: 112rem;
      padding-left: 0;
    }
    .section-first {
      height: 48rem;
      padding-left: 0;
      padding-right: 0;
      padding-top: 0rem;
      padding-top: 8.8rem;
    }
  }
`;

const AboutUsBanner = prop => {
  return (
    <StyledSection>
      <img className="banner" src={prop.bannerPhoto} alt="" />
      <h1 className="title">{prop.title}</h1>
      <section className="section-first">
        <h2 className="sub-title-first">{prop.subTitle}</h2>
        <p className="sub-paragraph-first">{prop.paragraph}</p>
        {prop.paragraph2 && <p className="sub-paragraph-first">{prop.paragraph2}</p>}
        {prop.paragraph3 && <p className="sub-paragraph-first">{prop.paragraph3}</p>}
        {prop.paragraph4 && <p className="sub-paragraph-first">{prop.paragraph4}</p>}
        {prop.paragraph5 && <p className="sub-paragraph-first">{prop.paragraph5}</p>}
      </section>
    </StyledSection>
  );
};

export default AboutUsBanner;
