import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import ErrorMessage from 'shared/components/ErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHttpClient } from 'shared/hooks/http-hook';
import * as yup from 'yup';
import { useState, useRef, useEffect } from 'react';

const TIMEOUT = 60;
const StyledInfoForm = styled.form`
  margin: auto;
  padding: 4rem 1.6rem 0 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.2rem;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #969696;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }

  .title {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    margin-bottom: 0;
  }

  .input-elements {
    width: 100%;
    max-width: 36rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .input-element {
    width: 100%;
    background: #010001;
    border: 1px solid #969696;
    border-radius: 8px;

    height: 4.8rem;

    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #ffffff;
    padding: 1.3rem 1.6rem;

    :focus {
      outline: none;
    }
  }

  .send-verification {
    width: 100%;
    max-width: 36rem;
    height: 48px;
    background: #cd0715;
    border-radius: 8px;

    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    border: none;
  }
`;

const MemberForgotPassword = () => {
  const { isLoading, sendRequest } = useHttpClient();

  const formSchema = yup.object().shape({
    email: yup.string().required('Email為必填').email('請輸入正確Email'),
  });
  const [timeoutSec, setTimeoutSec] = useState(TIMEOUT);
  const [showTimer, setShowTimer] = useState(false);

  let intervalRef = useRef();

  const decreaseTimeout = () => setTimeoutSec(prev => prev - 1);

  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = async data => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL +
          `/users/reset_password/${data.email}`,
        'POST',
        JSON.stringify({}),
        {
          'Content-Type': 'application/json',
        },
      );
      if (responseData.code === 202) {
        setErrorMessage('請確認信箱輸入正確，或已經綁定Line帳號。');
      } else {
        setTimeoutSec(TIMEOUT);
        intervalRef.current = setInterval(decreaseTimeout, 1000);
        sessionStorage.setItem('timeout', true);
        setShowTimer(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (timeoutSec <= 0) {
      clearInterval(intervalRef.current);
      setShowTimer(false);
      sessionStorage.setItem('timeout', false);
    } else if (sessionStorage.getItem('timeout') === 'true') {
      setShowTimer(true);
      intervalRef.current = setInterval(decreaseTimeout, 1000);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [timeoutSec]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ resolver: yupResolver(formSchema) }); /// TODO componentize
  return (
    <StyledInfoForm onSubmit={handleSubmit(onSubmit)}>
      <h1 className="title">忘記密碼</h1>
      <div className="input-elements">
        <input
          className="input-element"
          type="email"
          autoComplete="on"
          placeholder="Email"
          {...register('email', {
            onChange: e => {
              setErrorMessage('');
            },
          })}
        />
        {errors.email && <ErrorMessage message={errors.email.message} />}
        {errorMessage && <ErrorMessage message={errorMessage} />}
      </div>
      <button
        className="send-verification"
        onClick={handleSubmit(onSubmit)}
        disabled={!!errorMessage || isLoading || !!showTimer}
      >
        {showTimer && '請收取驗證信\n(' + timeoutSec + '秒後可再次發送)'}
        {!showTimer && '發送驗證信'}
      </button>
    </StyledInfoForm>
  );
};

export default MemberForgotPassword;
