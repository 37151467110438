import styled from 'styled-components';
import carEmptyIcon from 'assets/imgs/carEmpty.svg';
import { Link } from 'react-router-dom';

const EmptyCarReservationDiv = styled.div`
  box-sizing: border-box;
  height: 20.9rem;
  width: 100% - 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #1c1c1c;
  border-radius: 1.6rem;
  margin-left: 1.6rem;
  margin-right: 1.6rem;

  img {
    width: 4.9rem;
    height: 4.1rem;
    object-fit: contain;
    margin: 3.955rem auto 1.55rem auto;
    background: none;
  }

  h3 {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    background: none;
  }

  .link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 2.4rem;
    gap: 1rem;
    text-decoration: none;

    width: 14.8rem;
    height: 3.9rem;
    border: 2px solid #ffffff;
    border-radius: 3rem;

    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    margin-top: 2.4rem;
    background: none;
  }
`;

const EmptyCarReservation = () => {
  return (
    <EmptyCarReservationDiv>
      <img src={carEmptyIcon} alt="car" />
      <h3>目前尚無預約資料</h3>
      <Link className="link" to="/inventory">
        前往在庫車款
      </Link>
    </EmptyCarReservationDiv>
  );
};

export default EmptyCarReservation;
