import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import styled from 'styled-components';
import { Autoplay } from 'swiper';

const StyledSwiper = styled(Swiper)`
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    position: relative;
    text-align: center;
    font-size: 18px;
    background: #000;
    margin: 5% 0 8% 0;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: #1c1c1c;
  }

  .swiper-slide img {
    display: border-block;
    width: 100%;
    height: 21rem;
    object-fit: cover;
    background-color: transparent;
  }

  .swiper-pagination-bullet-active {
    im: #fff;
  }
`;

const CarouselWoIndicator = ({ images }) => {
  return (
    <>
      <StyledSwiper
        lazy={true}
        slidesPerView={1.3}
        centeredSlides={true}
        spaceBetween={20}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        grabCursor={true}
        modules={[Autoplay]}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img
              src={`${process.env.REACT_APP_DATA_URL}/uploads/home/${image}`}
              alt=""
              loading="lazy"
            />
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </>
  );
};

export default CarouselWoIndicator;
