import { useState, useCallback, useEffect } from 'react';

let logoutTimer;

export const useAuth = () => {
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [userId, setUserId] = useState(false);
  const [reservedCars, setReservedCars] = useState([]);

  const login = useCallback((uid, token, expirationDate) => {
    setToken(token);
    setUserId(uid);
    const tokenExpirationDate =
      expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60);
    setTokenExpirationDate(tokenExpirationDate);
    localStorage.setItem(
      'userData',
      JSON.stringify({
        userId: uid,
        token: token,
        expiration: tokenExpirationDate.toISOString(),
      }),
    );
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);
    localStorage.removeItem('userData');
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('userData'));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.userId,
        storedData.token,
        new Date(storedData.expiration),
      );
    }
  }, [login]);

  useEffect(() => {
    const cars = JSON.parse(localStorage.getItem('reservedCars'));
    // Init the cars array if it is null
    setReservedCars(!!cars ? cars : []);
  }, []);

  const addReservedCar = item => {
    let currentReservedCars = JSON.parse(localStorage.getItem('reservedCars'));

    if (currentReservedCars === null) {
      currentReservedCars = [];
    }
    currentReservedCars.push(item);

    // Only keep the unique items
    currentReservedCars = currentReservedCars.filter(
      (item, index, self) => index === self.findIndex(t => t.id === item.id),
    );

    localStorage.setItem('reservedCars', JSON.stringify(currentReservedCars));
    setReservedCars(currentReservedCars);
    // console.log("Reserved cars: ", currentReservedCars);
  };

  // Remove the reserved car from the local storage by the id
  const removeReservedCar = id => {
    let currentReservedCars = JSON.parse(localStorage.getItem('reservedCars'));

    if (currentReservedCars === null) {
      currentReservedCars = [];
    }

    // Remove the reserved car from the local storage by the id
    currentReservedCars = currentReservedCars.filter(car => car.id !== id);

    localStorage.setItem('reservedCars', JSON.stringify(currentReservedCars));
    setReservedCars(currentReservedCars);
    // console.log("Reserved cars: ", currentReservedCars);
  };

  // Clear the reserved cars from the local storage
  const clearReservedCar = () => {
    localStorage.setItem('reservedCars', JSON.stringify([]));
    setReservedCars([]);
  };

  return {
    token,
    login,
    logout,
    userId,
    reservedCars,
    addReservedCar,
    removeReservedCar,
    clearReservedCar,
  };
};
