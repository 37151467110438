import styled from 'styled-components';
import alertIcon from 'assets/imgs/alertIcon.svg';
import EmptyAddress from 'components/Member/EmptyAddress';
import AddressCardPanel from 'components/Member/AddressCardPanel';
import React, { useContext } from 'react';
import {
  DataContextMemberAddress,
  AddressComponentRenderState,
} from 'pages/Member';

const StyledMemberAddressSection = styled.section`
  .top-title-exclamation {
    display: flex;
    align-items: flex-start;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    color: #969696;
    gap: 0.8rem;
    margin-bottom: 4.5rem;
  }

  .top-title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 2.8rem;
    justify-content: space-between;
  }

  .btn-add-new-address-top {
    gap: 10px;
    width: 80px;
    height: 40px;
    border: 2px solid #ffffff;
    border-radius: 30px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
  }

  @media only screen and (min-width: 900px) {
    .top-title-exclamation {
      align-items: center;
    }

    .btn-add-new-address-top {
      margin-right: 1.6rem;
    }
  }
`;

const MemberAddress = ({ addressItems, locationItems }) => {
  const { setShowMemberAddress } = useContext(DataContextMemberAddress);
  const modifyMemberAddress = state => {
    setShowMemberAddress(state);
  };

  return (
    <React.Fragment>
      <StyledMemberAddressSection>
        <div className="top-title-exclamation">
          <img className="alert-icon" src={alertIcon} alt=""></img>
          下列地址將會在您預約時自動顯示,您可以修改成其它地址
        </div>
        <div className="top-title-wrapper">
          <h1 className="top-title-text">聯絡地址</h1>
          <button
            onClick={() =>
              modifyMemberAddress(AddressComponentRenderState.NewContactAddress)
            }
            className="btn-add-new-address-top"
          >
            新增
          </button>
        </div>
        {!addressItems.length && <EmptyAddress label="尚未設定聯絡地址" />}
        {!!addressItems.length && (
          <AddressCardPanel
            items={addressItems}
            type={AddressComponentRenderState.EditContactAddress}
          />
        )}
        <div className="top-title-wrapper">
          <h1 className="top-title-text">預約地點</h1>
          <button
            onClick={() =>
              modifyMemberAddress(
                AddressComponentRenderState.NewContactLocation,
              )
            }
            className="btn-add-new-address-top"
          >
            新增
          </button>
        </div>
        {!locationItems.length && <EmptyAddress label="尚未設定預約地點" />}
        {!!locationItems.length && (
          <AddressCardPanel
            items={locationItems}
            type={AddressComponentRenderState.EditContactLocation}
          />
        )}
      </StyledMemberAddressSection>
    </React.Fragment>
  );
};

export default MemberAddress;
