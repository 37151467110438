import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  flex-wrap: wrap;

  .img {
    display: flex;
    height: 48rem;
    width: 100%;
    max-width: 85.4rem;
  }

  .body {
    display: flex;
    flex-direction: column;
    padding: 1.6rem 4rem 1.6rem 4rem;
    max-width: 51.2rem;
    background: none;
  }

  .title {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    color: #ffffff;
    padding: 1.6rem 0 1.6rem 0;
    align-self: stretch;
    max-width: 51.2rem;
    background: none;
  }

  .text {
    padding: 1.6rem 0 1.6rem 0;
    max-width: 51.2rem;

    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
    color: #ffffff;
    align-self: stretch;
    background: none;
  }

  @media only screen and (min-width: 800px) {
    position: relative;
    height: 48rem;
    justify-content: start;
    .img {
      position: absolute;
      left: 0;
    }
    .body {
      position: absolute;
      top: 14.7rem;
      margin-left: 43%;
      padding-left: 0;
      max-width: 77.2rem;
      .title,
      .text {
        width: fit-content;
      }
    }
  }
`;

const TopicCardRight = ({ item }) => {
  return (
    <Container>
      <div
        className="img"
        style={{
          background: `linear-gradient(270deg, #000000 0%, rgba(0, 0, 0, 0) 50%), 
                       url(${`${process.env.REACT_APP_DATA_URL}/uploads/topic/${item.right_car_image}`})
                       center center/cover no-repeat`,
        }}
        variant="top"
      />
      <div className="body">
        <div className="title">{item.right_car_title}</div>
        <div className="text">{item.right_car_description}</div>
      </div>
    </Container>
  );
};
export default TopicCardRight;
