import { useState } from 'react';
import cities from './data/cities';
import districts from './data/districts';
import zipCodes from './data/zipCode';

const useTwZipCode = () => {
  const [city, setCity] = useState('請選擇');
  const [district, setDistrict] = useState('');
  const [zipCode, setZipCode] = useState('');

  const handleCityChange = value => {
    setCity(value);
    if (value in districts && value in zipCodes) {
      setDistrict(districts[value][0]);
      setZipCode(zipCodes[value][districts[value][0]]);
    }
  };

  const handleDistrictChange = async value => {
    setDistrict(value);
    if (city in zipCodes) {
      if (value in zipCodes[city]) {
        setZipCode(zipCodes[city][value]);
      }
    }
  };

  return {
    city,
    setCity,
    district,
    setDistrict,
    zipCode,
    setZipCode,
    handleCityChange,
    handleDistrictChange,
  };
};

export { cities, districts, zipCodes, useTwZipCode };
