import React, { useEffect, useState, useContext } from 'react';
// Import Swiper React components
import { useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Controller } from 'swiper';
import { useHttpClient } from 'shared/hooks/http-hook';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import { AuthContext } from 'shared/context/auth-context';
import { SCI_FI_BG } from 'shared/constants/assets';
import ReservationModal from 'shared/components/ReservationModal';

// Import Swiper styles
import 'swiper/less';
import 'swiper/css';
import 'swiper/css/navigation';
import styled from 'styled-components';

const StyledImageSwiper = styled(Swiper)`
  background-image: url(${SCI_FI_BG});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  * {
    background-color: transparent;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 4.8rem;
    height: 4.8rem;
    background: rgba(1, 0, 1, 0.5);
    ::after {
      color: white;
      font-size: 16px;
    }
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
  }

  .img-container {
    width: 100%;
    background: transparent;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 32rem;
    max-width: 26rem;
    padding: 4rem 0.2rem 1.8rem 0.2rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .img {
    padding-top: 4rem;
    padding-bottom: 1.8rem;
    width: 100%;
    background: transparent;
  }

  .title {
    visibility: hidden;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
  }

  .subtitle {
    visibility: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .price {
    visibility: hidden;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    // text-align: center;
    margin: auto;
  }

  .descriptions {
    display: none;
  }

  .reserve {
    visibility: hidden;
    width: 14.8rem;
    height: 3.9rem;
    border: 0.2rem solid #ffffff;
    border-radius: 30px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    margin: auto;
    text-decoration: none;
    padding-top: 0.8rem;
    display: flex;
    justify-content: center;
    :hover {
      background-color: #cd0715;
    }
  }

  .swiper-slide-active {
    .title,
    .subtitle,
    .price,
    .reserve {
      visibility: visible;
    }
  }

  @media only screen and (min-width: 1000px) {
    .container {
      height: 80rem;
      max-width: 60rem;
      justify-content: flex-start;
    }
    .title {
      font-size: 48px;
      line-height: 72px;
    }

    .price {
      margin: 1rem;
      font-size: 20px;
      line-height: 24px;
    }
    .reserve {
      margin: 1rem;
    }

    .subtitle {
      justify-content: center;
    }

    .swiper-slide-active {
      .descriptions {
        display: flex;
        margin-top: 48rem;
      }
    }

    @media only screen and (min-width: 1200px) {
      .title {
        /* font-size: 56px; */
        /* line-height: 72px; */
      }
    }
  }
`;

const StyledTextSwiper = styled(Swiper)`
  * {
    background: transparent;
  }

  .descriptions {
    padding-top: 8rem;
    display: flex;
    flex-direction: column;
  }

  .spec {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .angle-container {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .angle-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media only screen and (min-width: 1000px) {
    .spec {
      flex-direction: row;
      justify-content: center;
      margin-top: 8rem;
      margin-bottom: 8rem;
    }
    .descriptions {
      flex-direction: row;
      justify-content: center;
      display: none;
    }
  }
`;

const StyledDescriptionDiv = styled.div`
  .description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 3.2rem;
  }

  .performance {
    display: table;
    padding-bottom: 0.8rem;
  }

  .speed,
  .mileage {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
  }

  .speed-unit,
  .mileage-unit {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    display: table-cell;
    vertical-align: middle;
  }

  .drive {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    padding-bottom: 0.8rem;
  }

  .detail {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
  }

  @media only screen and (min-width: 1000px) {
    .speed,
    .mileage {
      font-size: 40px;
      line-height: 32px;
    }

    .speed-unit,
    .mileage-unit {
      font-size: 20px;
      line-height: 24px;
    }

    .drive {
      font-size: 32px;
      line-height: 32px;
    }

    .detail {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

const StyledSpecDiv = styled.div`
  .spec-img {
    width: 90%;
    max-width: 50rem;
    margin: auto;
  }

  .spec-sheet {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 3rem;
    max-width: 44rem;
  }

  .spec-header {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    width: 100%;
  }

  .spec-detail {
    width: 40%;
    border-bottom: 1px solid #969696;
  }
  .spec-detail-title {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #969696;
  }
  .spec-detail-text {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    color: #ffffff;
  }

  @media only screen and (min-width: 1000px) {
    .spec-img {
      margin: 0;
      max-width: 60rem;
    }
  }
`;

const StyledAngleDiv = styled.div`
  .title-container {
    width: 100%;
    max-width: 70rem;
  }

  .title {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    align-self: flex-start;
    margin-left: 5%; // margin: auto;
  }

  .angles {
    display: grid;
    width: 100%;
    max-width: 70rem;
    grid-template-columns: repeat(auto-fill, 11.6rem);
    justify-content: center;
    grid-gap: 1.6rem;
  }

  .angle {
    height: 11.6rem;
    width: 11.6rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  @media only screen and (min-width: 644px) {
    .angles {
      max-width: 70rem;
      grid-gap: 1.6rem;
      grid-template-columns: repeat(auto-fill, 12.6rem);
    }
  }

  @media only screen and (min-width: 694px) {
    .angles {
      max-width: 70rem;
      grid-gap: 1.6rem;
      grid-template-columns: repeat(auto-fill, 13.6rem);
    }

    .angle {
      height: 13.6rem;
      width: 13.6rem;
    }
  }

  @media only screen and (min-width: 1000px) {
    .title-container {
      width: 90%;
      max-width: 112.8rem;
    }
    .angles {
      max-width: 112.8rem;
      grid-gap: 3.2rem;
      grid-template-columns: repeat(auto-fill, 20rem);
    }

    .angle {
      height: 20rem;
      width: 20rem;
    }
  }

  @media only screen and (min-width: 1120px) {
    .title-container {
      width: 90%;
      max-width: 112.8rem;
    }
    .angles {
      max-width: 112.8rem;
      grid-gap: 3.2rem;
      grid-template-columns: repeat(auto-fill, 22rem);
    }

    .angle {
      height: 20rem;
      width: 20rem;
    }
  }
`;

const StyledAngleDetailDiv = styled.div`
  .subtitle {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    margin: auto;
    padding: 5.6rem 1.95rem;
    text-align: center;
  }

  .item {
    display: flex;
    flex-direction: column;
  }

  .img {
    width: 100%;
    max-width: 52rem;
    max-height: 40rem;
    object-fit: contain;
    object-position: center;
    margin: auto;
  }

  .full-img {
    width: 100%;
    max-width: 100rem;
    max-height: 68rem;
    padding-top: 8rem;
    padding-bottom: 8rem;
    object-fit: cover;
  }

  .reverse {
    flex-direction: column-reverse;
  }

  .description {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    padding: 5.6rem 1.95rem 0rem 1.95rem;
  }

  .last-description {
    padding: 0 1.95rem 5.6rem 1.95rem;
  }

  @media only screen and (min-width: 1000px) {
    .item {
      flex-direction: row;
      max-width: 112rem;
    }
    .description {
      margin: auto 0;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      padding: 0 4rem;
    }
    .img {
      height: 40rem;
      object-fit: contain;
      object-position: left;
      width: 50%;
    }

    .reverse {
      flex-direction: row-reverse;
    }
  }
`;

const Spec = ({ item }) => {
  return (
    <StyledSpecDiv className="spec">
      <img className="spec-img" src={item.img} alt="car" />
      <ul className="spec-sheet">
        <li className="spec-header">規格</li>
        <li className="spec-detail">
          <h3 className="spec-detail-title">車型年份</h3>
          <h1 className="spec-detail-text">{item.spec.year}</h1>
        </li>
        <li className="spec-detail">
          <h3 className="spec-detail-title">引擎</h3>
          <h1 className="spec-detail-text">{item.spec.engine}</h1>
        </li>
        <li className="spec-detail">
          <h3 className="spec-detail-title">傳輸</h3>
          <h1 className="spec-detail-text">{item.spec.transmission}</h1>
        </li>
        <li className="spec-detail">
          <h3 className="spec-detail-title">轉向</h3>
          <h1 className="spec-detail-text">{item.spec.steering}</h1>
        </li>
        <li className="spec-detail">
          <h3 className="spec-detail-title">煞車</h3>
          <h1 className="spec-detail-text">{item.spec.break}</h1>
        </li>
        <li className="spec-detail">
          <h3 className="spec-detail-title">外觀顏色</h3>
          <h1 className="spec-detail-text">{item.spec.appearance}</h1>
        </li>
        <li className="spec-detail">
          <h3 className="spec-detail-title">內飾顏色和材料</h3>
          <h1 className="spec-detail-text">{item.spec.interior}</h1>
        </li>
        <li className="spec-detail">
          <h3 className="spec-detail-title">背部</h3>
          <h1 className="spec-detail-text">{item.spec.back}</h1>
        </li>
      </ul>
    </StyledSpecDiv>
  );
};

const Descriptions = ({ item }) => {
  return (
    <StyledDescriptionDiv className="descriptions">
      <ul className="description">
        <li className="performance">
          <span className="speed">{item.speed}</span>
          <span className="speed-unit">&nbsp;秒</span>
        </li>
        <li className="detail">0-100 公里/小時</li>
      </ul>
      <ul className="description">
        <li className="performance">
          <span className="mileage">{item.mileage}</span>
          <span className="mileage-unit">&nbsp;公里</span>
        </li>
        <li className="detail">里程數</li>
      </ul>
      <ul className="description">
        <li className="performance">
          <span className="drive">{item.drive}</span>
        </li>
        <li className="detail">驅動系統</li>
      </ul>
    </StyledDescriptionDiv>
  );
};

const Angle = ({ item }) => {
  return (
    <React.Fragment>
      <StyledAngleDiv className="angle-container">
        <div className="title-container">
          <h1 className="title">更多角度</h1>
        </div>
        <div className="angles">
          {item.angles.map((angle, index) => (
            <div
              className="angle"
              key={index}
              style={{ backgroundImage: `url(${angle})` }}
            />
          ))}
        </div>
      </StyledAngleDiv>
    </React.Fragment>
  );
};

// TODO: 1. To enable image selection? 2. To confirm the selected image width (now 100rem)
const AngleDetail = ({ item }) => {
  return (
    <StyledAngleDetailDiv className="angle-detail">
      <h1 className="subtitle">{item.subtitle}</h1>
      <div className="item">
        <img className="img" src={item.sub_img_1} alt="car" />
        <p className="description">{item.description_1}</p>
      </div>
      <img className="full-img" src={item.selected_img} alt="car" />
      <div className="item reverse">
        <img className="img" src={item.sub_img_2} alt="car" />
        <p className="description last-description">{item.description_2}</p>
      </div>
    </StyledAngleDetailDiv>
  );
};

const Reservation = () => {
  const auth = useContext(AuthContext);
  const carId = useParams().carId;
  const [controlledSwiper, setControlledSwiper] = useState(null);

  const { isLoading, sendRequest } = useHttpClient();
  const [inventoryItems, setInventoryItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(0);
  const [showModal, setShowModal] = useState(false);

  // TODO: Get the inventory items from the Inventory component or through dataContext
  useEffect(() => {
    // console.log("Fetch inventory items in Reservation Component");
    const fetchInventoryItems = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/inventory',
          'GET',
        );

        // Construct items from the inventoryItems
        const items = responseData.map(item => {
          return {
            id: item.id,
            img: `${process.env.REACT_APP_DATA_URL}/inventory/${item.image}`,
            title: item.title,
            price: item.price,
            speed: item.speed,
            mileage: item.mileage,
            drive: item.drive,
            spec: {
              year: item.year,
              engine: item.engine,
              transmission: item.transmission,
              steering: item.steering,
              break: item.breaking,
              appearance: item.appearance,
              interior: item.interior,
              back: item.back,
            },
            angles: item.angles.map(
              angle => `${process.env.REACT_APP_DATA_URL}/inventory/${angle}`,
            ),
            sub_img_1: `${process.env.REACT_APP_DATA_URL}/inventory/${item.sub_img_1}`,
            sub_img_2: `${process.env.REACT_APP_DATA_URL}/inventory/${item.sub_img_2}`,
            selected_img: `${process.env.REACT_APP_DATA_URL}/inventory/${item.selected_img}`,
            subtitle: item.subtitle,
            description_1: item.description_1,
            description_2: item.description_2,
          };
        });

        // Put the item with id === carId to the first
        const index = items.findIndex(
          item => parseInt(item.id) === parseInt(carId),
        );
        setInventoryItems(items);
        setSelectedItem(index === -1 ? 0 : index);
      } catch (err) {}
    };
    fetchInventoryItems();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}
      {!isLoading && (
        <React.Fragment>
          <StyledImageSwiper
            lazy={true}
            initialSlide={selectedItem}
            slidesPerView={1.5}
            centeredSlides={true}
            spaceBetween={0}
            grabCursor={true}
            modules={[Controller, Navigation]}
            controller={{ control: controlledSwiper }}
            navigation
            scrollbar={{ draggable: false }}
            breakpoints={{
              320: {
                slidesPerView: 1.5,
              },
              1000: {
                slidesPerView: 2,
              },
            }}
          >
            {inventoryItems.map((item, index) => (
              <SwiperSlide key={index}>
                <div
                  className="container"
                  style={{
                    backgroundImage: `url(${item.img})`,
                  }}
                >
                  <h1 className="title">{item.title}</h1>
                  <div className="subtitle">
                    <span className="price">{item.price}</span>
                    <button
                      className="reserve"
                      onClick={() => {
                        auth.addReservedCar({
                          id: item.id,
                          price: item.price,
                          img: item.img,
                          title: item.title,
                        });
                        setShowModal(true);
                      }}
                    >
                      立即預約
                    </button>
                  </div>
                  <Descriptions item={item} />
                </div>
              </SwiperSlide>
            ))}
          </StyledImageSwiper>
          <StyledTextSwiper
            lazy={true}
            slidesPerView={1}
            centeredSlides={true}
            spaceBetween={0}
            grabCursor={false}
            modules={[Controller]}
            onSwiper={setControlledSwiper}
            scrollbar={{ draggable: false }}
            allowTouchMove={false}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              1000: {
                slidesPerView: 1,
              },
            }}
          >
            {inventoryItems.map((item, index) => (
              <SwiperSlide key={index}>
                <Descriptions item={item} />
                <Spec item={item} />
                <Angle item={item} />
                <AngleDetail item={item} />
              </SwiperSlide>
            ))}
          </StyledTextSwiper>
          <ReservationModal show={showModal} setShow={setShowModal} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Reservation;
