import styled from 'styled-components';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ErrorMessage from 'shared/components/ErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHttpClient } from 'shared/hooks/http-hook';
import { AuthContext } from 'shared/context/auth-context';
import LoadingSpinner from 'shared/components/LoadingSpinner';

import {
  DataContextMemberProfile,
  ProfileComponentRenderState,
} from 'pages/Member';

const StyledMemberPasswordForm = styled.form`
  padding: 0 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    padding-bottom: 2.4rem;
  }

  .sub-form {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    gap: 2.4rem;
  }
  .form-element {
    width: 100%;
    max-width: 41.5rem;
  }

  .element-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    padding: 0.4rem 0;
  }
  .element-input {
    width: 100%;
    padding: 0 1.6rem;
    background: #010001;
    height: 4.8rem;
    border: 1px solid #969696;
    border-radius: 8px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: white;

    ::placeholder {
      color: #969696;
    }

    :focus {
      outline: none;
    }
  }

  .btn-wrapper {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    justify-content: flex-end;
    padding-top: 5.6rem;
  }

  .btn-back,
  .btn-confirm {
    width: 15.6rem;
    height: 4.8rem;
    border: 2px solid #ffffff;
    border-radius: 8px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  .btn-confirm {
    background: #cd0715;
  }

  @media only screen and (min-width: 460px) {
    align-items: flex-start;

    .title-wrapper {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    .btn-line {
      max-width: 15.6rem;
      margin-top: 0;
    }

    .sub-form {
      width: 100%;
      display: flex;
      // flex-direction: column;
      flex-wrap: no-wrap;
      justify-content: space-between;
      gap: 2.4rem;
    }

    .btn-wrapper {
      padding-top: 4rem;
    }
  }
`;

const MemberPassword = () => {
  const setShowMemberProfile = useContext(DataContextMemberProfile);
  const modifyMemberPassword = state => {
    setShowMemberProfile(state);
  };
  const formSchema = yup.object().shape({
    password: yup
      .string()
      .required('目前的密碼錯誤')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\(\)_\+\-\=\[\]\{}|;:'",.<>\/?\\`~])(?=.{8,})/,
        '密碼為8到32個字元，至少各有1個大小寫英文字，一個數字，和一個特殊符號',
      )
      .max(32, '密碼最長為32個字元'),
    cpassword: yup
      .string()
      .required('請輸入新密碼')
      .notOneOf([yup.ref('password'), null], '新密碼與舊密碼相同')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\(\)_\+\-\=\[\]\{}|;:'",.<>\/?\\`~])(?=.{8,})/,
        '密碼為8到32個字元，至少各有1個大小寫英文字，一個數字，和一個特殊符號',
      )
      .min(8, '密碼最短為8個字元')
      .max(32, '密碼最長為32個字元'),

    ccpassword: yup
      .string()
      .required('請再輸入一次新密碼')
      .oneOf([yup.ref('cpassword')], '請輸入一樣的新密碼'),
  });

  const {
    register,
    formState: { errors, isValidating },
    handleSubmit,
  } = useForm({ resolver: yupResolver(formSchema) });

  const [changePasswordMessage, setChangePasswordMessage] = useState('');
  const auth = useContext(AuthContext);
  const { isLoading, sendRequest } = useHttpClient();

  useEffect(() => {
    if (isValidating) setChangePasswordMessage('');
  }, [isValidating]);

  const onSubmit = async data => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/users/password',
        'POST',
        JSON.stringify({
          password: data.password,
          newPassword: data.cpassword,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        },
      );
      setChangePasswordMessage(responseData.message);
    } catch (err) {
      setChangePasswordMessage('目前的密碼錯誤，請輸入正確密碼');
    }
  };

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}
      <StyledMemberPasswordForm onSubmit={handleSubmit(onSubmit)}>
        <h2 className="title">修改密碼</h2>
        <ul className="sub-form">
          <li className="form-element">
            <h3 className="element-title">目前的密碼</h3>
            <input
              className="element-input"
              type="password"
              autoComplete="on"
              placeholder="請輸入"
              {...register('password')}
            />
            {errors.password && (
              <ErrorMessage message={errors.password.message} />
            )}
          </li>
          <li className="form-element">
            <h3 className="element-title">新密碼</h3>
            <input
              className="element-input"
              type="password"
              autoComplete="on"
              placeholder="請輸入"
              {...register('cpassword')}
            />
            {errors.cpassword && (
              <ErrorMessage message={errors.cpassword.message} />
            )}
          </li>
          <li className="form-element">
            <h3 className="element-title">確認新密碼</h3>
            <input
              className="element-input"
              type="password"
              autoComplete="on"
              placeholder="請輸入"
              {...register('ccpassword')}
            />
            {errors.ccpassword && (
              <ErrorMessage message={errors.ccpassword.message} />
            )}
            {/* Note that this is not error message but we use its style here*/}
            {changePasswordMessage && (
              <ErrorMessage message={changePasswordMessage} />
            )}
          </li>
          <div className="btn-wrapper">
            <button
              onClick={() =>
                modifyMemberPassword(ProfileComponentRenderState.Profile)
              }
              className="btn-back"
            >
              返回
            </button>
            <button onClick={handleSubmit(onSubmit)} className="btn-confirm">
              確認修改
            </button>
          </div>
        </ul>
      </StyledMemberPasswordForm>
    </React.Fragment>
  );
};

export default MemberPassword;
