import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CarouselHomeInventory from 'components/Home/CarouselHomeInventory';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const lineHeight = 3.2;
const StyledSection = styled.section`
  position: relative;
  padding: ${lineHeight}rem 0rem 0 0rem;
  /* height: fit-content; */

  .title {
    display: block;
    margin: auto;
    width: 9.6rem;
    height: 3.2rem;
    padding-bottom: 0.8rem;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.2rem;
    text-align: center;
    color: #ffffff;
  }

  .nav-tabs {
    display: flex;
    border: none;
    max-width: 40rem;
    justify-content: center;
    margin: auto;
    padding-bottom: 1.6rem;
  }

  .nav-link,
  .nav-link.active {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;

    /* Text/ff */
    color: #ffffff;
    background: none;
    border: none;
    margin: auto;
    padding: 1.4rem 2rem;
  }

  .nav-link.active {
    font-weight: 700;
    border-bottom: 0.1rem solid red;
  }

  .tab-content {
  }

  @media only screen and (min-width: 990px) {
    height: 65rem;
    .title,
    .nav-tabs {
      display: flex;
    }
    .title {
      width: fit-content;
      position: absolute;
      margin-left: 12rem;
      font-size: 32px;
      line-height: 32px;
    }
    .nav-tabs {
      margin-right: 12rem;
      justify-self: end;
    }

    .nav-link,
    .nav-link.active {
      font-size: 20px;
      line-height: 24px;
      padding-top: 0.4rem;
    }
  }
`;

const HomeInventory = ({ inventoryItems }) => {
  // console.log("inventoryItems", inventoryItems);
  // Get the unique brand names from the inventory items
  const brands = [
    ...new Map(inventoryItems.map(item => [item.brand, item])).keys(),
  ];

  // Create items arrays for each brand
  const items = brands.map(brand => ({
    key: brand,
    title: brand,
    // Filter the inventory items by brand name
    cars: inventoryItems.filter(item => item.brand === brand),
  }));

  const total = {
    key: 'total',
    title: '全部',
    cars: inventoryItems,
  };

  // Add "total" to the beginning of the array
  items.unshift(total);

  // console.log(items);
  return (
    <>
      <StyledSection>
        <Link className="title" to="/inventory" href="#">在庫車款</Link>
        <Tabs defaultActiveKey={items[0].key}>
          {items.map(item => (
            <Tab key={item.key} eventKey={item.key} title={item.title}>
              <CarouselHomeInventory cars={item.cars} />
            </Tab>
          ))}
        </Tabs>
      </StyledSection>
    </>
  );
};

export default HomeInventory;
